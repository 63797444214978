import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getAdminMenu } from "../../utils/utils";

const AdminSidebar = () => {

    const [openedMenu, setOpenedMenu] = useState('')
    const toggleSidebar = () => {
        const htmlElement = document.documentElement;
        htmlElement.classList.toggle('layout-menu-collapsed');
        htmlElement.classList.remove('layout-menu-hover');
    }

    const handleMenuClick = (menuId, hasChildren, link, event) => {
        if (hasChildren) {
            event.preventDefault();
            setOpenedMenu((prev) => (prev === menuId ? '' : menuId));
        }
    };

    return <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme" onMouseEnter={() => {
        document.documentElement.classList.add('layout-menu-hover')
    }} onMouseLeave={() => {
        document.documentElement.classList.remove('layout-menu-hover')
    }}>
        <div class="app-brand">
            <Link to={`/admin/dashboard`} class="app-brand-link">
                <span class="app-brand-logo">
                    <img src="https://res.cloudinary.com/dzhclyn7c/image/upload/v1725515711/logo.11382c5ec95cfd207528_1_r310yc.png" width={170} />
                </span>
            </Link>

            <a href="#" class="layout-menu-toggle menu-link text-large ms-auto" onClick={toggleSidebar}>
                <i class="ti menu-toggle-icon d-none d-xl-block align-middle"></i>
            </a>
        </div>

        <div class="menu-inner-shadow"></div>
        <ul class="menu-inner py-1">
            {
                getAdminMenu().map(menuItem => {
                    const hasChildren = menuItem?.children?.length > 0
                    return <li
                        className={`admin-menu-item ${openedMenu === menuItem.id ? 'open' : ''
                            }`}
                        key={menuItem.id}
                    >
                        <a
                            href={menuItem.link}
                            className={`menu-link ${hasChildren ? 'menu-toggle' : ''}`}
                            onClick={(e) =>
                                handleMenuClick(menuItem.id, hasChildren, menuItem.link, e)
                            }
                        >
                            <i className="menu-icon tf-icons ti ti-smart-home"></i>
                            <div data-i18n="Dashboards">{menuItem.title}</div>
                        </a>
                        {hasChildren && (
                            <ul className="menu-sub">
                                {menuItem.children.map((child) => (
                                    <li className="admin-menu-item" key={child.link}>
                                        <Link to={child.link} className="menu-link">
                                            <div data-i18n="Dashboard">{child.title}</div>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                })
            }
        </ul>
    </aside>
}

export default AdminSidebar