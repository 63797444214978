import { Link } from 'react-router-dom';
import '../SellingCards/sellingcards.css'
import React, { useEffect } from "react";
import { scrollToTop } from '../../utils/utils';

const SellingCards = ()=>{
    useEffect(()=>{
        scrollToTop()
    },[])
    
    return(
        <>
            <div className="single_post_header flex">
                <div className="container">
                    <h2 className="entry-title">
                        Selling Cards
                    </h2>
                </div>
            </div>
            <div className="container">
                <div className="main-text pt-0">
                    <div className="text-div full-width-small">
                        <h2 className="selling-heading">WHAT TO AVOID IF YOU’RE SELLING SPORTS CARDS</h2>
                        <p className="text block">
                            If you’re selling sports cards, sports memorabilia, or other types of collectibles, there are a number of pitfalls that can get in the way of a successful transaction. With more than 30 years of experience in the hobby, TonyeTrade knows all too well the risks posed by incompetent or unethical buyers. Please take a moment to empower yourself with the knowledge to avoid these mishaps, and if you have any additional questions or would like to initiate a sale, don’t hesitate to <Link to={`/contact`}>contact TonyeTrade</Link>.
                        </p>
                    </div>
                    <div className="image-div">
                    </div>
                </div>
            </div>
            <div className="container pt-0 pb-0">
                <div className="icon-box-container pb-0">
                    <div className="icon-row flex wrap-small">
                        <div className="icon-box full-width-small">
                            <div className="text-icon-box">
                                <h2 className="pink-card-heading mt-0">LOWBALL OFFERS</h2>
                            </div>
                            <p className="icon-dec">Too often, unscrupulous dealers or investors take advantage of individuals
                                with little or no knowledge of the items they’re selling. For example, if you’re liquidating
                                an estate, the last thing you should worry about is getting too low of an offer for the
                                collection of a loved one. TonyeTrade is extremely proud to maintain a sterling reputation
                                for honest dealings and delivering the best cash offers for a broad range of collectibles.
                                Don’t let yourself fall for a low ball offer, we have beat out everyone in the hobby for
                                major collections.</p>
                        </div>
                        <div className="icon-box full-width-small">
                            <div className="text-icon-box">
                                <h2 className="mt-0 pink-card-heading">CHERRY PICKING</h2>
                            </div>
                            <p className="icon-dec">It’s exciting to realize you have valuable sports cards or other
                                collectibles. The feeling can be overwhelming when someone pulls a few items from your
                                collection and makes you a substantial offer. But what about the rest of the collection?
                                TonyeTrade offers a number of options to get cash for even the largest collections, so don’t
                                let those big dollars slip away by letting someone cherry-pick your own collection!</p>
                        </div>

                    </div>
                    <div className="icon-row wrap-small">
                        <div className="icon-box full-width-small">
                            <div className="text-icon-box">
                                <h2 className="mt-0 pink-card-heading">INACCURATE PRICING</h2>
                            </div>
                            <p className="icon-dec">Accurately pricing sports cards and other collectibles isn’t always as
                                simple as running a baseball card value lookup or perusing a guide like Beckett. There are a
                                number of things to consider, including market activity and the condition of the items
                                themselves. TonyeTrade draws on considerable experience to determine the market value of
                                your items and the best possible condition they can be assigned to deliver the highest
                                price.</p>
                        </div>
                        <div className="icon-box full-width-small">
                            <div className="text-icon-box">
                                <h2 className="mt-0 pink-card-heading">DELAYED PAYMENT</h2>
                            </div>
                            <p className="icon-dec">It’s easy to believe an auction is the best way to get top dollar for items
                                like sports cards and memorabilia. Unfortunately, this is far from the truth. The auction
                                itself can take quite some time before the sale is finalized. And auctions generally aren’t
                                marketed to the right kinds of buyers. Finally, most auction houses charge a commission
                                between 10% and 15% — with some asking 20% or more! On top of that —in many cases sellers
                                are faced with additional expenses like commissions and grading fees which can end up
                                lowering your bottom line.With our deep expertise, TonyeTrade looks to a database of active
                                buyers, and pays top dollar on the spot.</p>
                        </div>

                    </div>
                    <div className="icon-row wrap-small">
                        <div className="icon-box full-width-small">
                            <div className="text-icon-box">
                                <h2 className="mt-0 pink-card-heading">TRANSACTION HASSLES</h2>
                            </div>
                            <p className="icon-dec">Once you accept an offer for sports cards or other types of collectibles,
                                the last thing you should have to do is hassle with payment methods or make complex
                                arrangements for the sale. When you sell to TonyeTrade, we can travel to you in many cases
                                and cut a check on the spot. We also handle any requirements such as packaging and secure
                                transportation.</p>
                        </div>
                        <div className="icon-box bg-transparent">

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default SellingCards