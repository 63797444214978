import axios from "axios";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentLoader from "react-content-loader";

const ProductCard = React.lazy(() => import("../../components/ProductCard"));

const RenderPlaceholder = () => (
    <div className='placeholder_wrappers'>
        {Array(6).fill().map((_, index) => (
            <div key={index} className='single_placeholder'>
                <ContentLoader viewBox="0 0 40 40">
                    <rect x="0" y="0" rx="1" ry="1" width="100%" height="25" />
                    <rect x="0" y="27.5" rx="2" ry="2" width="100%" height="3.5" />
                    <rect x="0" y="33" rx="2" ry="2" width="80%" height="3" />
                </ContentLoader>
            </div>
        ))}
    </div>
);

const Badass = () => {
    const [products, setProducts] = useState(undefined)
    const navigate = useNavigate()
    const location = useLocation()
    const [layout, setLayout] = useState('grid')
    const [pagination, setPagination] = useState({
        totalPages: 0,
        currentPage: 1,
        totalPosts: 0,
    })
    const getProducts = useCallback(async (currentPage = 1) => {
        setPagination({
            ...pagination,
            currentPage: currentPage
        })
        await axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `badassproducts?page=${currentPage}`).then(res => {
            if (res?.data?.products && Array.isArray(res?.data?.products) && res?.data?.products?.length > 0) {
                setProducts(res?.data?.products)
                setPagination({
                    ...pagination,
                    totalPages: res?.data?.total_pages,
                    totalPosts: res?.data?.total_posts,
                });
            }
        }).catch(err => {
            console.log(err)
        })
    })
    useEffect(() => {
        setProducts(undefined)
        getProducts();
    }, [navigate])

    const handleLayoutChange = useCallback((event) => {
        event.preventDefault();
        setLayout(event.currentTarget.getAttribute('data-type'));
    }, []);

    const handlePaginationChange = useCallback(async (index) => {
        const newPage = index + 1
        setPagination({
            ...pagination,
            currentPage: newPage,
        })
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', newPage);
        const newSearchString = searchParams.toString();
        if (newPage > 1) {
            navigate(location.pathname + `?${newSearchString}`, { replace: true })
        } else {
            navigate(location.pathname, { replace: true })
        }
        getProducts(newPage)
    })

    return (
        <>
            <div className="single_page_header_wrapper">
                <div className="container wide">
                    <div className="single_page_breadcrumb">
                        <ul className="text-white m-0 flex gap-20">
                            <li className="mb-0 small">
                                Accounts
                            </li>
                            <li className="mb-0">
                                Badass Items
                            </li>
                        </ul>
                    </div>
                    <h3 className="single_page_header_title">
                        Badass Items
                    </h3>
                </div>
            </div>
            <div className="products_wrapper_outer flex">
                <div id="advanced_filters">

                </div>
                <div className={`products_wrapper_inner full-width-small pl-15-small ${layout}`}>
                    <div className="shop_container">
                        <div className="products_filter_header_wrapper">
                            <div className='left'>
                                <a id="adv_filter_opener" href="#">
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="20" cy="20" r="19.5" stroke="#E4E5E7"></circle> <path d="M27.5 14.631H21V13.881C21 13.631 20.75 13.381 20.5 13.381H19.5C19.2187 13.381 19 13.631 19 13.881V14.631H12.5C12.2187 14.631 12 14.881 12 15.131V15.631C12 15.9122 12.2187 16.131 12.5 16.131H19V16.881C19 17.1622 19.2187 17.381 19.5 17.381H20.5C20.75 17.381 21 17.1622 21 16.881V16.131H27.5C27.75 16.131 28 15.9122 28 15.631V15.131C28 14.881 27.75 14.631 27.5 14.631ZM27.5 24.631H17V23.881C17 23.631 16.75 23.381 16.5 23.381H15.5C15.2187 23.381 15 23.631 15 23.881V24.631H12.5C12.2187 24.631 12 24.881 12 25.131V25.631C12 25.9122 12.2187 26.131 12.5 26.131H15V26.881C15 27.1622 15.2187 27.381 15.5 27.381H16.5C16.75 27.381 17 27.1622 17 26.881V26.131H27.5C27.75 26.131 28 25.9122 28 25.631V25.131C28 24.881 27.75 24.631 27.5 24.631ZM27.5 19.631H25V18.881C25 18.631 24.75 18.381 24.5 18.381H23.5C23.2187 18.381 23 18.631 23 18.881V19.631H12.5C12.2187 19.631 12 19.881 12 20.131V20.631C12 20.9122 12.2187 21.131 12.5 21.131H23V21.881C23 22.1622 23.2187 22.381 23.5 22.381H24.5C24.75 22.381 25 22.1622 25 21.881V21.131H27.5C27.75 21.131 28 20.9122 28 20.631V20.131C28 19.881 27.75 19.631 27.5 19.631Z" fill="#525357"></path> </svg>
                                </a>
                                <span className='left_side_results_holder'>
                                    <span className='number'>{pagination?.totalPosts}</span> Results
                                </span>
                            </div>
                            <div className='middle'>
                                <div className='custom_product_sorter'>

                                </div>
                            </div>
                            <div className='right'>
                                <a href="#" data-type="list" className={`${layout == 'list' ? 'active' : ''}`} onClick={event => {
                                    handleLayoutChange(event)
                                }}>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="20" cy="20" r="19.5" stroke="#E4E5E7"></circle> <line x1="12.75" y1="15.25" x2="27.25" y2="15.25" stroke="#525357" strokeWidth="1.5" strokeLinecap="round"></line> <line x1="12.75" y1="20.25" x2="27.25" y2="20.25" stroke="#525357" strokeWidth="1.5" strokeLinecap="round"></line> <line x1="12.75" y1="25.25" x2="27.25" y2="25.25" stroke="#525357" strokeWidth="1.5" strokeLinecap="round"></line> </svg>
                                </a>
                                <a href="#" data-type="grid" className={`${layout == 'grid' ? 'active' : ''}`} onClick={event => {
                                    handleLayoutChange(event)
                                }}>
                                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="20" cy="20" r="19.5" fill="#fff" stroke="#E4E5E7"></circle> <path d="M27.4999 13.381H12.4999C11.6562 13.381 10.9999 14.0685 10.9999 14.881V15.381H28.9999V14.881C28.9999 14.0685 28.3124 13.381 27.4999 13.381ZM10.9999 25.881C10.9999 26.7247 11.6562 27.381 12.4999 27.381H27.4999C28.3124 27.381 28.9999 26.7247 28.9999 25.881V16.381H10.9999V25.881ZM21.9999 18.631C21.9999 18.506 22.0937 18.381 22.2499 18.381H26.7499C26.8749 18.381 26.9999 18.506 26.9999 18.631V19.131C26.9999 19.2872 26.8749 19.381 26.7499 19.381H22.2499C22.0937 19.381 21.9999 19.2872 21.9999 19.131V18.631ZM21.9999 20.631C21.9999 20.506 22.0937 20.381 22.2499 20.381H26.7499C26.8749 20.381 26.9999 20.506 26.9999 20.631V21.131C26.9999 21.2872 26.8749 21.381 26.7499 21.381H22.2499C22.0937 21.381 21.9999 21.2872 21.9999 21.131V20.631ZM21.9999 22.631C21.9999 22.506 22.0937 22.381 22.2499 22.381H26.7499C26.8749 22.381 26.9999 22.506 26.9999 22.631V23.131C26.9999 23.2872 26.8749 23.381 26.7499 23.381H22.2499C22.0937 23.381 21.9999 23.2872 21.9999 23.131V22.631ZM16.4999 18.381C17.5937 18.381 18.4999 19.2872 18.4999 20.381C18.4999 21.506 17.5937 22.381 16.4999 22.381C15.3749 22.381 14.4999 21.506 14.4999 20.381C14.4999 19.2872 15.3749 18.381 16.4999 18.381ZM13.0937 24.7872C13.3437 23.9747 14.0937 23.381 14.9999 23.381H15.2499C15.6249 23.5685 16.0312 23.631 16.4999 23.631C16.9374 23.631 17.3437 23.5685 17.7187 23.381H17.9999C18.8749 23.381 19.6249 23.9747 19.8749 24.7872C19.9999 25.0997 19.7187 25.381 19.4062 25.381H13.5624C13.2499 25.381 12.9687 25.0685 13.0937 24.7872Z" fill="#525357"></path> </svg>
                                </a>
                            </div>
                        </div>
                        {
                            typeof products !== 'undefined' ? (
                                products?.length > 0 ?
                                    <div className="products_wrapper">
                                        <Suspense fallback={<RenderPlaceholder />}>
                                            {products.map(product => (
                                                <ProductCard key={product?.id} product={product} />
                                            ))}
                                        </Suspense>
                                    </div> : <p>No products found!</p>
                            ) : (<RenderPlaceholder />)
                        }

                    </div>
                    {
                        pagination?.totalPages > 1 ?
                            <div className='shop_pagination'>
                                <ul id="shop_pagination" className='flex gap-20 wrap-small'>
                                    {
                                        Array.from({ length: pagination?.totalPages }, (_, index) => {
                                            const searchParams = new URLSearchParams(location.search);
                                            const currentPage = searchParams.get('page') || 1;
                                            return (
                                                <li key={index} className={`${index + 1 == currentPage ? 'active' : ''}`} onClick={() => {
                                                    handlePaginationChange(index)
                                                }}>
                                                    {index + 1}
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div> : null
                    }
                </div>
            </div>
        </>
    )
}

export default Badass