import '../HowToSell/howtosell.css'
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import number1 from '../../assets/images/numbers_1.png'
import number2 from '../../assets/images/numbers_2.png'
import number3 from '../../assets/images/numbers_3.png'
import number4 from '../../assets/images/numbers_4.png'
import { scrollToTop } from '../../utils/utils';


const HowToSell = () => {
    useEffect(()=>{
        scrollToTop()
    },[])
    return (
        <>
            <div className="single_post_header flex">
                <div className="container">
                    <h2 className="entry-title mt-0 mb-10">
                        HOW TO SELL
                    </h2>
                </div>
            </div>
            <div className='container mid flex howtosell'>
                <div className='flex-60'>
                    <h3 className='sell_heading mt-0 mb-10'>
                        READY TO SELL YOUR BASEBALL CARDS? TONYETRADE MAKES IT EASY
                    </h3>
                    <p>
                        One of the best investment decisions you can make is working with TonyeTrade to secure top dollar for your baseball cards for sale.
                    </p>
                    <p>
                        Over the past 30 years, we’ve refined a process that’s so easy it’s turned first-time clients into repeat business. Here we’ve outlined the easy steps for handling your baseball cards for sale, but if you have additional questions or concerns please <Link to={`/contact`}>contact TonyeTrade</Link>.
                    </p>
                </div>
            </div>
            <div className='container mid flex howtosell mt-0'>
                <div className='flex-50 pr-90'>
                    <p>
                        <img src={number1} loading='lazy' />
                    </p>
                    <h3 className='sell_heading mt-0 mb-10'>
                        LET US KNOW YOU HAVE BASEBALL CARDS FOR SALE
                    </h3>
                    <p>
                        If you have baseball cards for sale, get started by contacting TonyeTrade to schedule an initial consultation. We’ll discuss the age and condition of your collection, as well as go over additional details and answer any questions you might have.
                    </p>
                </div>
                <div className='flex-50 pr-90'>
                    <p>
                        <img src={number2} loading='lazy' />
                    </p>
                    <h3 className='sell_heading mt-0 mb-10'>
                        TONYETRADE EVALUATES THE COLLECTION
                    </h3>
                    <p>
                        Following an initial consultation about your baseball cards for sale, TonyeTrade may determine further research and evaluation is necessary. This is done at no inconvenience to you. In fact, TonyeTrade can travel to your location if that proves necessary.
                    </p>
                </div>
            </div>
            <div className='container mid flex howtosell mt-0'>
                <div className='flex-50 pr-90'>
                    <p>
                        <img src={number3} loading='lazy' />
                    </p>
                    <h3 className='sell_heading mt-0 mb-10'>
                        CHOOSE YOUR BEST OPTION FOR SELLING
                    </h3>
                    <p>
                        When you work with TonyeTrade to secure top dollar for your collection, cash on the spot is typically the best solution. However, TonyeTrade can provide options for even the most challenging transaction. We can broker your baseball cards for sale by looking to our extensive database of contacts, and we also offer consignment with competitive rates and flexible terms.
                    </p>
                </div>
                <div className='flex-50 pr-90'>
                    <p>
                        <img src={number4} loading='lazy' />
                    </p>
                    <h3 className='sell_heading mt-0 mb-10'>
                        TONYETRADE HANDLES THE REST
                    </h3>
                    <p>
                        Once the terms of your transaction are secured, TonyeTrade will handle any packaging and delivery requirements at no cost or inconvenience to you. This is a value-add we’ve carefully developed over more than three decades in business, and we’re pleased to offer it to each of our clients.
                    </p>
                </div>
            </div>
            <div className='container mid flex howtosell last_section'>
                <div className='flex-60'>
                    <h3 className='sell_heading mt-0 mb-10'>
                        GET STARTED WITH US
                    </h3>
                    <p>
                        When you have baseball cards for sale, TonyeTrade is always your best bet compared with auction sites and in-person transactions. We make it easy to get top dollar for a wide range of sports cards and numerous other collectibles. Get started today with an easy process that gets you more money for your items.
                    </p>
                </div>
            </div>
        </>
    )
}

export default HowToSell