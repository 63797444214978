import '../SingleCategory/singlecategory.css'
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getProductsByCategorySlug, getSets } from "../../api/api";
import { Link } from "react-router-dom";
import { getInventoryID, priceFilters, scrollToTop } from '../../utils/utils';
import he from 'he'
import { useNavigate, useLocation } from 'react-router-dom';
import ProductCard from '../../components/ProductCard';
import axios from 'axios';
import SearchBar from '../../components/Searchbar';
import $ from 'jquery'
import SidebarFilters from '../../components/SidebarFilters';
import Placeholder from '../../components/Placeholder';

const getLastName = (playerName) => {
    const nameWithoutBrackets = playerName.replace(/\s*\(.*?\)\s*/g, "");
    const nameParts = nameWithoutBrackets.split(" ");
    return nameParts[nameParts.length - 1];
};

const sortProductsByLastName = async (products) => {
    let productsWrapper = products
    let _have_card_numbers = true;
    productsWrapper?.products?.map(product => {
        if (!product?.cardnumber || product?.cardnumber < 1)
            _have_card_numbers = false
        else
            _have_card_numbers = true
    })

    if (!_have_card_numbers) {
        const sorted = [...productsWrapper?.products].sort((a, b) => {
            const lastNameA = getLastName(a.player);
            const lastNameB = getLastName(b.player);
            return lastNameA.localeCompare(lastNameB);
        });
        if( sorted.length ){
            productsWrapper.products = sorted
        }
    }

    return productsWrapper
}

const RenderSingleProductCard = ({ product }) => <ProductCard product={product} />;

const SingleCategory = () => {
    let { levelone, leveltwo, levelthree, levelfour } = useParams()
    const [products, setProducts] = useState(undefined)
    const [headertitle, setHeaderTitle] = useState('')
    const [paginationLevel, setPaginationLevel] = useState('')
    const [sets, setSets] = useState(undefined)
    const navigate = useNavigate();
    const location = useLocation()
    const [pagination, setPagination] = useState({
        totalPages: 1,
        currentPage: 1,
        totalPosts: 0,
    })
    const [loadType, setLoadType] = useState('')
    const [filters, setFilters] = useState(null)
    const [taxQuery, setTaxQuery] = useState(undefined)

    const getFilters = useCallback(async (query) => {
        axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `getfilters?query=${query}`).then(res => {
            if (res?.status == 200 && res?.data?.results && Array.isArray(res?.data?.results) && res?.data?.results?.length > 0) {
                const filtersInner = {
                    manufacturer: [],
                    team: [],
                    sport: [],
                    price: [],
                    year: [],
                }
                res?.data?.results.map(result => {
                    if (undefined != filtersInner[result?.facet_name]) {
                        const existingItem = filtersInner[result?.facet_name].find(item => item.id === result?.term_id);
                        if (!existingItem) {
                            filtersInner[result?.facet_name]?.push({
                                name: result?.facet_display_value,
                                id: result?.term_id,
                            });
                        }
                    }
                })
                setFilters(filtersInner)
            }
        }).catch(err => {

        })
    }, [])

    const getProducts = useCallback(async (currentPage = 1, append = false) => {
        setPagination({
            ...pagination,
            currentPage: currentPage
        })
        let loadProducts = levelfour?.length > 0 || levelthree?.length > 0 ? true : false
        let loadSets = levelfour == undefined && levelthree == undefined && leveltwo != undefined && leveltwo?.length > 0
        let categorySlug = '';
        if (levelthree?.length > 0)
            categorySlug = levelthree
        if (levelfour?.length > 0)
            categorySlug = levelfour

        if (levelone && undefined != levelone && levelone?.length > 0 && leveltwo == undefined && levelthree == undefined && levelfour == undefined) {
            loadProducts = true
            categorySlug = levelone
        }
        let lastPagination = ''
        if (leveltwo == undefined && levelthree == undefined && levelfour == undefined && levelone != undefined) {
            setHeaderTitle('Inventory')
        } else if (levelfour !== undefined) {
            lastPagination = `${levelfour?.replaceAll('-', ' ')} Sets`
            setHeaderTitle(lastPagination)
            setPaginationLevel(lastPagination)
        } else if (levelthree !== undefined) {
            lastPagination = `${levelthree?.replaceAll('-', ' ')} Sets`
            setHeaderTitle(lastPagination)
            setPaginationLevel(lastPagination)
        } else if (leveltwo !== undefined) {
            lastPagination = `${leveltwo?.replaceAll('-', ' ')} Sets`
            setHeaderTitle(lastPagination)
            setPaginationLevel(lastPagination)
        }

        if ('un-opened' == leveltwo || 'memorabilia' == leveltwo || 'comics' == leveltwo) {
            categorySlug = leveltwo
            loadProducts = true
            loadSets = false
        }

        if (loadProducts && categorySlug?.length) {
            let productsInner = await getProductsByCategorySlug(categorySlug, currentPage, taxQuery);
            if (Array.isArray(productsInner?.products) && productsInner?.products?.length > 0) {
                window.isLoading = false;
                if (!products) {
                    setProducts(productsInner?.products)
                } else {
                    setProducts(prevProducts => [...(prevProducts || []), ...(productsInner?.products || [])]);
                }

                setPagination({
                    ...pagination,
                    totalPages: productsInner?.total_pages,
                    totalPosts: productsInner?.total_posts,
                });

                if (typeof productsInner?.tax_query !== 'undefined' && Array.isArray(productsInner?.tax_query) && productsInner?.tax_query?.length > 0) {
                    setTaxQuery(productsInner?.tax_query)
                }

                if (productsInner?.query) {
                    getFilters(productsInner?.query)
                }

            }
            setLoadType('products')
        } else if (loadSets) {
            setLoadType('sets')
            const queryParams = new URLSearchParams(location.search);
            const paramValue = queryParams.get('page');
            getSetsInner(paramValue && paramValue > 0 ? paramValue : 1)
            getAllYears(leveltwo)
        }

    })

    const getSetsInner = useCallback(async (page = 1) => {
        const inventory_id = getInventoryID(leveltwo)
        if (inventory_id > 0) {
            const setsInner = await getSets(inventory_id, page, skipYears);
            if (Array.isArray(setsInner?.sets) && setsInner?.sets?.length > 0) {
                window.isLoading = false;
                if (!sets) {
                    setSets(setsInner?.sets)
                    setPagination({
                        ...pagination,
                        totalPages: setsInner?.total_pages,
                        totalPosts: setsInner?.total_posts
                    });
                } else {
                    setSets(prevSets => [...(prevSets || []), ...(setsInner?.sets || [])]);
                }

                let tempSkipped = []

                if (setsInner?.sets?.length) {
                    setsInner?.sets?.map(set => {
                        tempSkipped.push(set?.id)
                    })
                }
                setSkipYears([...skipYears, ...tempSkipped])
            }
        } else {
            navigate('/')
        }
    })

    useEffect(() => {
        setProducts(undefined)
        setSets(undefined)
        setTaxQuery(undefined)
        setAllYears(undefined)
        setSkipYears([])
        setActiveFilters({
            manufacturer: [],
            team: [],
            sport: [],
            player: [],
            price: [],
            grade: [],
            year: [],
        })
        getProducts()
        scrollToTop()

        document.addEventListener('scroll', function (event) {
            var t = $(window);
            var elem = $('#infinite');
            if (typeof elem == 'undefined' || elem.length < 1) {
                return;
            }
            if (window.isLoading == undefined)
                window.isLoading = false;
            if (!window.isLoading && (t.scrollTop() + t.height()) >= (elem.offset().top - (2 * elem.height()))) {
                window.isLoading = true;
                if ($('#shop_pagination .active').next('li').length) {
                    setTimeout(() => {
                        $('#shop_pagination .active').next('li').trigger('click');
                    }, 1000);
                } else {
                    elem.remove()
                }
            }
        });

    }, [navigate])

    const handlePaginationChange = async (index) => {
        const newPage = index + 1
        setPagination({
            ...pagination,
            currentPage: newPage,
        })
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', newPage);
        const newSearchString = searchParams.toString();
        if (newPage > 1) {
            navigate(location.pathname + `?${newSearchString}`, { replace: true })
        } else {
            navigate(location.pathname, { replace: true })
        }

        if ('products' == loadType) {
            getProducts(newPage)
        } else if ('sets' == loadType) {
            getSetsInner(newPage)
        }
    }

    const [advacnedFiltersOpened, setAdvancedFiltersOpened] = useState(false)

    const [activeFilters, setActiveFilters] = useState({
        manufacturer: [],
        team: [],
        sport: [],
        player: [],
        price: [],
        grade: [],
        year: [],
    })

    const [isFilteringProducts, setIsFilteringProducts] = useState(false)

    const filterProducts = useCallback(async () => {
        const queryParams = new URLSearchParams({
            taxQuery: JSON.stringify(taxQuery)
        });
        axios.post(process.env.REACT_APP_TONYETRADE_API_BASE + `getfilteredproducts?${queryParams}`, {
            filters: activeFilters,
        }).then(res => {

            const productsInner = res?.data
            if (productsInner && typeof productsInner !== 'undefined' && Array.isArray(productsInner?.products)) {
                setProducts(productsInner?.products)
                setPagination({
                    ...pagination,
                    totalPages: productsInner?.total_pages,
                    totalPosts: productsInner?.total_posts,
                })
                if (typeof productsInner?.tax_query !== 'undefined' && Array.isArray(productsInner?.tax_query) && productsInner?.tax_query?.length > 0) {
                    setTaxQuery(productsInner?.tax_query)
                }
            }

        }).catch(err => {

        })
    }, [activeFilters, taxQuery])

    const handleFilterChange = useCallback((type, value, isChecked) => {
        let updatedFilters = { ...activeFilters };
        if (isChecked) {
            if (!updatedFilters[type].includes(value)) {
                updatedFilters[type] = [...updatedFilters[type], value];
            }
        } else {
            updatedFilters[type] = updatedFilters[type].filter(item => item !== value);
        }
        setActiveFilters(updatedFilters);
        filterProducts()
    }, [])

    const getAllYears = (category = undefined) => {
        if (typeof category == 'undefined')
            return
        const _inventoryID = getInventoryID(category)
        if (_inventoryID) {
            axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `getallyears/${_inventoryID}`).then(res => {
                const _data = res?.data
                if (_data && _data?.categories && _data?.categories?.length) {
                    setAllYears(_data?.categories)
                }
            }).catch(err => {

            })
        }
    }

    const [allYears, setAllYears] = useState(undefined)
    const [skipYears, setSkipYears] = useState([])

    const loadSetsByYear = async (yearID) => {
        if (!yearID || yearID < 1 || skipYears?.includes(yearID))
            return
        setSkipYears([...skipYears, yearID])
        axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `getsetsbyyear/${yearID}`).then(res => {
            const _data = res?.data

            if (_data && _data?.sets && _data?.sets?.length) {
                setSets(prevSets => [...prevSets, ...([_data] || [])]);
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $('#czc_' + yearID).offset().top
                    });
                }, 50)
                setTimeout(() => {
                    $('.all_years_wrapper_inner ul li[data-id="' + yearID + '"]').removeClass('processing');
                    $('.all_years_wrapper_inner ul li').removeClass('processing');
                }, 1500)
            }

        }).catch(err => {

        })
    }

    return (
        <>
            <div className="single_page_header_wrapper">
                <div className="container wide">
                    <div className="single_page_breadcrumb">
                        <ul className="text-white m-0 flex gap-20">
                            <li className="mb-0 small">
                                <Link to={`/shop`}>
                                    Inventory
                                </Link>
                            </li>
                            {paginationLevel?.length > 0 ?
                                <li className="mb-0">
                                    {paginationLevel}
                                </li> : null}

                        </ul>
                    </div>
                    <h3 className="single_page_header_title">
                        {headertitle}
                    </h3>
                </div>
            </div>
            <SearchBar />
            {
                loadType == 'products' ?
                    <SidebarFilters products={products} handleFilterChange={handleFilterChange} RenderSingleProductCard={RenderSingleProductCard} pagination={pagination} handlePaginationChange={handlePaginationChange} setAdvancedFiltersOpened={setAdvancedFiltersOpened} priceFilters={priceFilters} filters={filters} isOpen={advacnedFiltersOpened} isFilteringProducts={isFilteringProducts} /> : null
            }
            {
                loadType == 'sets' && sets?.length > 0 ?

                    <>
                        {
                            typeof allYears != 'undefined' && allYears?.length ?
                                <div className='container'>
                                    <div className='all_years_wrapper'>
                                        <div className='all_years_wrapper_inner p-10-small'>
                                            <ul>
                                                {
                                                    allYears?.map((year, index) => {
                                                        return (
                                                            <li data-id={year?.id} className={`${index > 9 ? 'hidden' : ''} ${skipYears?.includes(year?.id) ? 'disabled' : ''}`} key={index} onClick={(e) => {
                                                                setTimeout(() => {
                                                                    $(e.target).addClass('processing')
                                                                }, 100);
                                                                loadSetsByYear(year?.id)
                                                            }}>
                                                                <span className='count'>
                                                                    {year?.count}
                                                                </span>
                                                                <span className='text'>
                                                                    {year?.name}
                                                                </span>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            {
                                                allYears?.length > 9 ?
                                                    <a href='#' className='see_more' onClick={(e) => {
                                                        e.preventDefault()
                                                        $(e.target).remove()
                                                        $('.all_years_wrapper_inner ul li').removeClass('hidden');
                                                        $('.all_years_wrapper_inner').addClass('all_visible');
                                                    }}>
                                                        SEE MORE
                                                    </a> : null
                                            }
                                        </div>
                                    </div>
                                </div> : null
                        }
                        <div className="sets_wrapper_outer pl-10 pr-10">
                            <div className="container wide">
                                <div className='sets_wrapper_inner'>
                                    {
                                        sets?.map(set => {
                                            const setsInner = set?.sets
                                            return (
                                                <div key={set?.id} id={`czc_${set?.id}`} className='single_set'>
                                                    <div className='row full'>
                                                        <div className='yeartitle'>
                                                            {set?.name}
                                                        </div>
                                                    </div>
                                                    <div className='products row'>
                                                        {
                                                            setsInner?.length > 0 ?
                                                                setsInner?.map(setInner => {
                                                                    return (
                                                                        <div className='product-box full-width-small'>
                                                                            <div className='product-box-inner'>
                                                                                <div className='product-box-inner-small'>
                                                                                    <div className='title-wrapper'>
                                                                                        <Link to={`/${setInner?.slug}`}>
                                                                                            {he.decode(setInner?.name)}
                                                                                        </Link>
                                                                                    </div>
                                                                                    <div className='boxdimage'>
                                                                                        <div className='image-fade_in_back'>
                                                                                            <Link to={`/${setInner?.slug}`}>
                                                                                                <img src={setInner?.thumbnail?.replace('staging.', 'www.')} loading='lazy' />
                                                                                            </Link>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='box-text-products-set'>
                                                                                        <Link to={`/${setInner?.slug}`}>
                                                                                            VIEW SET
                                                                                        </Link>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div id="infinite" className='processing'></div>
                                </div>
                                {
                                    pagination?.totalPages > 1 ?
                                        <div className='shop_pagination'>
                                            <ul id="shop_pagination" className='flex gap-20 wrap-small'>
                                                {
                                                    Array.from({ length: pagination?.totalPages }, (_, index) => {
                                                        const searchParams = new URLSearchParams(location.search);
                                                        const currentPage = searchParams.get('page') || 1;
                                                        return (
                                                            <li key={index} className={`${index + 1 == currentPage ? 'active' : ''}`} onClick={() => {
                                                                handlePaginationChange(index)
                                                            }}>
                                                                {index + 1}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div> : null
                                }
                            </div>
                        </div>
                    </>
                    : null
            }
            {
                products == undefined && sets == undefined ?
                    <Placeholder /> : null
            }
        </>
    )
}

export default SingleCategory