const CACHE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // Cache expiration time in milliseconds

export const setCache = (key, value) => {
    try {
        const dataWithTimestamp = {
            value,
            timestamp: Date.now(),
        };
        sessionStorage.setItem(key, JSON.stringify(dataWithTimestamp));
    } catch (error) {
        console.error(`Error setting cache for key: ${key}`, error);
    }
};

export const getCache = (key) => {
    try {
        const cachedData = sessionStorage.getItem(key);
        if (!cachedData) return null;

        const { value, timestamp } = JSON.parse(cachedData);
        if (Date.now() - timestamp > CACHE_EXPIRY_TIME) {
            sessionStorage.removeItem(key);
            return null;
        }
        return value;
    } catch (error) {
        console.error(`Error getting cache for key: ${key}`, error);
        return null;
    }
};

export const removeCache = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (error) {
        console.error(`Error removing cache for key: ${key}`, error);
    }
};