import { combineSlices, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { clearUserFromIndexedDB, getUserFromIndexedDB, removeUserFromIndexedDB, saveUserToIndexedDB } from '../../api/indexeddb';
import { login } from '../../api/adminapi';

const initialState = {
    isAuthenticated: false,
    adminInfo: null,
    isLoading: true,
    error: null,
};

export const loginAdmin = createAsyncThunk(
    'admin/loginAdmin',
    async ({ username, password }, { rejectWithValue }) => {
        try {
            const data = await login(username, password);
            await saveUserToIndexedDB(data);
            return data;
        } catch (error) {
            const message = error.response?.data?.message || error.message || error;
            return rejectWithValue(message);
        }
    })

export const logoutAdmin = createAsyncThunk(
    'admin/logoutAdmin',
    async (_, { rejectWithValue }) => {
        try {
            await clearUserFromIndexedDB();
            return true;
        } catch (error) {
            const message = error.response?.data?.message || error.message || error;
            return rejectWithValue(message);
        }
    }
);

export const checkStoredUser = createAsyncThunk(
    'admin/checkStoredUser',
    async () => {
        const user = await getUserFromIndexedDB();
        if (user) {
            return user;
        } else {
            throw new Error('No user found');
        }
    });

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        logout(state) {
            state.isAuthenticated = false;
            state.adminInfo = null;
            state.isLoading = false;
            removeUserFromIndexedDB();
        },
    },
    extraReducers: (builder) => {

        builder.addCase(loginAdmin.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(loginAdmin.fulfilled, (state, action) => {
            state.isAuthenticated = true;
            state.adminInfo = action.payload;
            state.isLoading = false;
        });

        builder.addCase(loginAdmin.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });

        builder.addCase(checkStoredUser.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(checkStoredUser.fulfilled, (state, action) => {
            state.isAuthenticated = true;
            state.adminInfo = action.payload;
            state.isLoading = false;
        });

        builder.addCase(checkStoredUser.rejected, (state) => {
            state.isAuthenticated = false;
            state.adminInfo = null;
            state.isLoading = false;
        });

        builder.addCase(logoutAdmin.fulfilled, (state) => {
            state.isAuthenticated = false;
            state.adminInfo = null;
            state.isLoading = false;
            state.error = null;
        });

    },
});

export const { logout } = adminSlice.actions;
export default adminSlice.reducer;