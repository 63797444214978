import './App.css';
import Header from './components/Header/header';
import Footer from './components/Footer/footer';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from '../src/pages/Home/index'
import NotFound from './pages/NotFound';
import SingleProduct from './pages/SingleProduct';
import About from './pages/About';
import SingleCategory from './pages/SingleCategory';
import Contact from './pages/Contact';
import Sell from './pages/Sell';
import Value from './pages/Value';
import HowToSell from './pages/HowToSell';
import HowToValuate from './pages/HowToValuate';
import MostWantedList from './pages/MostWantedList';
import SellingCards from './pages/SellingCards';
import Memoribilia from './pages/Memoribilia';
import FeaturedNew from './pages/FeaturedNew';
import Badass from './pages/Badass';
import Blog from './pages/Blog';
import SinglePost from './pages/SinglePost';
import Shop from './pages/Shop';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import MyAccount from './pages/MyAccount';
import { Toaster } from 'react-hot-toast';
import { AuthProvider } from './Hooks/useAuth';
import authManager from './api/user';
import AccountDetails from './pages/MyAccount/AccountDetails';
import UpdateShipingDetails from './pages/MyAccount/UpdateShippingDetails';
import UpdateBillingDetails from './pages/MyAccount/UpdateBillingDetails';
import EditAccountDetails from './pages/MyAccount/EditAccountDetails';
import ChangePassword from './pages/MyAccount/ChangePassword';
import MyOrders from './pages/MyAccount/MyOrders';
import ViewOrderDetails from './pages/MyAccount/MyOrders/OrderDetails';
import Collection from './pages/Collection';
import MyFavorites from './pages/MyFavorites';
import Search from './pages/Search';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import Team from './pages/Team';
import Manufacturer from './pages/Manufacturer';
import Year from './pages/Year';
import AdminDashboard from './adminpages/Dashboard';
import AdminLogin from './adminpages/Login';
import { useEffect } from 'react';
import Products from './adminpages/Products';
import AddProduct from './adminpages/AddProduct';
import Categories from './adminpages/Categories';
import Orders from './adminpages/Orders';
import ViewOrder from './adminpages/ViewOrder';
import Users from './adminpages/Users';
import ViewUser from './adminpages/ViewUser';
import EbayListings from './adminpages/EbayListings';
import AmazonListings from './adminpages/AmazonListings';
import EditTags from './adminpages/EditTags/EditTags';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import Bulk_Importer from './adminpages/BulkImporter/bulkimporter';
import BulkEbayLister from './adminpages/BulkEbayLister/bulkebaylister';
import EbayOrders from './adminpages/EbayOrders/ebayorders';
import AmazonOrders from './adminpages/AmazonOrders/amazonorders';
import $ from 'jquery'
import Posts from './adminpages/Posts';
import AddPost from './adminpages/AddPost';
import Terms from './pages/MostWantedList/terms';
import Cookie from './pages/MostWantedList/cookie';
import Policy from './pages/MostWantedList/policy';
import PriceAdjustment from './adminpages/PriceAdjustment';

function Layout() {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  useEffect(() => {
    if (location.pathname != '/search') {
      $('#header_search_query').val('')
    }
  }, [location]);

  useEffect(() => {
    if (isAdminRoute) {
      import('./assets/css/admin.core.css');
      import('./assets/css/admin.theme.css');
      import('./assets/css/admin.css');
      import('./assets/css/admin.css');
      import('./assets/css/admin.icons.css');
      import('./assets/css/admin.datatables.css');
    }
  }, [isAdminRoute]);

  return (
    <>
      {!isAdminRoute && <Header />}
      <Routes>
        <Route exact={true} path="/" element={<Home title="TonyeTrade - We Buy & Sell Baseball Cards, Sports Cards & Comics" />} />
        <Route exact={true} path="/about" element={<About />} />
        <Route exact={true} path="/contact" element={<Contact />} />
        <Route exact={true} path="/sell" element={<Sell />} />
        <Route exact={true} path="/determining-value" element={<Value title="Determening value - TonyeTrade" />} />
        <Route exact={true} path="/how-to-sell" element={<HowToSell />} />
        <Route exact={true} path="/how-to-valuate" element={<HowToValuate />} />
        <Route exact={true} path="/the-most-wanted-list" element={<MostWantedList />} />
        <Route exact={true} path="/selling-cards" element={<SellingCards />} />
        <Route exact={true} path="/memoribilia" element={<Memoribilia />} />
        <Route exact={true} path="/featured" element={<FeaturedNew />} />
        <Route exact={true} path="/badass" element={<Badass />} />
        <Route exact={true} path="/blog" element={<Blog />} />
        <Route exact={true} path="/shop" element={<Shop />} />
        <Route exact={true} path="/cart" element={<Cart />} />
        <Route exact={true} path="/checkout" element={<Checkout />} />
        <Route exact={true} path="/my-account" element={<MyAccount />} />
        <Route exact={true} path="/collection/:id" element={<Collection />} />
        <Route exact={true} path="/my-account/accountdetails" element={<AccountDetails />} />
        <Route exact={true} path="/my-account/updateshippingdetails" element={<UpdateShipingDetails />} />
        <Route exact={true} path="/my-account/updatebillingdetails" element={<UpdateBillingDetails />} />
        <Route exact={true} path="/my-account/editaccountdetails" element={<EditAccountDetails />} />
        <Route exact={true} path="/my-account/changepassword" element={<ChangePassword />} />
        <Route exact={true} path="/my-account/orders" element={<MyOrders />} />
        <Route exact={true} path="/checkout/order-received/:id" element={<ViewOrderDetails />} />
        <Route exact={true} path="/my-favorites/" element={<MyFavorites />} />
        <Route exact={true} path="/:slug" element={<SingleProduct />} />
        <Route exact={true} path="/post/:slug" element={<SinglePost />} />
        <Route exact={true} path="/team/:teamslug" element={<Team />} />
        <Route exact={true} path="/manufacturer/:manufacturerslug" element={<Manufacturer />} />
        <Route exact={true} path="/c_year/:yearslug" element={<Year />} />
        <Route exact={true} path="/product-category/:levelone/:leveltwo?/:levelthree?/:levelfour?" element={<SingleCategory />} />
        <Route exact={true} path="/search" element={<Search />} />
        <Route exact={true} path="/terms" element={<Terms />} />
        <Route exact={true} path="/cookie-policy" element={<Cookie />} />
        <Route exact={true} path="/privacy-policy" element={<Policy />} />
        <Route path="/404" element={<NotFound />} />
        {/* admin routes */}
        <Route exact={true} path="/admin" element={<AdminDashboard />} />
        <Route exact={true} path="/admin/login" element={<AdminLogin />} />
        <Route exact={true} path="/admin/ebay/listings" element={<EbayListings />} />
        <Route exact={true} path="/admin/ebay/orders" element={<EbayOrders />} />
        <Route exact={true} path="/admin/amazon/listings" element={<AmazonListings />} />
        <Route exact={true} path="/admin/amazon/orders" element={<AmazonOrders />} />
        <Route exact={true} path="/admin/bulk_importer" element={<Bulk_Importer />} />
        <Route exact={true} path="/admin/bulk_importer/lister" element={<BulkEbayLister />} />
        <Route exact={true} path="/admin/products" element={<Products />} />
        <Route exact={true} path="/admin/products/add" element={<AddProduct />} />
        <Route exact={true} path="/admin/products/categories" element={<Categories />} />
        <Route exact={true} path="/admin/orders" element={<Orders />} />
        <Route exact={true} path="/admin/orders/view/:order_id" element={<ViewOrder />} />
        <Route exact={true} path="/admin/users" element={<Users />} />
        <Route exact={true} path="/admin/users/view" element={<ViewUser />} />
        <Route exact={true} path="/admin/edit-tags/:taxonomy" element={<EditTags />} />
        <Route exact={true} path="/admin/posts" element={<Posts />} />
        <Route exact={true} path="/admin/posts/add" element={<AddPost />} />
        <Route exact={true} path="/admin/price_adjustment" element={<PriceAdjustment />} />
        <Route exact={true} path="/admin/posts/add/:id" element={<AddPost />} />
      </Routes>
      {!isAdminRoute && <Footer />}
    </>
  );
}


function App() {

  return (
    <>
      <PayPalScriptProvider options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD",
        debug: false,
        intent: "capture",
        dataPartnerAttributionId: 'Woo_PPCP',
        vault: false,
        components: ['buttons', 'funding-eligibility', 'messages'],
        disableFunding: ['paylater']
      }}>
        <AuthProvider authManager={authManager}>
          <BrowserRouter>
            <Layout />
          </BrowserRouter>
        </AuthProvider>
      </PayPalScriptProvider>
      <Toaster />
      <SpeedInsights />
      <Analytics />
    </>
  );
}

export default App;
