import ContentLoader from 'react-content-loader'
import { getRelatedProducts, getWishlistProductIDs } from '../../api/api'
import ProductCard from '../../components/ProductCard'
import '../MyFavorites/fav.css'
import React, { useEffect, useState } from 'react'
import { scrollToTop } from '../../utils/utils'

const RenderPlaceholder = () => {
    return (
        <div className='placeholder_wrappers'>
            {Array.from({ length: 6 }).map((_, index) => (
                <div className='single_placeholder' key={index}>
                    <ContentLoader viewBox="0 0 40 40">
                        <rect x="0" y="0" rx="1" ry="1" width="100%" height="25" />
                        <rect x="0" y="27.5" rx="2" ry="2" width="100%" height="3.5" />
                        <rect x="0" y="33" rx="2" ry="2" width="80%" height="3" />
                    </ContentLoader>
                </div>
            ))}
        </div>
    )
}

function MyFavorites() {
    const [layout, setLayout] = useState('grid')
    const [products, setProducts] = useState(undefined)
    const handleLayoutChange = (event) => {
        event.preventDefault()
        setLayout(event.currentTarget.getAttribute('data-type'))
    }
    const getProducts = async () => {
        const wishlistProductIDs = await getWishlistProductIDs()
        if (wishlistProductIDs?.products && Array.isArray(wishlistProductIDs?.products) && wishlistProductIDs?.products?.length > 0) {
            const productsInner = await getRelatedProducts(wishlistProductIDs?.products)
            if (Array.isArray(productsInner?.products) && productsInner?.products?.length > 0) {
                setProducts(productsInner?.products)
            }
        } else {
            setProducts([])
        }
    }
    useEffect(() => {
        setProducts(undefined)
        getProducts()
        scrollToTop()
    }, [])
    return (
        <>
            <div className='single_page_header_wrapper'>
                <div className='container wide'>
                    <div className='single_page_header_breadcrumb'>
                        <ul>
                            <li>
                                Accounts
                            </li>
                            <li>
                                My Favorites
                            </li>
                        </ul>
                    </div>
                    <h3 className='single_page_header_title'>
                        <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3.35346 3.85541C0.582702 6.62616 0.582702 11.1184 3.35346 13.8892L15.4654 26.0011L27.5772 13.8892C30.3479 11.1184 30.3479 6.62616 27.5772 3.85541C24.8064 1.08466 20.3141 1.08466 17.5434 3.85541L15.4654 5.93356L13.3872 3.85541C10.6165 1.08466 6.12421 1.08466 3.35346 3.85541Z" fill="#A21D0B" stroke="#A21D0B" strokeWidth="1.69265" strokeLinecap="round" strokeLinejoin="round"></path> </svg>
                        <span>My Favorites</span>
                    </h3>
                </div>
            </div>
            {
                typeof products != 'undefined' ? (
                    products?.length > 0 ? (
                        <div className='products_wrapper_outer flex wishlist'>
                            <div className={`products_wrapper_inner ${layout}`}>
                                <div className='shop_container'>
                                    <div className='products_filter_header_wrapper'>
                                        <div className='left'>
                                            <span className='left_side_results_holder'>
                                                <span className='number'>{products?.length}</span> Results
                                            </span>
                                        </div>
                                        <div className='middle'>

                                        </div>
                                        <div className='right'>
                                            <a href="#" data-type="list" className={`${layout == 'list' ? 'active' : ''}`} onClick={event => {
                                                handleLayoutChange(event)
                                            }}>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="20" cy="20" r="19.5" stroke="#E4E5E7"></circle> <line x1="12.75" y1="15.25" x2="27.25" y2="15.25" stroke="#525357" strokeWidth="1.5" strokeLinecap="round"></line> <line x1="12.75" y1="20.25" x2="27.25" y2="20.25" stroke="#525357" strokeWidth="1.5" strokeLinecap="round"></line> <line x1="12.75" y1="25.25" x2="27.25" y2="25.25" stroke="#525357" strokeWidth="1.5" strokeLinecap="round"></line> </svg>
                                            </a>
                                            <a href="#" data-type="grid" className={`${layout == 'grid' ? 'active' : ''}`} onClick={event => {
                                                handleLayoutChange(event)
                                            }}>
                                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="20" cy="20" r="19.5" fill="#fff" stroke="#E4E5E7"></circle> <path d="M27.4999 13.381H12.4999C11.6562 13.381 10.9999 14.0685 10.9999 14.881V15.381H28.9999V14.881C28.9999 14.0685 28.3124 13.381 27.4999 13.381ZM10.9999 25.881C10.9999 26.7247 11.6562 27.381 12.4999 27.381H27.4999C28.3124 27.381 28.9999 26.7247 28.9999 25.881V16.381H10.9999V25.881ZM21.9999 18.631C21.9999 18.506 22.0937 18.381 22.2499 18.381H26.7499C26.8749 18.381 26.9999 18.506 26.9999 18.631V19.131C26.9999 19.2872 26.8749 19.381 26.7499 19.381H22.2499C22.0937 19.381 21.9999 19.2872 21.9999 19.131V18.631ZM21.9999 20.631C21.9999 20.506 22.0937 20.381 22.2499 20.381H26.7499C26.8749 20.381 26.9999 20.506 26.9999 20.631V21.131C26.9999 21.2872 26.8749 21.381 26.7499 21.381H22.2499C22.0937 21.381 21.9999 21.2872 21.9999 21.131V20.631ZM21.9999 22.631C21.9999 22.506 22.0937 22.381 22.2499 22.381H26.7499C26.8749 22.381 26.9999 22.506 26.9999 22.631V23.131C26.9999 23.2872 26.8749 23.381 26.7499 23.381H22.2499C22.0937 23.381 21.9999 23.2872 21.9999 23.131V22.631ZM16.4999 18.381C17.5937 18.381 18.4999 19.2872 18.4999 20.381C18.4999 21.506 17.5937 22.381 16.4999 22.381C15.3749 22.381 14.4999 21.506 14.4999 20.381C14.4999 19.2872 15.3749 18.381 16.4999 18.381ZM13.0937 24.7872C13.3437 23.9747 14.0937 23.381 14.9999 23.381H15.2499C15.6249 23.5685 16.0312 23.631 16.4999 23.631C16.9374 23.631 17.3437 23.5685 17.7187 23.381H17.9999C18.8749 23.381 19.6249 23.9747 19.8749 24.7872C19.9999 25.0997 19.7187 25.381 19.4062 25.381H13.5624C13.2499 25.381 12.9687 25.0685 13.0937 24.7872Z" fill="#525357"></path> </svg>
                                            </a>
                                        </div>
                                    </div>
                                    {
                                        products?.length > 0 ?
                                            <div className="products_wrapper">
                                                {
                                                    products.map(product => {
                                                        return (
                                                            <ProductCard product={product} />
                                                        )
                                                    })
                                                }
                                            </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                    ) : (<p>No products found in your favorites list.</p>)
                ) : (
                    <RenderPlaceholder />
                )
            }
        </>
    )
}

export default MyFavorites