import { Link, useNavigate } from 'react-router-dom'
import '../Cart/cart.css'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector, useStore } from 'react-redux'
import { removeFromCart, setCart } from '../../redux/action'
import { convertToCommaSeparate, countriesStates, scrollToTop } from '../../utils/utils'
import toast from 'react-hot-toast'
import axios from 'axios'
import { useAuth } from '../../Hooks/useAuth'
import he from 'he'

const parsePrice = (price, divide = true) => {
    const divider = divide ? 100 : 1;
    return convertToCommaSeparate(price / divider);
};

const EmptyCart = React.lazy(() => import('../../components/EmptyCart'));

const Cart = () => {
    const [couponCode, setCouponCode] = useState('')
    const cartItems = useSelector((state) => state.cartReducer?.items),
        dispatcher = useDispatch(),
        store = useStore(),
        cart = useSelector((state) => state.cartReducer)
    const [isShippingFormLoaded, setIsShippingFormLoaded] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)
    let currency_symbol = '', tax = store?.getState('cartReducer')?.cartReducer?.tax, shippingCost = store?.getState('cartReducer')?.cartReducer?.shippingCost

    const [shippingAddress, setShippingAddress] = useState({
        country: '',
        state: '',
        zipcode: '',
        city: '',
    })

    if (cart) {
        currency_symbol = cart?.currency?.currency_symbol ? cart?.currency?.currency_symbol : '$'
    }

    const handleShippingCostFormSubmit = async (event) => {
        event.preventDefault()
        const _cartKey = cart?.cart_key ? parseInt(cart?.cart_key) : 0
        if (_cartKey < 1)
            return
        const userInner = await auth.getCurrentUser()
        if (userInner) {
            setIsProcessing(true)
            await axios.post(process.env.REACT_APP_WC_API_BASE + `customers/${userInner?.id}`, {
                shipping: {
                    country: shippingAddress?.country,
                    state: shippingAddress?.state,
                    city: shippingAddress?.city,
                    postcode: shippingAddress?.zipcode,
                },
                billing: {
                    country: shippingAddress?.country,
                    state: shippingAddress?.state,
                    city: shippingAddress?.city,
                    postcode: shippingAddress?.zipcode,
                },
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                }
            }).then(res => {
                if (res.status == 200) {
                    validateCart()
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setIsProcessing(false)
            })

        } else {
            if (cart?.cart_key) {
                setIsCartValidating(true)
                await axios.post(process.env.REACT_APP_TONYETRADE_API_BASE + `updatenoncustomercart`, {
                    cart_key: cart?.cart_key,
                    shipping: {
                        country: shippingAddress?.country,
                        state: shippingAddress?.state,
                        city: shippingAddress?.city,
                        postcode: shippingAddress?.zipcode,
                    },
                    billing: {
                        country: shippingAddress?.country,
                        state: shippingAddress?.state,
                        city: shippingAddress?.city,
                        postcode: shippingAddress?.zipcode,
                    }
                }).then(res => { }).catch(err => {
                    console.log(err)
                }).finally(() => {
                    validateCart()
                })
            }
        }
    }

    const handleCouponSubmitForm = () => {
        if (isCartValidating)
            return
        if (couponCode?.trim()?.length < 1) {
            toast.error('Invalid coupon code', {
                position: "top-center",
                style: {
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            })
            return
        }
        const _cartKey = cart?.cart_key ? cart?.cart_key : ''
        if (_cartKey?.length < 1)
            return
        setIsCartValidating(true)
        axios.post(process.env.REACT_APP_WC_STORE_API_BASE + `cart/coupons?code=${couponCode}&cart_key=${_cartKey}`).then(res => {
            if (res?.status == 201) {
                setCouponCode('')
                validateCart()
            } else {
                setIsCartValidating(false)
            }
        }).catch(err => {
            setIsCartValidating(false)
            if (err?.response?.status == 400) {
                let msg = err?.response?.data?.message
                if (err?.response?.data?.code == 'rest_invalid_param') {
                    msg = 'Invalid coupon code'
                }
                toast.error(he.decode(msg), {
                    position: "top-center",
                    style: {
                        borderColor: 'red',
                        borderWidth: '1px',
                        borderStyle: 'solid'
                    }
                })
            }
        })
    }

    const navigation = useNavigate()
    const auth = useAuth()
    const [isCartValidating, setIsCartValidating] = useState(false)
    const validateCart = useCallback(async () => {
        const userInner = await auth.getCurrentUser()
        let headers = {}, queryParamsObj = {}
        if (userInner) {
            headers = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                }
            }
        }
        if (!userInner && cart?.cart_key) {
            queryParamsObj.cart_key = cart?.cart_key
        }
        let queryParams = new URLSearchParams(queryParamsObj);

        if (userInner || 1) {
            setIsCartValidating(true)
            axios.get(process.env.REACT_APP_CC_API_BASE + `cart?${queryParams}`, headers).then(res => {
                if (res?.status == 200 && undefined != res?.data?.cart_key) {

                    setShippingAddress({
                        country: cart?.customer?.shipping_address?.shipping_country,
                        state: cart?.customer?.shipping_address?.shipping_state,
                        zipcode: cart?.customer?.shipping_address?.shipping_postcode,
                        city: cart?.customer?.shipping_address?.shipping_city,
                    })

                    if (countriesStates[cart?.customer?.shipping_address?.shipping_country]?.states?.length) {
                        setStateType('select')
                    }
                    if (cart?.cart_hash != res?.data?.cart_hash) {
                        // console.log(res?.data)
                        dispatcher(setCart(res?.data))
                    }
                }
            }).catch(err => {

            }).finally(() => {
                setIsCartValidating(false)
                setIsShippingFormLoaded(false)
            })
        }
    })
    useEffect(() => {
        if (cartItems?.length > 0) {
            validateCart()
        }
        scrollToTop()
    }, [navigation])

    const removeFromCartAPI = async (item) => {
        let headers = {}, cartKey = ''
        const userInner = await auth.getCurrentUser()
        if (userInner) {
            headers = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                }
            }
        } else {
            cartKey = cart?.cart_key ? `?cart_key=${cart?.cart_key}` : ''
            // cartKey = `?cart_key=${cart?.cart_key ? cart?.cart_key : 0}`
        }
        if (userInner || 1) {
            setIsCartValidating(true)
            await axios.delete(process.env.REACT_APP_CC_API_BASE + `cart/item/${item?.item_key}${cartKey}`, headers).then(res => {
                validateCart()
            }).catch(err => {

            }).finally(() => {
                setIsCartValidating(false)
            })
        }
    }

    const handleRemoveCoupon = async (coupon) => {
        if (isCartValidating)
            return
        if (coupon?.length < 1)
            return
        const _cartKey = cart?.cart_key ? cart?.cart_key : ''
        if (_cartKey?.length < 1)
            return
        setIsCartValidating(true)
        axios.delete(process.env.REACT_APP_WC_STORE_API_BASE + `cart/coupons/${coupon}?cart_key=${_cartKey}`).then(res => {
            if (res?.status == 204) {
                validateCart()
            } else {
                setIsCartValidating(false)
            }
        }).catch(err => {
            console.log(err)
        })
    }
    const [stateType, setStateType] = useState('text')

    return (
        <>
            <div className="checkout-page-title">
                <nav className="cart-breadcrumbs">
                    <Link to={`/cart`} className="current active-link">Shopping Cart</Link>
                    <Link to={`/checkout`} className="hide-for-small">Checkout details</Link>
                    <Link to={``} className="hide-for-small">Order Complete</Link>
                </nav>
            </div>
            <div className="container mt-0-small">
                {cartItems?.length < 1 && (
                    <React.Suspense fallback={''}>
                        <EmptyCart />
                    </React.Suspense>
                )}
                {
                    cartItems?.length > 0 ?
                        <div className={`cart-wrapper ${isCartValidating ? 'processing' : ''} wrap-small`}>
                            <div className="cart-left full-width-small p-0-small">
                                <form className="woocommerce-cart-form" onSubmit={(event) => {
                                    event.preventDefault()
                                }}>
                                    <div className="cart-wrapper">
                                        <table className="shop_table" cellSpacing="0">
                                            <thead>
                                                <tr className="product-item-heading">
                                                    <th className="product-name" colSpan="3">
                                                        Product</th>
                                                    <th className="product-price hidden-small">Price</th>
                                                    <th className="product-quantity">Quantity
                                                    </th>
                                                    <th className="product-subtotal hidden-small">Subtotal
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    cartItems?.map(item => {
                                                        return (
                                                            <tr className="woocommerce-cart-form__cart-item cart_item">
                                                                <td className="product-remove">
                                                                    <a onClick={(event) => {
                                                                        event.preventDefault()
                                                                        dispatcher(removeFromCart(item))
                                                                        removeFromCartAPI(item)
                                                                    }} href="#" className="remove" aria-label="Remove this item" data-product_id="1085596" data-product_sku="19th-century-canton-1">×</a>
                                                                </td>
                                                                <td className="product-thumbnail">
                                                                    <a href="#">
                                                                        <img className="product-img" src={item?.featured_image?.replace('staging.', 'www.')} loading='lazy' alt={item?.name} />
                                                                    </a>
                                                                </td>
                                                                <td className="product-name title">
                                                                    <Link className="product-titlee" to={`/${item?.slug}`} >
                                                                        {item?.name}
                                                                    </Link>
                                                                    <p className='hidden-large'>
                                                                        <span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">{currency_symbol}</span>{parsePrice(item?.totals?.total, false)}</bdi></span> x 1
                                                                    </p>
                                                                </td>
                                                                <td className="product-price hidden-small" data-title="Price">
                                                                    <span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">{currency_symbol}</span>{parsePrice(item?.totals?.total, false)}</bdi></span>
                                                                </td>
                                                                <td className="product-quantity" data-title="Quantity">{item?.quantity?.value} <input type="hidden" name="cart[5d9910d18044a72f118e8ffe2cb6fadc][qty]" value="1" />
                                                                </td>
                                                                <td className="product-subtotal hidden-small">
                                                                    <span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">{currency_symbol}</span>{parsePrice(item?.totals?.subtotal)}</bdi></span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan="6" className="actions clear">
                                                        <div className="main-button-div">
                                                            <div className="continue-shopping">
                                                                <Link to={`/shop`} className="button-continue-shopping" href="#">←&nbsp;Continue shopping</Link>
                                                            </div>
                                                            <button type="submit" className="update-cart-btn">Update cart</button>
                                                            <input type="hidden" id="woocommerce-cart-nonce" name="woocommerce-cart-nonce" value="554577a599" />
                                                            <input type="hidden" name="_wp_http_referer" value="/cart/" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                            <div className={`cart-right ${isProcessing ? 'processing' : ''} full-width-small`}>
                                <div className="cart-sidebar">
                                    <div className="cart_totals ">
                                        <table cellSpacing="0" className='full-width'>
                                            <thead>
                                                <tr className="product-item-heading">
                                                    <th className="product-name" colSpan="2">Cart totals</th>
                                                </tr>
                                            </thead>
                                        </table>
                                        <table cellSpacing="0" className="shop_table shop_table_responsive cart_page_table">
                                            <tbody>
                                                <tr className="cart-subtotal">
                                                    <th className="cart-total-heading">Subtotal</th>
                                                    <td><span className="woocommerce-Price-amount"><span
                                                        className="woocommerce-Price-currencySymbol">{currency_symbol}</span>{parsePrice(cart?.totals?.subtotal)}</span>
                                                    </td>
                                                </tr>
                                                {
                                                    cart?.needs_shipping ?
                                                        <tr className="shipping">
                                                            <td colSpan="2" className='shipping__inner'>
                                                                <table className='shipping__table'>
                                                                    <tbody>
                                                                        <tr>
                                                                            <th className='cart-total-heading'>
                                                                                Shipping
                                                                            </th>
                                                                            <td>
                                                                                {
                                                                                    typeof cart?.totals?.shipping_total != 'undefined' && parseInt(cart?.totals?.shipping_total) > 0 ?
                                                                                        <ul id="shipping_method" className="shipping__list woocommerce-shipping-methods">
                                                                                            <li className="shipping__list_item">
                                                                                                <input type="hidden" name="shipping_method[0]" data-index="0" id="shipping_method_0_flat_rate1" value="flat_rate:1" className="shipping_method" />
                                                                                                <label className="shipping__list_label" htmlFor="shipping_method_0_flat_rate1">
                                                                                                    Shipping Cost: <span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">{currency_symbol}</span>{parsePrice(cart?.totals?.shipping_total)}</bdi></span>
                                                                                                </label>
                                                                                            </li>
                                                                                        </ul> : null
                                                                                }
                                                                                {
                                                                                    shippingAddress?.country?.length && shippingAddress?.state?.length && shippingAddress?.city?.length && shippingAddress?.zipcode?.length ?
                                                                                        <p className="m-0 text-right woocommerce-shipping-destination mb-5">Shipping to:&nbsp;
                                                                                            {
                                                                                                shippingAddress?.country?.trim()?.length > 0 && shippingAddress?.city?.trim()?.length > 0 && shippingAddress?.zipcode?.trim()?.length > 0 ?
                                                                                                    <strong>{`${shippingAddress?.city}, ${shippingAddress?.state} ${shippingAddress?.zipcode}`}</strong> : null
                                                                                            }
                                                                                        </p> : null
                                                                                }
                                                                                <form className="woocommerce-shipping-calculator" onSubmit={event => {
                                                                                    handleShippingCostFormSubmit(event)
                                                                                }}>
                                                                                    <a href='#' className="shipping-calculator-button" onClick={(event) => {
                                                                                        event.preventDefault()
                                                                                        setIsShippingFormLoaded(!isShippingFormLoaded)
                                                                                    }}>Calculate shipping</a>
                                                                                    <section className={`shipping-calculator-form ${isShippingFormLoaded ? 'loaded' : ''}`}>
                                                                                        <p className="form-row">
                                                                                            <select className='input-text' name="calc_shipping_country" required="required" value={shippingAddress?.country} onChange={(event) => {
                                                                                                setShippingAddress({
                                                                                                    ...shippingAddress,
                                                                                                    country: event.target.value,
                                                                                                })
                                                                                                if (countriesStates[event.target.value]?.states?.length) {
                                                                                                    setStateType('select')
                                                                                                } else {
                                                                                                    setStateType('text')
                                                                                                }
                                                                                            }}>
                                                                                                <option value="">Select a country / region…</option>
                                                                                                {
                                                                                                    Object.entries(countriesStates)?.map((country, index) => {
                                                                                                        return (
                                                                                                            <option key={country[0]} value={country[0]}>
                                                                                                                {country[1]?.name}
                                                                                                            </option>
                                                                                                        )
                                                                                                    })
                                                                                                }
                                                                                            </select>
                                                                                        </p>
                                                                                        {
                                                                                            stateType == 'text' ?
                                                                                                <p className="address-field">
                                                                                                    <input type="text" className="input-text" value={shippingAddress?.state} required="required" onChange={(event) => {
                                                                                                        setShippingAddress({
                                                                                                            ...shippingAddress,
                                                                                                            state: event.target.value,
                                                                                                        })
                                                                                                    }} placeholder="State / County" />
                                                                                                </p> : null
                                                                                        }
                                                                                        {
                                                                                            stateType == 'select' ?
                                                                                                <p className="fields-row required" id="billing_state_field" data-priority="80">
                                                                                                    <span className="woocommerce-input-wrapper">
                                                                                                        <select autoComplete="address-level1" data-placeholder="Select an option…" data-input-classes="" data-label="State" tabIndex="-1" aria-hidden="true" className='full-width' required="required" value={shippingAddress?.state} onChange={event => {
                                                                                                            setShippingAddress({
                                                                                                                ...shippingAddress,
                                                                                                                state: event.target.value,
                                                                                                            })
                                                                                                        }}>
                                                                                                            <option value="">
                                                                                                                Select a state...
                                                                                                            </option>
                                                                                                            {
                                                                                                                countriesStates[shippingAddress?.country]?.states.length ?
                                                                                                                    countriesStates[shippingAddress?.country]?.states?.map(state => {
                                                                                                                        return (
                                                                                                                            <option value={state?.key}>
                                                                                                                                {state?.value}
                                                                                                                            </option>
                                                                                                                        )
                                                                                                                    }) : null
                                                                                                            }
                                                                                                        </select>
                                                                                                    </span>
                                                                                                </p>
                                                                                                : null
                                                                                        }
                                                                                        <p className="address-field">
                                                                                            <input type="text" className="input-text" value={shippingAddress?.city} onChange={(event) => {
                                                                                                setShippingAddress({
                                                                                                    ...shippingAddress,
                                                                                                    city: event.target.value,
                                                                                                })
                                                                                            }} placeholder="City" name="calc_shipping_city" required="required" />
                                                                                        </p>
                                                                                        <p className="address-field" id="calc_shipping_postcode_field">
                                                                                            <input type="text" className="input-text" value={shippingAddress?.zipcode} onChange={(event) => {
                                                                                                setShippingAddress({
                                                                                                    ...shippingAddress,
                                                                                                    zipcode: event.target.value,
                                                                                                })
                                                                                            }} placeholder="Postcode / ZIP" name="calc_shipping_postcode" required="required" />
                                                                                        </p>
                                                                                        <p>
                                                                                            <button type="submit" name="calc_shipping" value="1" className="button-update">Update</button>
                                                                                        </p>
                                                                                    </section>
                                                                                </form>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                                {
                                                    Array.isArray(cart?.coupons) && cart?.coupons?.length ?
                                                        cart?.coupons?.map(coupon => {
                                                            return (
                                                                <tr className='coupon_row'>
                                                                    <th>{coupon?.label}</th>
                                                                    <td>
                                                                        <span dangerouslySetInnerHTML={{ __html: coupon?.saving_html }} /> <a className='remove_applied_coupon' href='#' onClick={(event) => {
                                                                            event.preventDefault()
                                                                            handleRemoveCoupon(coupon?.coupon)
                                                                        }}>[Remove]</a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }) : null
                                                }
                                                {
                                                    typeof cart?.totals?.total_tax != 'undefined' && parseInt(cart?.totals?.total_tax) > 0 ?
                                                        <tr className="order-total">
                                                            <th className="cart-total-heading">Tax</th>
                                                            <td>
                                                                <strong>
                                                                    <span className="woocommerce-Price-amount amount">
                                                                        <bdi>
                                                                            <span className="woocommerce-Price-currencySymbol">
                                                                                {currency_symbol}
                                                                            </span>
                                                                            {parsePrice(cart?.totals?.total_tax, true)}
                                                                        </bdi>
                                                                    </span>
                                                                </strong>
                                                            </td>
                                                        </tr> : null
                                                }
                                                <tr className="order-total pt-10">
                                                    <th className="cart-total-heading">Total</th>
                                                    <td>
                                                        <strong>
                                                            <span className="woocommerce-Price-amount amount">
                                                                <span className="woocommerce-Price-currencySymbol">{currency_symbol}</span>{parsePrice(cart?.totals?.total)}
                                                            </span>
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="wc-proceed-to-checkout">
                                            <Link to={`/checkout`} className="checkout-button ">Proceed to checkout</Link>
                                        </div>
                                    </div>
                                    <form className="checkout_coupon" onSubmit={(e) => {
                                        e.preventDefault();
                                        handleCouponSubmitForm()
                                    }}>
                                        <div className="coupon">
                                            <h3 className="widget-title"><i className="icon-tag"></i> Coupon</h3>
                                            <input type="text" name="coupon_code br-0" className="input-text coupon" value={couponCode} placeholder="Coupon code" onChange={(e) => {
                                                setCouponCode(e.target.value?.trim())
                                            }} />
                                            <input className="submit-btn full-width mt-15" type="submit" name="apply_coupon" value="Apply coupon" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        </>
    )
}

export default Cart