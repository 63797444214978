import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { priceFilters, scrollToTop } from '../../utils/utils'
import SearchBar from '../../components/Searchbar'
import he from 'he'
import ProductCard from '../../components/ProductCard'
import { ReactComponent as Filter } from '../../assets/svgs/filter.svg'
import { ReactComponent as Menubar } from '../../assets/svgs/menubar.svg'
import { ReactComponent as Usercard } from '../../assets/svgs/usercard.svg'
import Placeholder from '../../components/Placeholder'
import $ from 'jquery'

const RenderSingleProductCard = ({ product }) => <ProductCard product={product} />;

const Search = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [products, setProducts] = useState([])
  const [headertitle, setHeaderTitle] = useState('')
  const [paginationLevel, setPaginationLevel] = useState('')
  const [filters, setFilters] = useState({
    manufacturer: [],
    team: [],
    sport: [],
    player: [],
    price: [],
    grade: [],
    year: [],
  })
  const [layout, setLayout] = useState('grid')
  const [taxQuery, setTaxQuery] = useState(undefined)
  const [isFilteringProducts, setIsFilteringProducts] = useState(false)
  const [advacnedFiltersOpened, setAdvancedFiltersOpened] = useState(false)
  const [pagination, setPagination] = useState({
    totalPages: 0,
    currentPage: 1,
    totalPosts: 0,
  })
  const [activeFilters, setActiveFilters] = useState({
    manufacturer: [],
    team: [],
    sport: [],
    player: [],
    price: [],
    grade: [],
    year: [],
  })

  const navigate = useNavigate()
  const location = useLocation()

  const getFilters = useCallback(async (query) => {
    axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `getfilters?query=${query}`).then(res => {
      if (res?.status == 200 && res?.data?.results && Array.isArray(res?.data?.results) && res?.data?.results?.length > 0) {
        const filtersInner = {
          manufacturer: [],
          team: [],
          sport: [],
          price: [],
          year: [],
        }
        res?.data?.results.map(result => {
          if (undefined != filtersInner[result?.facet_name]) {
            const existingItem = filtersInner[result?.facet_name].find(item => item.id === result?.term_id);
            if (!existingItem) {
              filtersInner[result?.facet_name]?.push({
                name: result?.facet_display_value,
                id: result?.term_id,
              });
            }
          }
        })
        setFilters(filtersInner)
      }
    }).catch(err => {
    })
  })

  const getProducts = useCallback(async (currentPage = 1) => {
    setPagination({
      ...pagination,
      currentPage: currentPage
    })
    let lastPagination = '', searchQueryInner = ''

    const searchParams = new URLSearchParams(location.search)
    searchQueryInner = searchParams?.get('s')
    if (searchQueryInner) {
      searchQueryInner = searchQueryInner?.trim()
    }

    if (searchQueryInner == undefined) {
      setHeaderTitle('Inventory')
    } else if (searchQueryInner !== undefined) {
      lastPagination = `${searchQueryInner?.replaceAll('-', ' ')}`
      setHeaderTitle(lastPagination)
      setPaginationLevel(lastPagination)
    }

    if (searchQueryInner?.length) {

      const queryParams = new URLSearchParams({
        page: currentPage,
        taxQuery: JSON.stringify(taxQuery),
        s: searchQueryInner,
        isSearchOnly: true
      })

      axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `products/placeholder?${queryParams}`).then(res => {
        if (res?.status == 200 && res?.data?.products && Array.isArray(res?.data?.products) && res?.data?.products?.length) {
          const productsInner = res?.data
          if (!products) {
            setProducts(productsInner?.products)
          } else {
            setProducts(prevProducts => [...(prevProducts || []), ...(productsInner?.products || [])]);
          }
          window.isLoading = false;
          setPagination({
            ...pagination,
            totalPages: res?.data?.total_pages,
            totalPosts: res?.data?.total_posts,
          })

        }
      }).catch(err => {

      }).finally(() => {

      })
    }
  }, [location.search, taxQuery, getFilters])

  useEffect(() => {
    // scrollToTop()
    const searchParams = new URLSearchParams(location.search)
    let searchQuery = searchParams?.get('s')
    if (searchQuery) {
      searchQuery = searchQuery?.trim()
    }
    setProducts([])
    setTaxQuery(undefined)
    setActiveFilters({
      manufacturer: [],
      team: [],
      sport: [],
      player: [],
      price: [],
      grade: [],
      year: [],
    })
    if (searchQuery?.length) {
      setSearchQuery(searchQuery)
      getProducts()
      document.addEventListener('scroll', function (event) {
        var t = $(window);
        var elem = $('#infinite');
        if (typeof elem == 'undefined' || elem.length < 1) {
          return;
        }
        if (window.isLoading == undefined)
          window.isLoading = false;
        if (!window.isLoading && (t.scrollTop() + t.height()) >= (elem.offset().top - (2 * elem.height()))) {
          window.isLoading = true;
          if ($('#shop_pagination .active').next('li').length) {
            setTimeout(() => {
              $('#shop_pagination .active').next('li').trigger('click');
            }, 1000);
          } else {
            elem.remove()
          }
        }
      });
    }
  }, [navigate])
  // }, [navigate, location.search])


  const handlePaginationChange = useCallback(async (index) => {
    // scrollToTop()
    const newPage = index + 1
    setPagination({
      ...pagination,
      currentPage: newPage,
    })
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('page', newPage);
    const newSearchString = searchParams.toString();
    if (newPage > 1) {
      navigate(location.pathname + `?${newSearchString}`, { replace: true })
    } else {
      navigate(location.pathname, { replace: true })
    }
    getProducts(newPage)
  }, [location.search, navigate])

  const handleLayoutChange = (event) => {
    event.preventDefault()
    setLayout(event.currentTarget.getAttribute('data-type'))
  }

  const filterProducts = useCallback(async () => {
    const queryParams = new URLSearchParams({
      taxQuery: JSON.stringify(taxQuery)
    });
    axios.post(process.env.REACT_APP_TONYETRADE_API_BASE + `getfilteredproducts?${queryParams}`, {
      filters: activeFilters,
    }).then(res => {

      const productsInner = res?.data
      if (productsInner && typeof productsInner !== 'undefined' && Array.isArray(productsInner?.products)) {
        setProducts(productsInner?.products)
        setPagination({
          ...pagination,
          totalPages: productsInner?.total_pages,
          totalPosts: productsInner?.total_posts,
        })
        if (typeof productsInner?.tax_query !== 'undefined' && Array.isArray(productsInner?.tax_query) && productsInner?.tax_query?.length > 0) {
          setTaxQuery(productsInner?.tax_query)
        }
      }

    }).catch(err => {

    })
  }, [activeFilters, taxQuery])

  const handleFilterChange = useCallback((type, value, isChecked) => {
    let updatedFilters = { ...activeFilters };
    if (isChecked) {
      if (!updatedFilters[type].includes(value)) {
        updatedFilters[type] = [...updatedFilters[type], value];
      }
    } else {
      updatedFilters[type] = updatedFilters[type].filter(item => item !== value);
    }
    setActiveFilters(updatedFilters);
    filterProducts()
  }, [filterProducts])

  const renderedFilters = useCallback((type) => {
    return (
      <>
        {filters[type]?.length && (
          <div id={`filter_${type}`} className="advanced_filter" data-filter={type}>
            <h2 className="heading">{type}</h2>
            <div className="inner">
              {filters[type].map((m) => (
                <label key={m.id}>
                  <input value={m.id} type="checkbox" name={`filter_${type}`} onChange={(e) => handleFilterChange(type, e.target.value, e.target.checked)} /> {he.decode(m.name)}{' '}
                  <span className="checkmark"></span>
                </label>
              ))}
            </div>
          </div>
        )}
      </>
    );
  }, [filters, handleFilterChange]);

  return (
    <>
      <div className="single_page_header_wrapper">
        <div className="container wide">
          {/* <div className="single_page_breadcrumb">
            <ul className="text-white m-0 flex gap-20">
              <li className="mb-0 small">
                <Link to={`/shop`}>
                  Inventory
                </Link>
              </li>
              {paginationLevel?.length > 0 ?
                <li className="mb-0">
                  {paginationLevel}
                </li> : null}

            </ul>
          </div> */}
          <h3 className="single_page_header_title saerch">
            {headertitle}
          </h3>
        </div>
      </div>
      <SearchBar />
      {
        products && products?.length ?
          <div className={`products_wrapper_outer flex ${advacnedFiltersOpened ? 'sidebar_filter_opened' : ''} ${isFilteringProducts ? 'processing' : ''} `}>
            <div id="advanced_filters">
              {renderedFilters('manufacturer')}
              {renderedFilters('team')}
              {renderedFilters('sport')}
              {renderedFilters('player')}
              <div id="filter_price" className="advanced_filter" data-filter="price">
                <h2 className="heading">Price</h2>
                <div className="inner">
                  {
                    priceFilters?.map(p => {
                      return (
                        <label>
                          <input value={p?.val} type="checkbox" name="filter_price" onChange={(event) => {
                            handleFilterChange('price', event.target.value, event.target.checked)
                          }} /> {p?.label} <span className="checkmark"></span>
                        </label>
                      )
                    })
                  }
                  <div className="grouped custom_price">
                    <div className="single_custom_price">
                      <span>$</span>
                      <input type="number" name="min_price" placeholder="Min" />
                    </div>
                    <div className="single_custom_price">
                      <span>$</span>
                      <input type="number" name="max_price" placeholder="Max" />
                    </div>
                    <button id="apply_c_price">Apply</button></div>
                </div>
              </div>
              {renderedFilters('grade')}
              {renderedFilters('year')}
            </div>
            <div className={`products_wrapper_inner ${layout}`}>
              <div className="shop_container">
                <div className="products_filter_header_wrapper">
                  <div className='left'>
                    {/* <a id="adv_filter_opener" href="#" onClick={event => {
                      event.preventDefault()
                      setAdvancedFiltersOpened(!advacnedFiltersOpened)
                    }}>
                      <Filter />
                    </a> */}
                    <span className='left_side_results_holder'>
                      <span className='number'>{pagination?.totalPosts}</span> Results
                    </span>
                  </div>
                  <div className='middle'>
                    <div className='custom_product_sorter'>

                    </div>
                  </div>
                  <div className='right'>
                    <a href="#" data-type="list" className={`${layout == 'list' ? 'active' : ''}`} onClick={event => {
                      handleLayoutChange(event)
                    }}>
                      <Menubar />
                    </a>
                    <a href="#" data-type="grid" className={`${layout == 'grid' ? 'active' : ''}`} onClick={event => {
                      handleLayoutChange(event)
                    }}>
                      <Usercard />
                    </a>
                  </div>
                </div>
                {
                  products?.length > 0 ?
                    <div className="products_wrapper">
                      {
                        products.map(product => {
                          return (
                            <RenderSingleProductCard key={product?.id} product={product} />
                          )
                        })
                      }
                    </div> : null
                }
              </div>
              {
                pagination?.totalPages > 1 ?
                  <>
                    <div id="infinite" className='processing'></div>
                    <div className='shop_pagination'>
                      <ul id="shop_pagination" className='flex gap-20 wrap-small'>
                        {
                          Array.from({ length: pagination?.totalPages }, (_, index) => {
                            const searchParams = new URLSearchParams(location.search);
                            const currentPage = searchParams.get('page') || 1;
                            return (
                              <li key={index} className={`${index + 1 == currentPage ? 'active' : ''}`} onClick={() => {
                                handlePaginationChange(index)
                              }}>
                                {index + 1}
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </> : null
              }
            </div>
          </div>
          : null
      }
      {
        products?.length < 1 ?
          <Placeholder count={6} /> : null
      }
    </>
  )
}

export default Search