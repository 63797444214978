import { openDB } from 'idb';

const dbPromise = openDB('tonyadmin-auth-db', 1, {
    upgrade(db) {
        db.createObjectStore('auth', {
            keyPath: 'key',
        });
    },
});

export const saveUserToIndexedDB = async (user) => {
    const db = await dbPromise;
    await db.put('auth', { key: 'user', value: user });
};

export const clearUserFromIndexedDB = async (user) => {
    const db = await dbPromise;
    await db.delete('auth', 'user');
    return true;
};

export const getUserFromIndexedDB = async () => {
    const db = await dbPromise;
    const user = await db.get('auth', 'user');
    return user ? user.value : null;
};

export const removeUserFromIndexedDB = async () => {
    const db = await dbPromise;
    await db.delete('auth', 'user');
};
