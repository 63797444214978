import '../MostWantedList/mostwantedlist.css'
import React, { useEffect } from "react"
import firebird from '../../assets/images/mostwanted/firebird-2-small.png'
import img6 from '../../assets/images/mostwanted/image-6.png'
import img14 from '../../assets/images/mostwanted/image-14.png'
import img17 from '../../assets/images/mostwanted/image-17.png'
import img19 from '../../assets/images/mostwanted/image-19.png'
import img20 from '../../assets/images/mostwanted/image-20.png'
import img21 from '../../assets/images/mostwanted/image-21.png'
import s1600 from '../../assets/images/mostwanted/s-l1600_2-1-1.png'
import memorabilia from '../../assets/images/mostwanted/tony_e_trade_sports_memorabilia-1.png'
import { scrollToTop } from '../../utils/utils'

const MostWantedList = ()=>{

    useEffect(()=>{
        scrollToTop()
    },[])

    return(
        <>
            <div className="single_post_header flex">
                <div className="container">
                    <h2 className="entry-title">
                        THE MOST WANTED LIST
                    </h2>
                </div>
            </div>
            <div className="container pt-0 pb-0">
                <div className='text-desc-wrapper flex'>
                    <p className="text-desc full-width-small">
                        We pay top dollar to collections or dealers for collections. We have the means to buy virtually any collection and we can travel to you the following day for large collections. All transactions are confidential and can be made with any form of payment.
                    </p>
                </div>
                <div className='most_wanted_list_wrapper flex wrap'>
                    <div className='single_most_wanted_card full-width-small'>
                        <div className='single_most_wanted_card_inner'>
                            <div className='card_image_wrapper'>
                                <img src={img6} />
                            </div>
                            <div className='single_most_wanted_card_content_wrapper'>
                                <h4 className='single_most_wanted_card_title'>
                                    19TH CENTURY CARDS
                                </h4>
                                <p className='single_most_wanted_card_desc'>
                                    Allen and Ginter, Goodwin Champs, Mayos, Old Judge
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='single_most_wanted_card full-width-small'>
                        <div className='single_most_wanted_card_inner'>
                            <div className='card_image_wrapper'>
                                <img src={img14} />
                            </div>
                            <div className='single_most_wanted_card_content_wrapper bg-dark'>
                                <h4 className='single_most_wanted_card_title'>
                                    PRE-1975 SPORTS CARDS
                                </h4>
                                <p className='single_most_wanted_card_desc'>
                                    Goudey, US Caramel, Topps, and Bowman including Subsets and Test issues
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='single_most_wanted_card full-width-small'>
                        <div className='single_most_wanted_card_inner'>
                            <div className='card_image_wrapper'>
                                <img src={img21} />
                            </div>
                            <div className='single_most_wanted_card_content_wrapper'>
                                <h4 className='single_most_wanted_card_title'>
                                    REGIONAL ISSUES
                                </h4>
                                <p className='single_most_wanted_card_desc'>
                                    Kahns, Dan Dees, Wilson Wieners…
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='single_most_wanted_card full-width-small'>
                        <div className='single_most_wanted_card_inner'>
                            <div className='card_image_wrapper'>
                                <img src={s1600} />
                            </div>
                            <div className='single_most_wanted_card_content_wrapper bg-dark'>
                                <h4 className='single_most_wanted_card_title'>
                                    PSA/GAI/SGC GRADED CARDS
                                </h4>
                                <p className='single_most_wanted_card_desc'>
                                    Any graded card of any grade can be quoted site unseen
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='single_most_wanted_card full-width-small'>
                        <div className='single_most_wanted_card_inner'>
                            <div className='card_image_wrapper'>
                                <img src={img17} />
                            </div>
                            <div className='single_most_wanted_card_content_wrapper'>
                                <h4 className='single_most_wanted_card_title'>
                                    UNOPENED MATERIAL
                                </h4>
                                <p className='single_most_wanted_card_desc'>
                                    Pre – 1979
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='single_most_wanted_card full-width-small'>
                        <div className='single_most_wanted_card_inner'>
                            <div className='card_image_wrapper'>
                                <img src={img20} />
                            </div>
                            <div className='single_most_wanted_card_content_wrapper bg-dark'>
                                <h4 className='single_most_wanted_card_title'>
                                    COMPLETE SETS
                                </h4>
                                <p className='single_most_wanted_card_desc'>
                                    Pre-1969l
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='single_most_wanted_card full-width-small'>
                        <div className='single_most_wanted_card_inner'>
                            <div className='card_image_wrapper'>
                                <img src={img19} />
                            </div>
                            <div className='single_most_wanted_card_content_wrapper'>
                                <h4 className='single_most_wanted_card_title'>
                                    COMIC BOOKS
                                </h4>
                                <p className='single_most_wanted_card_desc'>
                                    Specifically looking for Vintage quality material
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='single_most_wanted_card full-width-small'>
                        <div className='single_most_wanted_card_inner'>
                            <div className='card_image_wrapper'>
                                <img src={firebird} />
                            </div>
                            <div className='single_most_wanted_card_content_wrapper bg-dark'>
                                <h4 className='single_most_wanted_card_title'>
                                    TOYS
                                </h4>
                                <p className='single_most_wanted_card_desc'>
                                    Vintage classics and historic material
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='single_most_wanted_card full-width-small'>
                        <div className='single_most_wanted_card_inner'>
                            <div className='card_image_wrapper'>
                                <img src={memorabilia} />
                            </div>
                            <div className='single_most_wanted_card_content_wrapper'>
                                <h4 className='single_most_wanted_card_title'>
                                    SPORTS MEMORABILIA
                                </h4>
                                <p className='single_most_wanted_card_desc'>
                                    Vintage player photos, autographed baseballs, boxing gloves, jerseys
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MostWantedList