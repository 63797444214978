import axios from 'axios'
import '../MyOrders/myorders.css'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Hooks/useAuth'
import { Link } from 'react-router-dom'

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
}

function MyOrders() {
  const [orders, setOrders] = useState([])
  const auth = useAuth()
  useEffect(() => {
    const getOrders = async () => {
      const user = await auth.getCurrentUser()
      if (user) {
        const queryParams = new URLSearchParams({
          // customer: 6612
          customer: user?.id
        });
        await axios.get(process.env.REACT_APP_WC_API_BASE + `orders?${queryParams}`, {
          headers: {
            'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (res?.status == 200 && Array.isArray(res?.data) && res?.data?.length > 0) {
            setOrders(res?.data)
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
    getOrders()
  }, [])
  return (
    <div className="container wide pt-0 pb-0">
      <nav className="breadcrumbs">
        <a href="#" className="current">Accounts</a> | <span className="divider "></span>
        <a href="#" className="hide-for-small">My Orders</a>
        <span className="divider "></span>
      </nav>
      <div className="order-search">
        <h2 className="heading-orders">Your Orders</h2>
        <div className="main-order hidden-small">
          <div className="menu">
            <div className="menu-item">Orders</div>
          </div>
          <div className="search">
            <input type="text" placeholder="Search..." />
            <button>Search Order</button>
          </div>
        </div>
      </div>
      <div className="dropdown-div flex align-center">
        <label className="dropdown-label mb-0">{orders?.length} orders placed in </label>
        <select className="month-dropdown" name="months" id="months">
          <option value="3-month" selected>Past 3 months</option>
          <option value="2-month">Past 2 months</option>
          <option value="4-month">Past 4 months</option>
          <option value="6-month">Past 6 months</option>
        </select>
      </div>
      <div className="maiin-div">
        {
          orders?.length > 0 ?
            orders?.map(order => {
              return (
                <div className="order-summary">
                  <div className="order-header wrap-small">
                    <div className="order-left wrap-small full-width-small">
                      <div className="order-date">
                        <span className="order-date-title">ORDER PLACED</span>
                        <span className="order-date-dec">
                          {formatDate(order?.date_created_gmt)}
                        </span>
                      </div>
                      <div className="orders-total">
                        <span className="order-date-title">TOTAL</span>
                        <span className="order-date-dec">${order?.total}</span>
                      </div>
                      <div className="order-ship-to">
                        <select className="month-dropdown" name="ship tp" id="months">
                          <option value="john" selected>SHIP TO</option>
                        </select>
                        <span className="order-date-dec">{order?.billing?.first_name} {order?.billing?.last_name}</span>
                      </div>
                    </div>
                    <div className="order-right full-width-small">
                      <div className="order-id text-right">
                        <span className="orderid-title">ORDER #{order?.id}</span>
                        <Link className="link-order" to={`/my-account/view-order/${order?.id}`}>View order details</Link>
                      </div>
                    </div>
                  </div>
                  <div className="order-content wrap-small">
                    <div className="header-content-left mb-10">
                      <span className="left-content-heading mb-0-small">{order?.status}</span>
                      <div className="item">
                        <img src={order?.line_items[0]?.image?.src} alt="" />
                        <div className="product-details">
                          {
                            order?.line_items?.length > 0 ?
                              <ul className='order_items'>
                                {
                                  order?.line_items?.map(item => {
                                    return (
                                      <li>
                                        {item?.name}
                                      </li>
                                    )
                                  })
                                }
                              </ul> : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className="header-content-right full-width-small">
                      <div className="item-details2 full-width-small">
                        <Link className="buy-btn text-center" to={`/`}>Buy it again</Link>
                        <Link className="view-item text-center">View your item</Link>
                      </div>
                      <div className="item-details-show">
                        <a href="#" className="link-btn">
                          <img src="cart/assets/Chevron down.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                  {
                    order?.line_items?.map(item => {
                      // console.log(item)
                      return (
                        <></>
                      )
                    })
                  }
                </div>
              )
            }) : null
        }
      </div>
    </div>
  )
}

export default MyOrders