import { Link } from 'react-router-dom';
import '../Shop/shop.css'
import React, { useEffect, useMemo } from "react";
import SearchBar from '../../components/Searchbar';
import { scrollToTop, sportsList } from '../../utils/utils';

const Shop = () => {
    useEffect(() => {
        scrollToTop()
    }, [])

    const sportsLinks = useMemo(() => {
        return sportsList?.map(({ slug, title }) => (
            <Link to={`/${slug}`} key={slug}>
                <div className="sportsd">{title}</div>
            </Link>
        ));
    }, [sportsList]);

    return (
        <div className="shop_page_wrapper">
            <div className="shop_page_header">
                <div className="container wide">
                    <h1 className="shop_page_title">
                        Inventory
                    </h1>
                </div>
            </div>
            <div className="shop_page_body">
                <div className='shop_page_search_wrapper'>

                </div>
                <div className='shop_page_content'>
                    <div className="container wide">
                        <SearchBar />
                        <div className='listsports'>
                            {sportsLinks}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Shop