import React from "react";

const PostStatuses = ({ counters, activeFilters, handleStatusFilter }) => {
    const counterEntries = Object.entries(counters);
    return (
        <div class="admin-card-header pt-3 pb-3 d-flex border-top rounded-0 flex-wrap py-0 flex-column flex-md-row align-items-center">
            <div className="col d-flex align-center">
                <ul className="posts_counter_wrapper d-flex gap-2 flex-wrap">
                    {Object.entries(counters).map(([key, value], index) => {
                        return <li className="gap-2 m-0">
                            <a className={`${key == activeFilters.status ? 'active' : ''} gap-1`} href="#" key={key}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleStatusFilter(key)
                                }}
                            >
                                <span className="text">{value.t}</span>({value.c})
                            </a>{index < counterEntries.length - 1 && <span className="sep"> | </span>}
                        </li>
                    })}
                </ul>
            </div>
        </div>
    );
};

export default PostStatuses;