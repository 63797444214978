import { ADD_TO_WISHLIST, REMOVE_FROM_WISHLIST } from "../constants"

export const initialState = localStorage.getItem('tonyetradewishlistlocal') ? JSON.parse(localStorage.getItem('tonyetradewishlistlocal')) : {
    products: [],
    count: 0,
}

export const wishlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_WISHLIST:
            if (!state?.products?.includes(action?.data)) {
                state?.products?.push(action?.data)
            }
            const newData = {
                ...initialState,
                count: state?.products?.length,
            }
            localStorage.setItem('tonyetradewishlistlocal', JSON.stringify(newData))
            return newData
        case REMOVE_FROM_WISHLIST:
            let newDataWishlist = state?.products
            newDataWishlist = state?.products?.filter(item => {
                return item !== action?.data
            })
            const afterRemovedData = {
                ...state,
                count: newDataWishlist?.length,
                products: newDataWishlist
            }
            localStorage.setItem('tonyetradewishlistlocal', JSON.stringify(afterRemovedData))
            return afterRemovedData
        default:
            return state
    }
}