import { put, takeEvery } from 'redux-saga/effects'
import { DEFAULT_CONSTANT } from './constants'

function* sagaData() {
    yield takeEvery(DEFAULT_CONSTANT, defaultFn)
}

export function* defaultFn(){

}

export default sagaData