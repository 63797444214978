import '../MostWantedList/mostwantedlist.css'
import React, { useEffect } from "react"
import firebird from '../../assets/images/mostwanted/firebird-2-small.png'
import img6 from '../../assets/images/mostwanted/image-6.png'
import img14 from '../../assets/images/mostwanted/image-14.png'
import img17 from '../../assets/images/mostwanted/image-17.png'
import img19 from '../../assets/images/mostwanted/image-19.png'
import img20 from '../../assets/images/mostwanted/image-20.png'
import img21 from '../../assets/images/mostwanted/image-21.png'
import s1600 from '../../assets/images/mostwanted/s-l1600_2-1-1.png'
import memorabilia from '../../assets/images/mostwanted/tony_e_trade_sports_memorabilia-1.png'
import { scrollToTop } from '../../utils/utils'

const Terms = ()=>{

    useEffect(()=>{
        scrollToTop()
    },[])

    return(
        <>
            <div className="single_post_header flex">
                <div className="container">
                    <h2 className="entry-title">
                        Terms and Conditions
                    </h2>
                </div>
            </div>
            <div className="container pt-0 pb-0">
                <div className='text-desc-wrapper flex' style={{flexDirection: 'column'}}>
                    <p className="text-desc full-width-small">
                        <strong>
                            Last updated October 31, 2024
                        </strong>
                    </p>
                    <h4>AGREEMENT TO OUR LEGAL TERMS</h4>
                    <p>We are Tonyetrade LLC Enterprises, doing business as Tonyetrade ("Company," "we," "us," "our"), a company registered in Arizona, United States at 15029 N Thompson Peak Pkwy, Suite B-111 #618, Scottsdale, AZ 85260.</p>
                
                    <p>We operate the website <a href="https://tonyetrade.com" target="_blank">https://tonyetrade.com</a> (the "Site"), as well as any other related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").</p>

                    <p>Your source for vintage sports cards, baseball cards, unopened packs, sports memorabilia & comic books.</p>

                    <p>You can contact us by phone at (+1) 513-616-2224, email at <a href="mailto:info@tonyetrade.com">info@tonyetrade.com</a>, or by mail to 15029 N Thompson Peak Pkwy, Suite B-111 #618, Scottsdale, AZ 85260, United States.</p>

                    <p>These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity ("you"), and Tonyetrade LLC Enterprises, concerning your access to and use of the Services. You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.</p>

                    <p>Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms at any time and for any reason. We will alert you about any changes by updating the "Last updated" date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.</p>

                    <p>The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services.</p>

                    <p>We recommend that you print a copy of these Legal Terms for your records.</p>
                    <h4>TABLE OF CONTENTS</h4>
                    <ul>
                        <li>1. OUR SERVICES</li>
                        <li>2. INTELLECTUAL PROPERTY RIGHTS</li>
                        <li>3. USER REPRESENTATIONS</li>
                        <li>4. USER REGISTRATION</li>
                        <li>5. PRODUCTS</li>
                        <li>6. PURCHASES AND PAYMENT</li>
                        <li>7. RETURN POLICY</li>
                        <li>8. PROHIBITED ACTIVITIES</li>
                        <li>9. USER GENERATED CONTRIBUTIONS</li>
                        <li>10. CONTRIBUTION LICENSE</li>
                        <li>11. SERVICES MANAGEMENT</li>
                        <li>12. PRIVACY POLICY</li>
                        <li>13. TERM AND TERMINATION</li>
                        <li>14. MODIFICATIONS AND INTERRUPTIONS</li>
                        <li>15. GOVERNING LAW</li>
                        <li>16. DISPUTE RESOLUTION</li>
                        <li>17. CORRECTIONS</li>
                        <li>18. DISCLAIMER</li>
                        <li>19. LIMITATIONS OF LIABILITY</li>
                        <li>20. INDEMNIFICATION</li>
                        <li>21. USER DATA</li>
                        <li>22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES</li>
                        <li>23. SMS TEXT MESSAGING</li>
                        <li>24. CALIFORNIA USERS AND RESIDENTS</li>
                        <li>25. MISCELLANEOUS</li>
                        <li>26. CONTACT US</li>
                    </ul>
                    <h2>1. OUR SERVICES</h2>
                    <p>The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
                    
                    <p>The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).</p>

                    <h2>2. INTELLECTUAL PROPERTY RIGHTS</h2>
                    
                    <h3>Our Intellectual Property</h3>
                    <p>We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks, and logos contained therein (the "Marks").</p>
                    
                    <p>Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United States and around the world.</p>
                    
                    <p>The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use only.</p>

                    <h3>Your Use of Our Services</h3>
                    <p>Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable, revocable license to:</p>
                    <ul>
                        <li>Access the Services; and</li>
                        <li>Download or print a copy of any portion of the Content to which you have properly gained access,</li>
                    </ul>
                    <p>solely for your personal, non-commercial use.</p>

                    <p>Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.</p>

                    <p>If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: <a href="mailto:info@tonyetrade.com">info@tonyetrade.com</a>. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.</p>

                    <p>We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.</p>

                    <p>Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.</p>

                    <h3>Your Submissions</h3>
                    <p>Please review this section and the "PROHIBITED ACTIVITIES" section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.</p>
                    
                    <h4>Submissions</h4>
                    <p>By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ("Submissions"), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>

                    <h4>You are Responsible for What You Post or Upload</h4>
                    <p>By sending us Submissions through any part of the Services you:</p>
                    <ul>
                        <li>Confirm that you have read and agree with our "PROHIBITED ACTIVITIES" and will not post, send, publish, upload, or transmit through the Services any Submission that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;</li>
                        <li>To the extent permissible by applicable law, waive any and all moral rights to any such Submission;</li>
                        <li>Warrant that any such Submission is original to you or that you have the necessary rights and licenses to submit such Submissions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions; and</li>
                        <li>Warrant and represent that your Submissions do not constitute confidential information.</li>
                    </ul>
                    <p>You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual property rights, or (c) applicable law.</p>
                    <h2>3. USER REPRESENTATIONS</h2>
                    <p>By using the Services, you represent and warrant that:</p>
                    <ul>
                        <li>All registration information you submit will be true, accurate, current, and complete;</li>
                        <li>You will maintain the accuracy of such information and promptly update such registration information as necessary;</li>
                        <li>You have the legal capacity and you agree to comply with these Legal Terms;</li>
                        <li>You are not a minor in the jurisdiction in which you reside;</li>
                        <li>You will not access the Services through automated or non-human means, whether through a bot, script, or otherwise;</li>
                        <li>You will not use the Services for any illegal or unauthorized purpose; and</li>
                        <li>Your use of the Services will not violate any applicable law or regulation.</li>
                    </ul>
                    <p>If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).</p>

                    <h2>4. USER REGISTRATION</h2>
                    <p>You may be required to register to use the Services. You agree to keep your password confidential and will be responsible for all use of your account and password. We reserve the right to remove, reclaim, or change a username you select if we determine, in our sole discretion, that such username is inappropriate, obscene, or otherwise objectionable.</p>

                    <h2>5. PRODUCTS</h2>
                    <p>We make every effort to display as accurately as possible the colors, features, specifications, and details of the products available on the Services. However, we do not guarantee that the colors, features, specifications, and details of the products will be accurate, complete, reliable, current, or free of other errors, and your electronic display may not accurately reflect the actual colors and details of the products. All products are subject to availability, and we cannot guarantee that items will be in stock. We reserve the right to discontinue any products at any time for any reason. Prices for all products are subject to change.</p>

                    <h2>6. PURCHASES AND PAYMENT</h2>
                    <p>We accept the following forms of payment:</p>
                    <ul>
                        <li>Visa</li>
                        <li>Mastercard</li>
                        <li>American Express</li>
                        <li>Discover</li>
                        <li>PayPal</li>
                    </ul>
                    <p>You agree to provide current, complete, and accurate purchase and account information for all purchases made via the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed required by us. We may change prices at any time. All payments shall be in US dollars.</p>
                    <p>You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.</p>
                    <p>We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel quantities purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.</p>

                    <h2>7. RETURN POLICY</h2>
                    <p>Please review our Return Policy posted on the Services prior to making any purchases.</p>

                    <h2>8. PROHIBITED ACTIVITIES</h2>
                    <p>You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.</p>
                    <p>As a user of the Services, you agree not to:</p>
                    <ul>
                        <li>Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
                        <li>Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.</li>
                        <li>Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.</li>
                        <li>Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.</li>
                        <li>Use any information obtained from the Services in order to harass, abuse, or harm another person.</li>
                        <li>Make improper use of our support services or submit false reports of abuse or misconduct.</li>
                        <li>Use the Services in a manner inconsistent with any applicable laws or regulations.</li>
                        <li>Engage in unauthorized framing of or linking to the Services.</li>
                        <li>Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.</li>
                        <li>Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.</li>
                        <li>Delete the copyright or other proprietary rights notice from any Content.</li>
                        <li>Attempt to impersonate another user or person or use the username of another user.</li>
                        <li>Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as "spyware" or "passive collection mechanisms" or "pcms").</li>
                        <li>Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.</li>
                        <li>Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.</li>
                        <li>Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.</li>
                        <li>Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.</li>
                        <li>Except as permitted by applicable law, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.</li>
                        <li>Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or use or launch any unauthorized script or other software.</li>
                        <li>Use a buying agent or purchasing agent to make purchases on the Services.</li>
                        <li>Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.</li>
                        <li>Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavor or commercial enterprise.</li>
                        <li>Use the Services to advertise or offer to sell goods and services.</li>
                        <li>Sell or otherwise transfer your profile.</li>
                    </ul>

                    <h2>9. USER GENERATED CONTRIBUTIONS</h2>
                    <p>The Services does not offer users to submit or post content.</p>

                    <h2>10. CONTRIBUTION LICENSE</h2>
                    <p>You and Services agree that we may access, store, process, and use any information and personal data that you provide following the terms of the Privacy Policy and your choices (including settings).</p>
                    <p>By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such feedback for any purpose without compensation to you.</p>

                    <h2>11. SERVICES MANAGEMENT</h2>
                    <p>We reserve the right, but not the obligation, to:</p>
                    <ul>
                        <li>Monitor the Services for violations of these Legal Terms;</li>
                        <li>Take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities;</li>
                        <li>In our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof;</li>
                        <li>In our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and</li>
                        <li>Otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.</li>
                    </ul>

                    <h2>12. PRIVACY POLICY</h2>
                    <p>We care about data privacy and security. Please review our Privacy Policy: __________. By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted in the United States. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your continued use of the Services, you are transferring your data to the United States, and you expressly consent to have your data transferred to and processed in the United States.</p>
                </div>
            </div>
        </>
    )
}

export default Terms