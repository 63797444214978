import axios from 'axios'
import './collection.css'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { convertToCommaSeparate } from '../../utils/utils'
import { useAuth } from '../../Hooks/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { setCart } from '../../redux/action'
import toast from 'react-hot-toast'
import he from 'he'

function Collection() {
    const [products, setProducts] = useState([])
    const [collectionTitle, setCollectionTitle] = useState('Loading...')

    let { id } = useParams()
    const auth = useAuth()
    const dispatcher = useDispatch()
    const cart = useSelector((state) => state.cartReducer?.items)
    const isInCart = useCallback((product) => {
        return cart.some(item => item?.id === product?.id);
    }, [cart])

    const getCollectionProducts = useCallback(async (id) => {
        const queryParams = new URLSearchParams({
            collectionid: id,
        });
        await axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `collection?${queryParams}`).then(res => {
            if (res?.status == 200 && res?.data && res?.data?.variations && Array.isArray(res?.data?.variations) && res?.data?.variations?.length > 0) {
                setProducts(res?.data?.variations)
                setCollectionTitle(res?.data?.title)
            }
        }).catch(err => {

        })
    }, [])
    useEffect(() => {
        id = parseInt(id)
        if (id > 0) {
            getCollectionProducts(id)
        }
    }, [])

    const cartReducer = useSelector((state) => state.cartReducer)

    const handleAddToCart = useCallback(async (product, event, type = 'simple') => {
        event.target.classList.add('processing');
        let headers = {}
        const userInner = await auth.getCurrentUser()
        if (userInner) {
            headers = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                }
            }
        }
        if (userInner || 1) {
            let cartKeyParam = ''
            if (!userInner && cartReducer && cartReducer?.cart_key) {
                cartKeyParam = `?cart_key=${cartReducer?.cart_key}`
            }

            await axios.post(process.env.REACT_APP_CC_API_BASE + `cart/add-item${cartKeyParam}`, {
                "id": '' + product?.id,
                "quantity": "1"
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                }
            }).then(res => {
                if (res?.status == 200 && undefined != res?.data?.cart_key) {
                    dispatcher(setCart(res?.data))
                }
            }).catch(err => {
                if (err?.response?.data) {
                    toast.error(he.decode(err?.response?.data?.message), {
                        position: "bottom-left",
                        style: {
                            borderColor: 'red',
                            borderWidth: '1px',
                            borderStyle: 'solid'
                        }
                    })
                }
            }).finally(() => {
                event.target.classList.remove('processing');
            })
        }
    }, [auth, cartReducer])

    const renderProducts = useMemo(() => {
        if (products.length === 0) return null;

        return products.map(product => (
            <div key={product.id} className="single_collection_item">
                <div className="single_collection_item_header">
                    <h3 className="collection_title">
                        <Link to={`/${product?.parent_slug}/?variation=${product?.id}`}>{product?.grade_name}</Link>
                    </h3>
                </div>
                <div className="single_collection_item_images">
                    {Array.isArray(product?.images) && product?.images.length > 0 && (
                        <div className="single_collection_images">
                            <OwlCarousel
                                items={1}
                                className="owl-theme single-product-thumbnails-slider"
                                loop
                                margin={8}
                                dots
                                lazyLoad
                                nav
                            >
                                {product.images.map((image, index) => (
                                    <div key={index} className="pro_slider_item">
                                        <img src={image.replace('staging.', '')} alt={product?.grade_name} loading='lazy' />
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    )}
                </div>
                <div className="single_collection_item_footer">
                    {product?.team && (
                        <div className="single_collection_item_team flex align-center gap-5">
                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.8203 10.4424L11.6133..." fill="#090F25" />
                            </svg>
                            <Link to={`/team/${product?.team?.slug}`}>{product?.team?.name}</Link>
                        </div>
                    )}
                    <div className="collection_price_wrapper flex flex-end gap-50">
                        <div className="collection_price">
                            <Link to={`/${product?.parent_slug}/?variation=${product?.id}`} className="priceLink">
                                ${convertToCommaSeparate(product?.price)}
                            </Link>
                        </div>
                        <div className="collection_actions">
                            {isInCart(product) ? (
                                <Link className="view_cart" to="/cart">VIEW CART →</Link>
                            ) : (
                                <button
                                    className="newlist_custom_addcart"
                                    onClick={(event) => handleAddToCart(product, event)}
                                >
                                    Add To Cart
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        ));
    }, [products, handleAddToCart, isInCart]);

    return (
        <>
            <div className='single_page_header_wrapper'>
                <div className='container wide'>
                    <h3 className='single_page_header_title'>
                        {collectionTitle}
                    </h3>
                </div>
            </div>
            <div className='container'>
                <div className='collections_wrapper wrap flex gap-20'>
                    {renderProducts}
                </div>
            </div>
        </>
    )
}

export default Collection