import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkStoredUser } from "../../redux/slices/adminSlice";
import { Navigate, Route } from "react-router-dom";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { getBestSellingReport_API, getPaidNonPaidCustomersCount_API, getSalesReport_API } from "../../api/adminapi";
import ReactApexChart from "react-apexcharts";
import { getCache, setCache } from "../../cacheUtil";

const formatMonth = (monthString) => {
    const [year, month] = monthString.split('-');
    const date = new Date(year, month - 1);
    return date.toLocaleString('en-US', { month: 'short', year: 'numeric' });
};

const formatNumber = (number) => {
    const formattedNumber = number.toLocaleString();
    if (number >= 1000) {
        const kNumber = (number / 1000).toFixed(2);
        return `$${kNumber}k`;
    }
    return `$${formattedNumber}`;
};

const getPeriodDates = () => {
    const currentYear = new Date().getFullYear();
    const start = new Date(currentYear, 0, 1);
    const end = new Date(currentYear, 11, 31);
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    return {
        min_date: formatDate(start),
        max_date: formatDate(end),
    };
};

const AdminDashboard = () => {

    const dispatch = useDispatch();
    const { isAuthenticated, isLoading } = useSelector((state) => state.admin);
    const [loading, setLoading] = useState(true);
    const [salesData, setSalesData] = useState({});
    const [bestSellingData, setBestSellingData] = useState([]);

    const [customerChartData, setCustomerChartData] = useState({
        series: [
            { name: ['Paying customer','Non-paying customer'], data: [0, 0] }
        ],
        options: {
            chart: {
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: [],
            },
        },
    });

    const [chartData, setChartData] = useState({
        series: [
            { name: 'Total Sales', data: [0] },
            { name: 'Total Customers', data: [0] }
        ],
        options: {
            chart: {
                height: 650,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                // type: 'datetime',
                categories: []
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy'
                },
            }
        }
    });

    useEffect(() => {
        dispatch(checkStoredUser());
    }, [dispatch]);
    const fetchReports = async () => {
        setLoading(true);

        const cachedData = getCache('sales_report');
        const cachedDataBestSelling = getCache('best_selling_items');
        if (cachedDataBestSelling) {
            setBestSellingData(cachedDataBestSelling);
        }
        if (cachedData) {
            const _data = cachedData;
            setSalesData(_data);
            const totals = _data.totals;

            const monthKeys = Object.keys(totals);
            const formattedMonths = monthKeys.map(month => formatMonth(month));

            const totalSales = monthKeys.map(month => parseFloat(totals[month].sales));
            const totalCustomers = monthKeys.map(month => parseFloat(totals[month].customers));

            setChartData({
                series: [
                    { name: 'Total Sales', data: totalSales },
                    { name: 'Total Customers', data: totalCustomers }
                ],
                options: {
                    ...chartData.options,
                    xaxis: {
                        ...chartData.options.xaxis,
                        categories: formattedMonths
                    },
                    tooltip: {
                        y: {
                            formatter: function (val, { seriesIndex }) {
                                if (seriesIndex === 0) {
                                    return formatNumber(val);
                                }
                                return val;
                            }
                        }
                    },
                    yaxis: {
                        labels: {
                            formatter: function (val) {
                                return formatNumber(val);
                            }
                        }
                    }
                }
            });

            setLoading(false);
            // return;
        }

        try {
            const date_range = getPeriodDates();
            const response = await getSalesReport_API('year', date_range.min_date, date_range.max_date);
            if (response.status === 200) {
                let _data = response.data;
                if (Array.isArray(_data) && _data.length) {
                    _data = _data[0];
                    setSalesData(_data);
                    setCache('sales_report', _data);
                    const totals = _data.totals;

                    const monthKeys = Object.keys(totals);
                    const formattedMonths = monthKeys.map(month => formatMonth(month));

                    const months = Object.keys(totals);
                    const totalSales = months.map(month => parseFloat(totals[month].sales));
                    const totalCustomers = months.map(month => parseFloat(totals[month].customers));
                    setChartData({
                        series: [
                            { name: 'Total Sales', data: totalSales },
                            { name: 'Total Customers', data: totalCustomers }
                        ],
                        options: {
                            ...chartData.options,
                            xaxis: {
                                ...chartData.options.xaxis,
                                categories: formattedMonths
                            },
                            tooltip: {
                                y: {
                                    formatter: function (val, { seriesIndex }) {
                                        if (seriesIndex === 0) {
                                            return formatNumber(val);
                                        }
                                        return val;
                                    }
                                }
                            },
                            yaxis: {
                                labels: {
                                    formatter: function (val) {
                                        return formatNumber(val);
                                    }
                                }
                            },
                        }
                    });
                }
            }

            const bestSellingResponse = await getBestSellingReport_API('year', date_range.min_date, date_range.max_date)
            if (bestSellingResponse.status == 200) {
                let _data = bestSellingResponse.data;
                _data = _data.slice(0, 5);
                setBestSellingData(_data)
                setCache('best_selling_items', _data);
            }

            const customerResponse = await getPaidNonPaidCustomersCount_API()
            if (customerResponse.status == 200) {
                let _data = customerResponse.data;
                const categories = _data.map(item => item.name);
                const seriesData = _data.map(item => item.total);
                setCustomerChartData({
                    series: [
                        {
                            name: 'Customers',
                            data: seriesData,
                        }
                    ],
                    options: {
                        ...chartData.options,
                        xaxis: {
                            categories: categories,
                        },
                        yaxis: {
                            labels: {
                                formatter: function (val) {
                                    return (val);
                                }
                            }
                        }
                    },
                });
                setCache('customers_compare', _data);
            }

        } catch (error) {
            console.error("Error fetching reports:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchReports();
        }
    }, [isAuthenticated]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!isAuthenticated) {
        return <Navigate to="/admin/login" />;
    }

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">

            <AdminSidebar />

            <div class="layout-page">

                <AdminTopbar />

                <div class="content-wrapper">

                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="row g-6">
                            <div class="col-xl-12 col-md-12">
                                <div class="admin-card h-100">
                                    <div class="admin-card-header d-flex justify-content-between">
                                        <h5 class="admin-card-title mb-0">Sales Statistics</h5>
                                        <small class="text-muted">This Year</small>
                                    </div>
                                    <div class="admin-card-body d-flex align-items-end">
                                        <div class="w-100">
                                            <div class="row gy-3">
                                                <div class="col-md-3 col-6">
                                                    <div class="d-flex align-items-center">
                                                        <div class="badge rounded bg-label-info me-4 p-2"><i class="ti ti-users ti-lg"></i></div>
                                                        <div class="admin-card-info">
                                                            <h5 class="mb-0">
                                                                {
                                                                    salesData?.total_sales ? formatNumber(salesData.total_sales) : 0
                                                                }
                                                            </h5>
                                                            <small>Total Sales</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-6">
                                                    <div class="d-flex align-items-center">
                                                        <div class="badge rounded bg-label-info me-4 p-2"><i class="ti ti-users ti-lg"></i></div>
                                                        <div class="admin-card-info">
                                                            <h5 class="mb-0">
                                                                {
                                                                    salesData?.net_sales ? formatNumber(salesData.net_sales) : 0
                                                                }
                                                            </h5>
                                                            <small>Net Sales</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-6">
                                                    <div class="d-flex align-items-center">
                                                        <div class="badge rounded bg-label-info me-4 p-2"><i class="ti ti-users ti-lg"></i></div>
                                                        <div class="admin-card-info">
                                                            <h5 class="mb-0">
                                                                {
                                                                    salesData?.average_sales ? formatNumber(salesData.average_sales) : 0
                                                                }
                                                            </h5>
                                                            <small>Average Sales</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-6">
                                                    <div class="d-flex align-items-center">
                                                        <div class="badge rounded bg-label-info me-4 p-2"><i class="ti ti-users ti-lg"></i></div>
                                                        <div class="admin-card-info">
                                                            <h5 class="mb-0">
                                                                {
                                                                    salesData?.total_orders ? formatNumber(salesData.total_orders) : 0
                                                                }
                                                            </h5>
                                                            <small>Total Orders</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-6">
                                                    <div class="d-flex align-items-center">
                                                        <div class="badge rounded bg-label-info me-4 p-2"><i class="ti ti-users ti-lg"></i></div>
                                                        <div class="admin-card-info">
                                                            <h5 class="mb-0">
                                                                {
                                                                    salesData?.total_refunds ? formatNumber(salesData.total_refunds) : 0
                                                                }
                                                            </h5>
                                                            <small>Total Refunds</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-6">
                                                    <div class="d-flex align-items-center">
                                                        <div class="badge rounded bg-label-info me-4 p-2"><i class="ti ti-users ti-lg"></i></div>
                                                        <div class="admin-card-info">
                                                            <h5 class="mb-0">
                                                                {
                                                                    salesData?.total_discount ? formatNumber(salesData.total_discount) : 0
                                                                }
                                                            </h5>
                                                            <small>Total Discounts</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-6">
                                                    <div class="d-flex align-items-center">
                                                        <div class="badge rounded bg-label-info me-4 p-2"><i class="ti ti-users ti-lg"></i></div>
                                                        <div class="admin-card-info">
                                                            <h5 class="mb-0">
                                                                {
                                                                    salesData?.total_customers ? formatNumber(salesData.total_customers) : 0
                                                                }
                                                            </h5>
                                                            <small>Total Customers</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3 col-6">
                                                    <div class="d-flex align-items-center">
                                                        <div class="badge rounded bg-label-danger me-4 p-2">
                                                            <i class="ti ti-shopping-cart ti-lg"></i>
                                                        </div>
                                                        <div class="admin-card-info">
                                                            <h5 class="mb-0">
                                                                {
                                                                    salesData?.total_items ? formatNumber(salesData.total_items) : 0
                                                                }
                                                            </h5>
                                                            <small>Total Items Sold</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* sales stat */}
                            </div>
                        </div>
                        <div class="row g-6 mt-0">
                            <div class="col-xl-8">
                                <div class="admin-card h-100">
                                    <div class="admin-card-header d-flex justify-content-between">
                                        <div class="admin-card-title m-0 me-2">
                                            <h5 class="mb-1">Top 5 Best Sellling Items</h5>
                                            <p class="admin-card-subtitle">This Year (On website)</p>
                                        </div>
                                    </div>
                                    <div class="admin-card-body">
                                        {
                                            bestSellingData?.length ?
                                                <ul class="p-0 m-0">
                                                    {
                                                        bestSellingData.map(product => {
                                                            return <li class="d-flex mb-6">
                                                                {/* <div class="me-4">
                                                                    <img src="../../assets/img/products/iphone.png" alt="User" class="rounded" width="46" />
                                                                </div> */}
                                                                <div class="d-flex w-100 flex-wrap align-items-center justify-content-between gap-2">
                                                                    <div class="me-2">
                                                                        <h6 class="mb-0">
                                                                            {product.name}
                                                                        </h6>
                                                                    </div>
                                                                    <div class="user-progress d-flex align-items-center gap-1">
                                                                        <p class="mb-0">{product.quantity} quantity Sold</p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        })
                                                    }
                                                </ul> : null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div class="col-xxl-4 col-4">
                                <div class="row g-6">
                                    <div class="col-xxl-12 col-md-12">
                                        <div class="admin-card h-100">
                                            <div class="admin-card-header d-flex justify-content-between">
                                                <div class="admin-card-title m-0 me-2">
                                                    <h5 class="mb-1">Paying vs Non-Paying Customers</h5>
                                                    <p class="admin-card-subtitle">All Time</p>
                                                </div>
                                            </div>
                                            <div class="admin-card-body">
                                                <ReactApexChart
                                                    options={customerChartData.options}
                                                    series={customerChartData.series}
                                                    type="bar"
                                                    height={300}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="col-xxl-12">
                                <div class="admin-card h-100">
                                    <div class="admin-card-body p-0">
                                        <div class="row row-bordered g-0">
                                            <div class="col-md-12 position-relative">
                                                <div class="admin-card-header d-flex justify-content-between">
                                                    <h5 class="admin-card-title mb-0">Revenue Report</h5>
                                                    <small class="text-muted">This Year</small>
                                                </div>
                                                <ReactApexChart
                                                    options={chartData.options}
                                                    series={chartData.series}
                                                    type="line"
                                                    height={300}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default AdminDashboard