import React, { useEffect, useState } from "react";
import he from 'he';
import { updateTerm } from "../../api/adminapi";
import toast from "react-hot-toast";

const stripHtml = (html) => {
    return html?.replace(/<\/?[^>]+(>|$)/g, "");
}

const EditTagCanvas = ({ taxonomy, term, isOpen, onClose, refresh }) => {

    // console.warn(taxonomy)

    const [processing, setProcessing] = useState(false)
    let term_id = term?.term_id
    const isEditing = term_id > 0 ? true : false

    const [formData, setFormData] = useState({
        term_id: term_id ? term_id : 0,
        slug: term?.slug ? term?.slug : '',
        taxonomy: taxonomy,
        description: term?.description ? term?.description : '',
        name: term?.name ? term?.name : '',
    })

    const handleFormSubmit = async (e) => {
        e.preventDefault()
        if (processing)
            return;
        if (!formData?.name?.trim().length) {
            toast.error('Title is required', {
                position: "top-center",
                style: {
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            })
            return;
        }
        if( ! formData.taxonomy )
            formData.taxonomy = taxonomy;
        setProcessing(true)
        await updateTerm(formData).then(response => {
            if (response.status == 200 || response.status == 201) {
                toast.success(response.data.message, {
                    position: "top-center",
                    style: {
                        borderColor: 'green',
                        borderWidth: '1px',
                        borderStyle: 'solid'
                    }
                });
                onClose()
                resetForm();
                refresh && refresh();
                return;
            }
            toast.error(response.data.message, {
                position: "top-center",
                style: {
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            })
        }).catch(err => {
            toast.error(err, {
                position: "top-center",
                style: {
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            })
        }).finally(() => {
            setProcessing(false)
        })

    }

    useEffect(() => {
        if (term_id) {
            setFormData({
                name: term?.name,
                description: term?.description,
                taxonomy: term?.taxonomy,
                term_id: term?.term_id,
                slug: term?.slug,
            })
            return;
        }
        setProcessing(false)
    }, [term_id])

    const resetForm = () => {
        setFormData({
            name: '',
            description: '',
            taxonomy: '',
            term_id: 0,
            slug: '',
        })
    }

    return (
        <div class={`offcanvas offcanvas-end ${isOpen ? 'show' : ''}`} tabindex="-1" id="offcanvasEcommerceCategoryList" aria-labelledby="offcanvasEcommerceCategoryListLabel">
            <div class="offcanvas-header py-6">
                <h5 id="offcanvasEcommerceCategoryListLabel" class="offcanvas-title">{isEditing ? 'Save' : 'Add'} {taxonomy}</h5>
                <button onClick={() => {
                    if (processing)
                        return;
                    onClose()
                    resetForm();
                }} type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body border-top">
                <form class={`pt-0 ${processing ? 'processing' : ''}`} id="eCommerceCategoryListForm" onsubmit="return true" onSubmit={(e) => { handleFormSubmit(e) }}>
                    <div class="mb-6">
                        <label class="form-label" for="ecommerce-category-title">Title</label>
                        <input type="text" class="form-control" id="ecommerce-category-title" placeholder="Enter title" name="categoryTitle"
                            // value={isEditing && term ? he.decode(term.name) : formData.name} 
                            value={he.decode(formData.name ? formData.name : '')}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    name: e.target.value,
                                })
                            }}
                        />
                    </div>

                    <div class="mb-6">
                        <label class="form-label" for="ecommerce-category-slug">Slug</label>
                        <input type="text" id="ecommerce-category-slug" class="form-control" placeholder="Enter slug" name="slug"
                            // value={isEditing && term ? term.slug : ''} 
                            value={formData.slug}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    slug: e.target.value,
                                })
                            }}
                        />
                    </div>
                    {/* 
                    <div class="mb-6">
                        <label class="form-label" for="ecommerce-category-image">Attachment</label>
                        <input class="form-control" type="file" id="ecommerce-category-image" />
                    </div> */}

                    {/* <div class="mb-6 ecommerce-select2-dropdown">
                        <label class="form-label" for="ecommerce-category-parent-category">Parent category</label>
                        <select id="ecommerce-category-parent-category" class="select2 form-select" data-placeholder="Select parent category" value={isEditing && term ? term.parent : ''}>
                            <option value="">Select parent {taxonomy}</option>
                        </select>
                    </div> */}

                    <div class="mb-6">
                        <label class="form-label">Description</label>
                        <textarea
                            className="form-control"
                            // value={isEditing && term ? stripHtml(he.decode(term.description)) : ''}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    description: e.target.value,
                                })
                            }}
                            value={he.decode(stripHtml(formData?.description ? formData?.description : ''))}
                        // onChange={(e) => setTerm({ ...term, description: e.target.value })}
                        />
                    </div>
                    <div class="mb-6">
                        <button type="submit" class="btn btn-primary me-sm-3 me-1 data-submit">{isEditing ? 'Save' : 'Add'}</button>
                        <button onClick={onClose} type="reset" class="btn btn-label-danger" data-bs-dismiss="offcanvas">Discard</button>
                    </div>
                    <input type="hidden" name="term_id" value={isEditing ? term_id : 0} />
                    <input type="hidden" name="is_editing" value={isEditing ? 'yes' : ''} />
                </form>
            </div>
        </div>
    )
}
export default EditTagCanvas