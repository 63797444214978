import { Link } from 'react-router-dom';
import '../Value/value.css'
import React, { useEffect } from "react";
import corner from '../../../src/assets/images/Corners.png'
import edges from '../../../src/assets/images/Edges.png'
import surface from '../../../src/assets/images/Surface.png'
import centering from '../../../src/assets/images/Centering.png'
import print from '../../../src/assets/images/Printissues.png'
import group36 from '../../../src/assets/images/Group-36.png'
import group37 from '../../../src/assets/images/Group-37.png'
import group38 from '../../../src/assets/images/Group-38.png'
import { scrollToTop } from '../../utils/utils';
import DocumentTitle from '../../utils/DocumentTitle';

const Value = ({ title }) => {
    if (title) {
        DocumentTitle(title)
    }

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <div className="single_post_header flex">
                <div className="container">
                    <h2 className="entry-title">
                        DETERMINING THE VALUE OF OLD BASEBALL CARDS
                    </h2>
                </div>
            </div>
            <div className="container pt-50">
                <div className="card_section wrap-small">
                    <div className="card full-width-small">
                        <div className="card_upper">
                            <h2 className="card_heading">How Do I Go About Determining The Value Of Old Baseball Cards?</h2>
                            <p className="card_text">Whether it’s something you inherited or pulled from the closet after years,
                                it’s always exciting to discover the value of old baseball cards.</p>
                        </div>
                        <div className="card_lower">
                            <p className="card_text2 pb-30 mt-10" >While there's no definitive method for coming
                                up with a price for old or rare sports cards, TonyeTrade is happy to present the following
                                information to help you make the most informed decisions possible.</p>
                            <div className='text-center'>
                                <Link className="card-button" to={`/the-most-wanted-list/`}>SEE WHAT WE'RE LOOKING FOR</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card full-width-small">
                        <div className="card_upper">
                            <h2 className="card_heading">FINDING A SOURCE WITH INTEGRITY</h2>
                            <p className="card_text pb-27">When it comes to the value of old baseball
                                cards, there are unfortunately dishonest or unscrupulous individuals who look to take
                                advantage of those with less knowledge.</p>
                        </div>
                        <div className="card_lower">
                            <p className="card_text2 mt-10">TonyeTrade is proud to have established a reputation for fair and honest
                                dealings, and for paying top dollar for both ungraded and graded cards. If you are looking
                                to buy or sell valuable sports cards, gain peace of mind by contacting TonyeTrade.</p>
                            <div className='text-center'>
                                <Link className="card-button" to={`/contact`}>QUESTIONS? CONTACT US</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-0 pt-0">
                <div className="image-background">
                    <div className="content-area full-width-small">
                        <div className="content_upper">
                            <h2 className="card_heading text-white text-center">BOOK VALUE VS. MARKET VALUE</h2>
                            <p className="card_text_inner mt-0 text-white">Looking to a price guide like Beckett or Tuff Stuff
                                can give you a basic idea of what cards are worth, but it can also be a little misleading.
                            </p>
                            <p className="card_text_inner mt-0 text-white">It’s important to consider the market for the items
                                in question. A better method to determine the price your cards might fetch on the open
                                market is looking to online marketplaces like eBay. With eBay, you can check the value of
                                recent sales fairly easily:</p>
                        </div>
                        <div className="card_lower1 pt-30 pb-30 full-width-small">
                            <div className="image-text-container">
                                <div className="image-text-pair">
                                    <div className="text-container-inner">
                                        <h4 className='mt-0 mb-10'>GO TO EBAY.COM</h4>
                                    </div>
                                </div>
                                <div className="image-text-pair">
                                    <div className="text-container-inner">
                                        <h4 className='mt-0 mb-10'>SEARCH FOR YOUR ITEM</h4>
                                        <p className="paragraph-text-inner mt-0"> Go to the left-hand sidebar, and from the Show only
                                            section, select Sold listings.This will filter results to show the final price
                                            of completed sales on eBay.com, and should give you a better idea of the sum
                                            your item could realistically fetch.</p>
                                    </div>

                                </div>
                                <div className="image-text-pair">
                                    <div className="text-container-inner">
                                        <h4 className='mt-0 mb-10'>MAKE SURE YOUR ITEMIS SEARCHABLE.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="container-inner wrap-small">
                    <div className="content_inner full-width-small">
                        <h2 className="text-container ">DETERMINING THE CONDITION OF SPORTS CARDS</h2>
                        <p className="paragraph-text text-grey-v2">Beyond scarcity, condition is one of
                            the greatest determining factors of the value of old baseball cards. Looking for wear and damage
                            is something you can do on your own, and will give you a good idea if there are any issues that
                            might affect the price of the items you’re hoping to sell. To find any wear and damage, look
                            closely at four areas of your sports cards:</p>
                    </div>
                    <div className="empty-div"></div>
                </div>
            </div>
            <div className="container pt-0 mt-0">
                <div className="icon-box-container">
                    <div className="icon-row wrap-small">
                        <div className="icon-box full-width-small">
                            <div className="text">
                                <img loading='lazy' src={corner} className="icon-image corner" />
                                <h2 className="icon-text mt-0"> CORNERS</h2>
                            </div>
                            <p className="icon-dec">These should be sharp and well defined. As you evaluate the corners of your
                                cards, look carefully for fraying, rounding off, and tears.</p>
                        </div>
                        <div className="icon-box bg-dark full-width-small">
                            <div className="text">
                                <img loading='lazy' src={edges} className="icon-image edges" alt=" 3" />
                                <h2 className="icon-text text-white mt-0">EDGES</h2>
                            </div>
                            <p className="icon-dec text-white">Examine the edge of your card straight on. Damage could
                                include imperfections in the finish, dents, or wear due to improper handling.</p>
                        </div>
                        <div className="icon-box full-width-small">
                            <div className="text">
                                <img loading='lazy' src={surface} className="icon-image surface" alt=" 3" />
                                <h2 className="icon-text mt-0">SURFACE</h2>
                            </div>
                            <p className="icon-dec">DCheck the surface of your card for any bends and creases, as well as any
                                areas where the finish is imperfect. Also check for defects from when the card was
                                originally printed.</p>
                        </div>
                    </div>

                    <div className="icon-row wrap-small">
                        <div className="icon-box bg-dark full-width-small">
                            <div className="text">
                                <img loading='lazy' src={centering} className="icon-image centering" alt=" 3" />
                                <h2 className="icon-text text-white mt-0">CENTERING</h2>
                            </div>
                            <p className="icon-dec text-white">The older the card, the less precise the equipment used
                                to print it. This can sometimes lead to cards being printed off-center on the stock. Check
                                the borders to ensure the width is the same on all four sides.</p>
                        </div>
                        <div className="icon-box full-width-small">
                            <div className="text">
                                <img loading='lazy' src={print} className="icon-image printissues" alt=" 3" />
                                <h2 className="icon-text mt-0">PRINT ISSUES</h2>
                            </div>
                            <p className="icon-dec">Production errors such as marks or imperfections.</p>
                        </div>
                        <div className="icon-box bg-transparent">
                        </div>
                    </div>

                </div>
            </div>
            <div className="container">
                <div className="last-div wrap-small">
                    <div className="left-content full-width-small mr-0-small">
                        <h2 className="text-container "> SERVICES FOR GRADED CARDS</h2>
                        <p className="paragraph-text">While the above methods for checking the value of old baseball cards are
                            important for your own knowledge, they might not help much on the open market. For that, most
                            savvy collectors look to grading services. These services, for a fee, will evaluate your cards
                            and assign them a grade — which will impact the price. The most reputable grading services are:
                        </p>
                    </div>
                    <div className="right-content full-width-small">
                        <img loading='lazy' src={group36} alt="Image 1" />
                        <img loading='lazy' src={group38} alt="Image 2" />
                        <img loading='lazy' src={group37} alt="Image 3" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Value