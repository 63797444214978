import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { Link } from "react-router-dom";
import { convertToCommaSeparate, getBulkActions } from "../../utils/utils";
import Pagination from "../../components/Pagination";
import { fetchAmazonListings, getAmazonListingStatusCount } from "../../api/adminapi";
import { getCache, setCache } from "../../cacheUtil";
import PostStatuses from "../../components/PostStatuses";
import AdminTableMeta from "../../components/AdminTableMeta";

const CACHE_KEY_AMAZON_LISTINGS = 'amazon_listings_data';
const CACHE_KEY_AMAZON_LISTINGS_COUNTERS = 'amazon_listings_counters';

const AmazonListings = () => {

    const [listings, setListings] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [total, setTotal] = useState(0)
    const [selectedListings, setSelectedListings] = useState([]);
    const [activeFilters, setActiveFilters] = useState({
        status: 'all',
    })
    const fetchListings = async (page = null) => {
        if (loading)
            return
        try {
            setLoading(true);
            await fetchAmazonListings(activeFilters, page ? page : currentPage).then(response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setTotalPages(_data.total_items)
                    setTotal(_data.total_items)
                    setCurrentPage(_data.current_page)
                    setListings(_data.listings)
                    setCache(CACHE_KEY_AMAZON_LISTINGS, _data);
                }
            }).catch(() => { }).finally(() => {
                setLoading(false)
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        const loadCachedListings = () => {
            const cachedData = getCache(CACHE_KEY_AMAZON_LISTINGS);
            if (cachedData) {
                setTotalPages(cachedData.total_items);
                setTotal(cachedData.total_items);
                setListings(cachedData.listings);
            }
            fetchListings();
        };
        loadCachedListings();
    }, [activeFilters])


    useEffect(() => {
        const cachedCounters = getCache(CACHE_KEY_AMAZON_LISTINGS_COUNTERS);
        if (cachedCounters) {
            setCounters(cachedCounters);
        }
        getListingsCounts();
    }, [])

    let startPage = Math.max(1, currentPage - Math.floor(totalPages / 2));
    let endPage = startPage + totalPages - 1;

    if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - totalPages + 1);
    }

    const getListingsCounts = async () => {
        try {
            getAmazonListingStatusCount().then(response => {
                if (response.status == 200) {
                    const _counts = response.data;
                    if (_counts) {
                        setCounters(prevCounters => {
                            const updatedCounters = { ...prevCounters };
                            Object.entries(_counts)?.forEach(([key, value]) => {
                                switch (key) {
                                    case 'total_items':
                                        key = 'all';
                                        break;
                                }
                                if (updatedCounters[key]) {
                                    updatedCounters[key] = {
                                        ...updatedCounters[key],
                                        c: value,
                                    };
                                }
                            });
                            setCache(CACHE_KEY_AMAZON_LISTINGS_COUNTERS, updatedCounters);
                            return updatedCounters;
                        });
                    }
                }
            })
        } catch (error) {

        }
    }

    const [counters, setCounters] = useState({
        all: { c: 0, t: 'All' },
        prepared: { c: 0, t: 'Prepared' },
        online: { c: 0, t: 'Online' },
        changed: { c: 0, t: 'Changed' },
        matched: { c: 0, t: 'Matched' },
        failed: { c: 0, t: 'Failed' },
        importqueue: { c: 0, t: 'Import Queue' },
        instock: { c: 0, t: 'In Stock' },
        nostock: { c: 0, t: 'No Stock' },
    });

    const handleStatusFilter = (status) => {
        setActiveFilters({
            ...activeFilters,
            status: status,
        })
    }

    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allListingIds = listings.map((listing) => listing.id);
            setSelectedListings(allListingIds);
        } else {
            setSelectedListings([]);
        }
    };

    const handleListingCheckbox = (e, listingId) => {
        if (e.target.checked) {
            setSelectedListings((prev) => [...prev, listingId]);
        } else {
            setSelectedListings((prev) => prev.filter((id) => id !== listingId));
        }
    };

    const [searchQuery, setSearchQuery] = useState('')


    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="admin-card">
                            <AdminTableMeta showPagination={true}
                                pagination={{
                                    total: total,
                                    per_page: 20,
                                    currentpage: currentPage,
                                    refresh: fetchListings,
                                    setCurrentPage: setCurrentPage,
                                }}
                                loading={loading}
                                showSearch={true}
                                search={{
                                    searchQuery: searchQuery,
                                    setSearchQuery: setSearchQuery,
                                    placeholder: 'Search listings...'
                                }}
                                showBulkActions={true}
                                bulkActions={{
                                    actions: getBulkActions('amazon')
                                }}
                            />
                            <div class="card-datatable table-responsive">
                                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <PostStatuses counters={counters} handleStatusFilter={handleStatusFilter} activeFilters={activeFilters} />
                                    <table class="datatables-products table dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                        <thead class="border-top">
                                            <tr>
                                                <th class="control sorting_disabled dtr-hidden" rowspan="1" colspan="1" style={{
                                                    width: 22.3594 + 'px',
                                                    display: 'none',
                                                }} aria-label=""></th>
                                                <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" rowspan="1" colspan="1" style={{
                                                    width: 18.6328 + 'px',
                                                }} data-col="1" aria-label="">
                                                    <input type="checkbox" class="form-check-input"
                                                        onChange={handleAllSelector}
                                                        checked={listings && selectedListings.length === listings.length}
                                                    />
                                                </th>
                                                <th class="sorting sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 113.82 + 'px',
                                                }} aria-sort="ascending" aria-label="product: activate to sort column descending">Title</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61.4375 + 'px',
                                                }} aria-label="sku: activate to sort column ascending">sku</th>
                                                <th class="sorting_disabled" rowspan="1" colspan="1" style={{
                                                    width: 84.5703 + 'px',
                                                }} aria-label="stock">stock</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 78.8125 + 'px',
                                                }} aria-label="price: activate to sort column ascending">price</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61 + 'px',
                                                }} aria-label="qty: activate to sort column ascending">created at</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61 + 'px',
                                                }} aria-label="qty: activate to sort column ascending">status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                'undefined' == typeof listings ?
                                                    (
                                                        <>
                                                            <tr class="odd">
                                                                <td valign="top" colspan="9" class="dataTables_empty">Loading...</td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        !listings.length ?
                                                            <p>No</p> :
                                                            listings.map(listing => {
                                                                return <>
                                                                    <tr class="odd">
                                                                        <td class="dt-checkboxes-cell">
                                                                            <input type="checkbox" class="dt-checkboxes form-check-input"
                                                                                checked={selectedListings.includes(listing.id)}
                                                                                onChange={(e) => handleListingCheckbox(e, listing.id)}
                                                                            />
                                                                        </td>
                                                                        <td class="sorting_1">
                                                                            <div class="d-flex justify-content-start align-items-center product-name">
                                                                                <Link to={`/admin/products/add?product_id=${listing.parent_id}&action=edit`}>
                                                                                    <div class="avatar-wrapper">
                                                                                        {
                                                                                            listing.GalleryURL ?
                                                                                                <div class="avatar avatar me-4 rounded-2 bg-label-secondary">
                                                                                                    <img src={listing.GalleryURL} alt="Product-9" class="rounded-2" />
                                                                                                </div> : null
                                                                                        }
                                                                                    </div>
                                                                                    <div class="d-flex flex-column">
                                                                                        <h6 class="mb-0" dangerouslySetInnerHTML={{ __html: listing.listing_title }} />
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span dangerouslySetInnerHTML={{ __html: listing.sku }} />
                                                                        </td>
                                                                        <td>
                                                                            {`${listing.quantity}`}
                                                                        </td>
                                                                        <td><span>${convertToCommaSeparate(listing.price)}</span></td>
                                                                        <td>
                                                                            {listing.date_created}
                                                                        </td>
                                                                        <td class="dtr-hidden">
                                                                            <div class="d-flex flex-column">
                                                                                <span class={`badge ${listing.status == 'failed' ? 'bg-label-danger' : ''} ${listing.status == 'online' ? 'bg-label-success' : ''} ${listing.status == 'changed' ? 'bg-label-warning' : ''}`} text-capitalized="">
                                                                                    {listing.status}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        listing?.message_row ?
                                                                            <tr>
                                                                                <td>&nbsp;</td>
                                                                                <td colspan="5">
                                                                                    <span dangerouslySetInnerHTML={{ __html: listing.message_row }} />
                                                                                </td>
                                                                                <td>&nbsp;</td>
                                                                            </tr> : null
                                                                    }
                                                                </>
                                                            })
                                                    )
                                            }
                                        </tbody>
                                    </table>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                                                Showing {listings ? listings.length : 0} of {totalPages} products
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6">
                                            <Pagination
                                                totalItems={totalPages}
                                                itemsPerPage={20}
                                                currentPage={currentPage}
                                                onPageChange={(number) => {
                                                    setCurrentPage(number)
                                                }}
                                                refresh={fetchListings}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default AmazonListings