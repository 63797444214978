import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { Link } from "react-router-dom";
import { convertToCommaSeparate, getBulkActions } from "../../utils/utils";
import { getPriceAdjustmentDateGroups, getPriceAdjustmentItems } from "../../api/adminapi";
import { getCache, setCache } from "../../cacheUtil";
import AdminTableMeta from "../../components/AdminTableMeta";
import PriceAdjustmentEditor from "../PriceAdjustmentEditor";

const CACHE_KEY_PRICE_ADJUSTMENT_DATE_GROUPS = 'price_adjustment_date_groups';
const CACHE_KEY_PRICE_ADJUSTMENT_ITEMS = 'price_adjustment_items';

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric'
    }).format(date);
};

const PriceAdjustment = () => {

    const [items, setItems] = useState(undefined)
    const [dateGroups, setDateGroups] = useState([])
    const [loading, setLoading] = useState(false)
    const [popupVisible, setPopupVisible] = useState(false)
    const [enableInlineForm, setEnableInlineForm] = useState(false)
    const [selectedItems, setSelectedItems] = useState([]);
    const [activeDate, setActiveDate] = useState('')

    const fetchDateGroups = async (page = null) => {
        if (loading)
            return
        try {
            setLoading(true)
            await getPriceAdjustmentDateGroups().then(response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setDateGroups(_data);
                    if (_data.length) {
                        setActiveDate(_data[0])
                    }
                    setCache(CACHE_KEY_PRICE_ADJUSTMENT_DATE_GROUPS, _data);
                }
            }).catch(() => { }).finally(() => {
                setLoading(false)
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        const loadCachedListings = () => {
            
        };
        // loadCachedListings();
        fetchDateGroups();
        // }, [activeDate])
    }, [])

    const fetchItems = async (date = null) => {
        if (loading)
            return;
        if( ! date ){
            date = activeDate
        }

        const cachedItems = getCache(`${CACHE_KEY_PRICE_ADJUSTMENT_ITEMS}_${date}`);
        if (cachedItems) {
            setItems(cachedItems);
        }

        setLoading(true)
        await getPriceAdjustmentItems(date).then(response => {
            console.warn(response)
            if (response.status == 200) {
                setItems(response.data)
                setCache(`${CACHE_KEY_PRICE_ADJUSTMENT_ITEMS}_${date}`, response.data);
            }
        }).catch(err => {

        }).finally(() => {
            setLoading(false)
        });
    }
    useEffect(() => {
        if (activeDate) {
            fetchItems(activeDate);
        }
    }, [activeDate])

    const handleStatusFilter = (status) => {

    }

    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allItemsIDs = items.map((item) => item.ID);
            setSelectedItems(allItemsIDs);
        } else {
            setSelectedItems([]);
        }
    };

    const handleListingCheckbox = (e, itemID) => {
        if (e.target.checked) {
            setSelectedItems((prev) => [...prev, itemID]);
        } else {
            setSelectedItems((prev) => prev.filter((id) => id !== itemID));
        }
    };

    const bulkFn = (action = null) => {
        if (!action)
            return;
        switch( action ){
            case 'edit_one_by_one':
                setPopupVisible(false)
                setEnableInlineForm(true)
                break;
            case 'edit_in_bulk':
                setPopupVisible(true)
                setEnableInlineForm(false)
                break;
        }
    }
    const [selectedAction, setSelectedAction] = useState('');

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="admin-card">
                            <div className="d-flex align-center justify-content-between pr-25">
                                <AdminTableMeta showPagination={false}
                                    refresh={fetchItems}
                                    loading={loading}
                                    showSearch={false}
                                    showBulkActions={true}
                                    bulkActions={{
                                        actions: getBulkActions('priceadjustment')
                                    }}
                                    actionCallback={setSelectedAction}
                                    selectedItems={selectedItems}
                                    setLoading={setLoading}
                                    post_type={'priceadjustment'}
                                    bulkFn={bulkFn}
                                />
                                <div class="position-relative">
                                    <select class="select2 form-select select2-hidden-accessible" name="post_status"
                                        onChange={(e) => {
                                            setActiveDate(e.target.value)
                                        }}>
                                        {
                                            dateGroups.length > 0 ? (
                                                dateGroups.map((dateGroup, index) => (
                                                    <option key={index} value={dateGroup}>
                                                        {formatDate(dateGroup)}
                                                    </option>
                                                ))
                                            ) : (
                                                <option disabled>Loading dates...</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                            <div class="card-datatable table-responsive">
                                <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                    <table class="datatables-products table dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                        <thead class="border-top">
                                            <tr>
                                                <th class="control sorting_disabled dtr-hidden" rowspan="1" colspan="1" style={{
                                                    width: 22.3594 + 'px',
                                                    display: 'none',
                                                }} aria-label=""></th>
                                                <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" rowspan="1" colspan="1" style={{
                                                    width: 18.6328 + 'px',
                                                }} data-col="1" aria-label="">
                                                    <input type="checkbox" class="form-check-input"
                                                        onChange={handleAllSelector}
                                                        checked={items && selectedItems.length === items.length}
                                                    />
                                                </th>
                                                <th class="sorting sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 113.82 + 'px',
                                                }} aria-sort="ascending" aria-label="product: activate to sort column descending">Title</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61.4375 + 'px',
                                                }} aria-label="sku: activate to sort column ascending">sku</th>
                                                <th class="sorting_disabled" rowspan="1" colspan="1" style={{
                                                    width: 84.5703 + 'px',
                                                }} aria-label="stock">Amazon Price</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 78.8125 + 'px',
                                                }} aria-label="price: activate to sort column ascending">Website Price</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61 + 'px',
                                                }} aria-label="qty: activate to sort column ascending">Ebay Price</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61 + 'px',
                                                }} aria-label="qty: activate to sort column ascending">Category</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61 + 'px',
                                                }} aria-label="qty: activate to sort column ascending">Original Price</th>
                                                <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                    width: 61 + 'px',
                                                }} aria-label="qty: activate to sort column ascending">status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                'undefined' == typeof items ?
                                                    (
                                                        <>
                                                            <tr class="odd">
                                                                <td valign="top" colspan="9" class="dataTables_empty">Loading...</td>
                                                            </tr>
                                                        </>
                                                    ) : (
                                                        !items.length ?
                                                            <p>No items found</p> :
                                                            items.map(item => {
                                                                return <>
                                                                    <tr class="odd">
                                                                        <td class="dt-checkboxes-cell">
                                                                            <input type="checkbox" class="dt-checkboxes form-check-input"
                                                                                checked={selectedItems.includes(item.ID)}
                                                                                onChange={(e) => handleListingCheckbox(e, item.ID)}
                                                                                value={item.ID}
                                                                            />
                                                                        </td>
                                                                        <td class="sorting_1">
                                                                            <div class="d-flex justify-content-start align-items-center product-name">
                                                                                <Link to={`/admin/products/add?product_id=${item.parent_id}&action=edit`}>
                                                                                    <div class="avatar-wrapper">
                                                                                        {
                                                                                            item.image ?
                                                                                                <div class="avatar avatar me-4 rounded-2 bg-label-secondary">
                                                                                                    <img src={item.image} alt="Product-9" class="rounded-2" />
                                                                                                </div> : null
                                                                                        }
                                                                                    </div>
                                                                                    <div class="d-flex flex-column">
                                                                                        <h6 class="mb-0" dangerouslySetInnerHTML={{ __html: item.title ? item.title : '--' }} />
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <span dangerouslySetInnerHTML={{ __html: item.sku ? item.sku : '--' }} />
                                                                        </td>
                                                                        <td><span>${convertToCommaSeparate(item.amazon_price)}</span></td>
                                                                        <td><span>${convertToCommaSeparate(item.website_price)}</span></td>
                                                                        <td><span>${convertToCommaSeparate(item.ebay_price)}</span></td>
                                                                        <td>
                                                                            {item.category}
                                                                        </td>
                                                                        <td><span>${convertToCommaSeparate(item.original_price)}</span></td>
                                                                        <td class="dtr-hidden">
                                                                            <div class="d-flex flex-column">
                                                                                <span class={`badge ${item.status == 'ended' ? 'bg-label-danger' : ''} ${item.status == 'live' || item.status == 'scheduled' ? 'bg-label-success' : ''} ${item.status == 'pending' ? 'bg-label-warning' : ''}`} text-capitalized="">
                                                                                    {item.status}
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    {
                                                                        enableInlineForm && selectedItems.includes(item.ID) && selectedAction == 'edit_one_by_one' ?
                                                                            <tr>
                                                                                <td colspan="9">
                                                                                    <PriceAdjustmentEditor items={[item]} visible={true} setVisible={()=>{}} isInline={true} refresh={fetchItems} />
                                                                                </td>
                                                                            </tr> : null
                                                                    }
                                                                </>
                                                            })
                                                    )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
        {
            items?.length && selectedAction == 'edit_in_bulk' ?
                <PriceAdjustmentEditor items={items} visible={popupVisible} setVisible={setPopupVisible} refresh={fetchItems} /> : null
        }
    </div>
}

export default PriceAdjustment