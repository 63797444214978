import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import '../../assets/css/admin.dropzone.css'
import '../../assets/css/admin.editor.css'
import { useNavigate, useSearchParams } from "react-router-dom";
import { Accordion, Stack, DatePicker, Uploader, VStack, SelectPicker, TagPicker } from 'rsuite';
import 'rsuite/Accordion/styles/index.css';
import 'rsuite/DatePicker/styles/index.css';
import 'rsuite/Uploader/styles/index.css';
import "rsuite/dist/rsuite.css";

import {
    MDXEditor, headingsPlugin, UndoRedo, BoldItalicUnderlineToggles, toolbarPlugin, markdownShortcutPlugin,
    thematicBreakPlugin,
    quotePlugin,
    listsPlugin,
    BlockTypeSelect,
    CreateLink,
    InsertImage,
    ListsToggle,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import { getProductVariations, getProudct, getTags, updateProudct } from "../../api/adminapi";
import { productDataTabs } from "../../utils/utils";
import toast from "react-hot-toast";
import { getCache, setCache } from "../../cacheUtil";

const CACHE_KEY_CATEGORIES = 'categories_data';
const CACHE_KEY_CONDITION = 'condition_data';
const CACHE_KEY_TEAM = 'team_data';
const CACHE_KEY_SPORTS = 'sports_data';
const CACHE_KEY_MANUFACTURERS = 'manufacturers_data';
const CACHE_KEY_YEAR = 'year_data';
const CACHE_KEY_PLAYER = 'player_data';
const CACHE_KEY_PRODUCT = 'product_data';
const CACHE_KEY_GRADES = 'product_grades';

const fillMissingData = (data) => {
    data.stock_quantity = data.stock_quantity ? data.stock_quantity : 1;
    data.low_stock_amount = data.low_stock_amount ? data.low_stock_amount : 1;
    return data;
}

const formatDate = (dateString) => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    };

    const date = new Date(dateString);
    return date.toLocaleString('en-US', options).replace(',', ' at');
}

const AccordionHeader = props => {
    const { avatarUrl, title, subtitle, id, variation, ...rest } = props;
    return (
        <Stack {...rest} spacing={10} alignItems="flex-start"
        >
            <Stack spacing={2} direction="column" alignItems="flex-start">
                <div className="row">
                    <div className="col">
                        <div>#{id} | {variation?.attributes[0]?.option}</div>
                    </div>
                </div>
            </Stack>
        </Stack>
    );
};

const AttributeHeader = props => {
    const { id, attribute, ...rest } = props;
    return (
        <Stack {...rest} spacing={10} alignItems="flex-start">
            <Stack spacing={2} direction="column" alignItems="flex-start">
                <div className="row">
                    <div className="col">
                        <div>{attribute?.name}</div>
                    </div>
                </div>
            </Stack>
        </Stack>
    );
};

const isTermInTerms = (term, terms, _match_type = 'term_id') => {

    if ('slug' == _match_type) {
        return term?.slug == terms?.slug
    }

    let is_there = false;
    terms?.map(t => {
        if (t.id == term.term_id)
            is_there = true
    })
    return is_there;
}

const AddProduct = () => {
    const [categories, setCategories] = useState([])
    const [condition, setCondition] = useState([])
    const [team, setTeam] = useState([])
    const [sports, setSports] = useState([])
    const [manufacturers, setManufacturers] = useState([])
    const [player, setPlayer] = useState([])
    const [year, setYear] = useState([])
    const [variations, setVariations] = useState([])
    const [searchParams] = useSearchParams();
    const [editing, setEditing] = useState(false)
    const [editingDate, setEditingDate] = useState(false)
    const [product, setProduct] = useState(undefined)
    const [activeTab, setActiveTab] = useState('general')
    const [productInformation, setProductInformation] = useState({
        backordered: false,
        backorders_allowed: false,
        downloadable: false,
        manage_stock: true,
        shipping_required: true,
        shipping_taxable: true,
        on_sale: false,
        virtual: false,
        type: 'simple',
        name: '',
        slug: '',
        sku: '',
        date_created: new Date(),
        description: '',
        short_description: '',
        shipping_class: '',
        shipping_class_id: '',
        status: 'publish',
        tax_class: '',
        tax_status: 'taxable',
        backorders: 'no',
        catalog_visibility: 'visible',
        categories: [],
        images: [],
        variations: [],
        manufacturer: [],
        meta_data: [],
        attributes: [],
        price: '0.00',
        regular_price: '0.00',
        sale_price: '0.00',
        stock_quantity: 1,
        low_stock_amount: 1,
        stock_status: 'instock',
        year: {},
        card_number: '',
        sports: [],
        ebay_shipping: 0,
        gallery: [],
        featured_image: 0,
        amazon_price: 0,
        ebay_price: 0,
        website_price: 0,
        list_on_amazon: false,
        list_on_ebay: false,
        ebay_listings: [],
        amazon_listings: [],
    });

    useEffect(() => {
        if (searchParams.get('action') == 'edit' && searchParams.get('product_id')) {
            setEditing(true)
        } else {
            setEditing(false)
            setProduct(undefined)
        }
        fetchAllTaxonomies();
    }, []);

    const fetchAllTaxonomies = async () => {

        const fetchAndCacheData = async (cacheKey, fetchFn, setFn) => {
            const cachedData = getCache(cacheKey);
            if (cachedData) {
                setFn(cachedData);
            } else {
                const response = await fetchFn();
                if (response.status === 200) {
                    setFn(response.data.terms);
                    setCache(cacheKey, response.data.terms);
                }
            }
        };

        // Fetch and cache each taxonomy
        await fetchAndCacheData(CACHE_KEY_CATEGORIES, () => getTags('product_cat', 1, 20), setCategories);
        fetchAndCacheData(CACHE_KEY_CONDITION, () => getTags('condition', 1, 20), setCondition);
        fetchAndCacheData(CACHE_KEY_TEAM, () => getTags('team', 1, 20), setTeam);
        fetchAndCacheData(CACHE_KEY_SPORTS, () => getTags('sports', 1, 20), setSports);
        fetchAndCacheData(CACHE_KEY_MANUFACTURERS, () => getTags('manufacturer', 1, 20), setManufacturers);
        fetchAndCacheData(CACHE_KEY_YEAR, () => getTags('c_year', 1, 20), setYear);
        fetchAndCacheData(CACHE_KEY_PLAYER, () => getTags('player', 1, 20), setPlayer);
    }

    const setProductAndDetails = (productData) => {
        setProduct(productData);
        setProductInformation({
            ...productInformation,
            backordered: productData.backordered,
            backorders_allowed: productData.backorders_allowed,
            downloadable: productData.downloadable,
            manage_stock: productData.manage_stock,
            shipping_required: productData.shipping_required,
            shipping_taxable: productData.shipping_taxable,
            on_sale: productData.on_sale,
            virtual: productData.virtual,
            type: productData.type,
            name: productData.name,
            slug: productData.slug,
            sku: productData.sku,
            attributes: productData.attributes,
            backorders: productData.backorders,
            catalog_visibility: productData.catalog_visibility,
            categories: productData.categories,
            date_created: productData.date_created,
            description: productData.description,
            short_description: productData.short_description,
            images: productData.images,
            variations: productData.variations,
            manufacturer: productData.manufacturer,
            meta_data: productData.meta_data,
            price: productData.price,
            regular_price: productData.regular_price,
            sale_price: productData.sale_price,
            shipping_class: productData.shipping_class,
            shipping_class_id: productData.shipping_class_id,
            status: productData.status ? productData.status : 'publish',
            stock_quantity: productData.stock_quantity ? productData.stock_quantity : 1,
            stock_status: productData.stock_status,
            tax_class: productData.tax_class,
            tax_status: productData.tax_status,
            year: productData.year,
            card_number: productData.card_number,
            ebay_shipping: productData.ebay_shipping,
            low_stock_amount: productData.low_stock_amount ? productData.low_stock_amount : 1,
            ebay_listings: productData.ebay_listings ? productData.ebay_listings : [],
            amazon_listings: productData.amazon_listings ? productData.amazon_listings : [],
        });
        if (productData.type === 'variable') {
            fetchProductVariations(productData.id);
        }
    };

    const fetchProductVariations = async (product_id) => {
        await getProductVariations(product_id).then(response => {
            if (response.status == 200) {
                const _data = response.data;
                setVariations(_data)
            }
        })
    }

    const fetchProduct = async () => {
        try {
            setFormProcessing(true)

            const productId = searchParams.get('product_id');
            const cachedProduct = getCache(`${CACHE_KEY_PRODUCT}_${productId}`);
            if (cachedProduct) {
                setProductAndDetails(cachedProduct);
                setFormProcessing(false);
                return;
            }

            await getProudct(productId).then(async response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setCache(`${CACHE_KEY_PRODUCT}_${productId}`, _data);
                    setProductAndDetails(_data);
                    setFormProcessing(false)
                }
            })

        } catch (error) {
            setFormProcessing(false)
        }
    }

    useEffect(() => {
        if (editing) {
            fetchProduct()
        }
    }, [editing])

    const ref = React.useRef(null)
    const dateRef = React.useRef(null)
    const navigate = useNavigate();
    const [formProcessing, setFormProcessing] = useState(false)
    const handleFormSubmit = async () => {
        if (formProcessing)
            return;
        setFormProcessing(true)

        const keysToRemove = ['date_created', 'date_created_gmt', 'date_modified', 'date_modified_gmt', 'downloads', 'meta_data', 'menu_order', 'variations', 'attributes', 'related_ids', 'shipping_class_id', 'shipping_taxable', 'shipping_required', 'backordered', 'backorders_allowed', 'total_sales', 'purchasable', 'on_sale', 'price_html', 'price'];
        let filteredInfo = { ...productInformation };
        keysToRemove.forEach(key => {
            delete filteredInfo[key];
        });
        filteredInfo = fillMissingData(filteredInfo);

        await updateProudct(product?.id, filteredInfo, editing).then(res => {
            if (res.status == 200) {
                toast.success("Product updated successfully", {
                    position: "top-center",
                    style: {
                        borderColor: 'green',
                        borderWidth: '1px',
                        borderStyle: 'solid'
                    }
                })
            } else if (!editing && res.status == 201) {
                navigate(`/admin/products/add?product_id=${res.data.id}&action=edit`)
            }
        }).catch(err => {
            console.warn(err)
        })
        setFormProcessing(false)
    }

    const saveProductInformation = (key, value) => {
        setProductInformation({
            ...productInformation,
            [key]: value,
        })
    }

    const saveVariationInformation = (key, value, index) => {
        setVariations(prevVariations => {
            const newVariations = [...prevVariations];
            newVariations[index] = {
                ...newVariations[index],
                [key]: value
            };

            return newVariations;
        });
    };

    const [featureImage, setFeaturedImage] = useState({});
    const [productGallery, setProductGallery] = useState([]);
    const handleRemoveFeaturedImage = (e) => {
        e.preventDefault()
        setFeaturedImage({})
    }

    const addNewVariation = () => {
        const newVariation = {
            id: Date.now(),
            price: '',
            sale_price: '',
            stock_quantity: 1,
            stock_status: 'instock',
            attributes: {},
            status: 'private',
            downloadable: false,
            virtual: false,
            manage_stock: false,
            description: '',
            amazon_price: '',
            ebay_price: '',
            website_price: ''
        };
        setVariations(prevVariations => [...prevVariations, newVariation]);
    };

    const [attributes, setAttributes] = useState([])
    const addAttribute = (type) => {
        setAttributes([
            ...attributes,
            {
                id: 0,
                name: type == 'grade' ? 'Grade' : '',
                values: [],
                type: type,
                visible: false,
                usedVariations: false,
            }
        ])
    }

    const updateAttributeName = (index, value) => {
        setAttributes(prevAttributes => {
            const updatedAttributes = [...prevAttributes];
            updatedAttributes[index] = {
                ...updatedAttributes[index],
                name: value
            };
            return updatedAttributes;
        });
    };

    const updateAttributeValues = (index, value) => {
        const valuesArray = value.split('|').filter(val => val.trim() !== '');
        setAttributes(prevAttributes => {
            const updatedAttributes = [...prevAttributes];
            updatedAttributes[index] = {
                ...updatedAttributes[index],
                values: valuesArray
            };
            return updatedAttributes;
        });
    };

    const [grades, setGrades] = useState([])
    const fetchGrades = async () => {
        const cachedGrades = getCache(CACHE_KEY_GRADES);
        if (cachedGrades) {
            setGrades(cachedGrades)
            return
        }
        await getTags('pa_grade', 1, -1).then(response => {
            if (response.status == 200) {
                const _data = response.data;
                setGrades(_data.terms)
                const data = _data.terms.map(
                    term => ({ label: term.term.name, value: term.term.term_id })
                );
                setCache(CACHE_KEY_GRADES, data);
            }
        }).catch((err) => {

        }).finally(() => {

        });
    }

    const updateAttributeGradeValues = (index, selectedIds) => {
        const selectedGrades = grades.filter(grade => selectedIds.includes(grade.value));
        console.warn(selectedGrades)
        setAttributes(prevAttributes => {
            const updatedAttributes = [...prevAttributes];
            updatedAttributes[index] = {
                ...updatedAttributes[index],
                values: selectedGrades
            };
            return updatedAttributes;
        });
    };

    useEffect(() => {
        fetchGrades();
    }, [])

    const [savingAttribute, setSavingAttribute] = useState(false)

    const updateAttributeVariation = (type = 'attribute') => {
        setSavingAttribute(true)
        setTimeout(()=>{
            setSavingAttribute(false)
        },2500)
    }

    return <div class="layout-wrapper layout-content-navbar light-styleclass">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleFormSubmit()
                }}>
                    <input type="hidden" name="product_id" value={editing && product ? product.id : 0} />
                    <div className="content-wrapper">
                        <div className="flex-grow-1 container-p-y container-fluid">
                            <div className="app-ecommerce">
                                <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-6 row-gap-4">
                                    <div class="d-flex flex-column justify-content-center">
                                        <h4 class="mb-1">{editing ? 'Edit' : 'Add a new'} Product</h4>
                                    </div>
                                    <div class="d-flex align-content-center flex-wrap gap-4">
                                        {
                                            !editing ?
                                                <div class="d-flex gap-4">
                                                    <button type="button" class="btn btn-label-secondary waves-effect">Discard</button>
                                                    {/* <button type="button" class="btn btn-label-primary waves-effect">Save draft</button> */}
                                                </div> : null
                                        }
                                    </div>
                                </div>
                                <div class="row">
                                    <div className="col-12 col-lg-8">
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header">
                                                <h5 class="admin-card-tile mb-0">Product information</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                <div class="col mb-4">
                                                    <label class="form-label">Title</label>
                                                    <input type="text" class="form-control" placeholder="Product title" name="title" value={`${productInformation.name}`} required="required"
                                                        onChange={(e) => {
                                                            saveProductInformation('name', e.target.value)
                                                        }}
                                                    />
                                                </div>
                                                <div>
                                                    <label class="mb-1">Description</label>
                                                    <div class="form-control p-0">
                                                        <MDXEditor
                                                            markdown={`${product ? product.description : ''}`}
                                                            plugins={[
                                                                toolbarPlugin({
                                                                    toolbarContents: () => (
                                                                        <>
                                                                            {' '}
                                                                            <UndoRedo />
                                                                            <BoldItalicUnderlineToggles />
                                                                            <BlockTypeSelect />
                                                                            <CreateLink />
                                                                            <InsertImage />
                                                                            <ListsToggle />
                                                                        </>
                                                                    )
                                                                }),
                                                                headingsPlugin(),
                                                                listsPlugin(),
                                                                quotePlugin(),
                                                                thematicBreakPlugin(),
                                                                markdownShortcutPlugin()
                                                            ]}
                                                            ref={ref}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header">
                                                <div className="rows d-flex gap-3">
                                                    <div className="cols">
                                                        <h5 class="admin-card-title mb-0">Product data</h5>
                                                    </div>
                                                    <div className="cols d-flex gap-4 align-center">
                                                        <div class="position-relative">
                                                            <select class="select2 form-select select2-hidden-accessible" name="type" value={productInformation.type}
                                                                onChange={(e) => {
                                                                    saveProductInformation('type', e.target.value)
                                                                }}
                                                            >
                                                                <option value="" data-select2-id="94" disabled="disabled" selected>Product type</option>
                                                                <option value="simple">Simple</option>
                                                                <option value="variable">Variable</option>
                                                            </select>
                                                        </div>
                                                        <div className="rows d-flex gap-3">
                                                            <div className="cols">
                                                                <div class="form-check">
                                                                    <label class="form-check-label" >Virtual</label>
                                                                    <input type="checkbox" class="form-check-input" name="virtual" onChange={(e) => {
                                                                        saveProductInformation('virtual', e.target.value)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                            <div className="cols">
                                                                <div class="form-check">
                                                                    <label class="form-check-label">Downloadable</label>
                                                                    <input type="checkbox" class="form-check-input" name="downloadable" onChange={(e) => {
                                                                        saveProductInformation('downloadable', e.target.value)
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="admin-card-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-3 col-xl-3 col-xxl-3 mx-auto card-separator">
                                                        <div class="d-flex justify-content-between flex-column mb-4 mb-md-0 pe-md-4">
                                                            <div class="nav-align-left">
                                                                <ul class="nav nav-pills flex-column w-100" role="tablist">
                                                                    {
                                                                        productDataTabs
                                                                            .filter(tab => !(productInformation.type === 'simple' && tab.id === 'variations') && !(productInformation.type === 'variable' && tab.id === 'price_adjustment'))
                                                                            .map(tab => {
                                                                                return (
                                                                                    <li class="nav-item" role="presentation">
                                                                                        <button type="button" className={`nav-link waves-effect waves-light ${activeTab == tab.id ? 'active' : ''}`} data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" onClick={() => {
                                                                                            setActiveTab(tab.id)
                                                                                        }}>
                                                                                            <i class="ti ti-box ti-sm me-1_5"></i>
                                                                                            <span class="align-middle">
                                                                                                {tab.title}
                                                                                            </span>
                                                                                        </button>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-9 col-xl-9 col-xxl-9 pt-6 pt-md-0">
                                                        <div class={`tab-content p-0 ps-md-4 ${savingAttribute ? 'processing' : ''}`}>
                                                            <div className={`tab-pane fade ${activeTab == 'general' ? 'active show' : ''}`} id="general" role="tabpanel">
                                                                {
                                                                    productInformation.type == 'simple' ?
                                                                        <>
                                                                            <div class="mb-3">
                                                                                <label class="form-label">Regular Price ($)</label>
                                                                                <input type="number" class="form-control" placeholder="Price" name="price" value={productInformation.regular_price}
                                                                                    onChange={(e) => {
                                                                                        saveProductInformation('regular_price', e.target.value)
                                                                                        // saveProductInformation('price', e.target.value)
                                                                                    }}
                                                                                    required="required"
                                                                                />
                                                                            </div>
                                                                            <div class="mb-3">
                                                                                <label class="form-label">Sale Price ($)</label>
                                                                                <input type="number" class="form-control" placeholder="Price" name="sale_price" value={productInformation.sale_price}
                                                                                    onChange={(e) => {
                                                                                        saveProductInformation('sale_price', e.target.value)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <hr />
                                                                        </> : null
                                                                }
                                                                <div class="mb-3 col ecommerce-select2-dropdown">
                                                                    <label class="form-label mb-1">Tax status</label>
                                                                    <div class="position-relative">
                                                                        <select class="select2 form-select select2-hidden-accessible" name="tax_status" value={`${productInformation.tax_status}`}
                                                                            onChange={(e) => {
                                                                                saveProductInformation('tax_status', e.target.value)
                                                                            }}
                                                                        >
                                                                            <option value="taxable">Taxable</option>
                                                                            <option value="shipping">Shipping only</option>
                                                                            <option value="none">None</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3 col ecommerce-select2-dropdown">
                                                                    <label class="form-label mb-1">Tax class</label>
                                                                    <div class="position-relative">
                                                                        <select class="select2 form-select select2-hidden-accessible" name="tax_class" value={`${productInformation.tax_class}`}
                                                                            onChange={(e) => {
                                                                                saveProductInformation('tax_class', e.target.value)
                                                                            }}
                                                                        >
                                                                            <option value="standard">Standard</option>
                                                                            <option value="reduced-rate">Reduced rate</option>
                                                                            <option value="zero-rate">Zero rate</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={`tab-pane fade ${activeTab == 'inventory' ? 'active show' : ''}`} id="inventory" role="tabpanel">
                                                                <div>
                                                                    <div class="col mb-3">
                                                                        <label class="form-label">SKU</label>
                                                                        <input type="text" class="form-control" name="sku" value={`${productInformation.sku}`}
                                                                            onChange={(e) => {
                                                                                saveProductInformation('sku', e.target.value)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="d-flex justify-content-between align-items-center">

                                                                        <div class="form-check">
                                                                            <label class="form-check-label">Manage stock level (quantity)</label>
                                                                            <input type="checkbox" class="form-check-input" name="manage_stock_level" onChange={(e) => {
                                                                                saveProductInformation('manage_stock', e.target.checked)
                                                                            }}
                                                                                {...(productInformation.manage_stock === true ? { checked: true } : {})}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        productInformation.manage_stock ?
                                                                            <>
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Stock quantity</label>
                                                                                    <input type="number" class="form-control" placeholder="Stock quantity" name="stock" value={productInformation.stock_quantity}
                                                                                        onChange={(e) => {
                                                                                            saveProductInformation('stock_quantity', e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div class="mb-3 col ecommerce-select2-dropdown">
                                                                                    <label class="form-label mb-1">Allow backorders?</label>
                                                                                    <div class="position-relative">
                                                                                        <select class="select2 form-select select2-hidden-accessible" name="allow_backorders" value={`${productInformation.backorders}`}
                                                                                            onChange={(e) => {
                                                                                                saveProductInformation('backorders', e.target.value)
                                                                                            }}
                                                                                        >
                                                                                            <option value="no">No</option>
                                                                                            <option value="notify">Allow, but notify customer</option>
                                                                                            <option value="yes">Allow</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="mb-3">
                                                                                    <label class="form-label">Low stock threshold</label>
                                                                                    <input type="number" min="1" class="form-control" placeholder="Low stock threshold" name="low_stock_amount" value={productInformation.low_stock_amount}
                                                                                        onChange={(e) => {
                                                                                            saveProductInformation('low_stock_amount', e.target.value)
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </> : <div class="mb-3 col ecommerce-select2-dropdown">
                                                                                <label class="form-label mb-1">Stock status</label>
                                                                                <div class="position-relative">
                                                                                    <select class="select2 form-select select2-hidden-accessible" name="stock_status" value={`${productInformation.stock_status}`}
                                                                                        onChange={(e) => {
                                                                                            saveProductInformation('stock_status', e.target.value)
                                                                                        }}
                                                                                    >
                                                                                        <option value="instock">In stock</option>
                                                                                        <option value="outofstock">Out of stock</option>
                                                                                        <option value="onbackorder">On backorder</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={`tab-pane fade ${activeTab == 'attributes' ? 'active show' : ''}`} id="attributes" role="tabpanel">
                                                                <div className="flex gap-2">
                                                                    <button type="button" onClick={() => {
                                                                        addAttribute('custom')
                                                                    }} className="btn btn-primary">
                                                                        Add custom attribute
                                                                    </button>
                                                                    <button type="button" onClick={() => {
                                                                        addAttribute('grade')
                                                                    }} className="btn btn-primary">
                                                                        Add Grade
                                                                    </button>
                                                                </div>
                                                                <hr />
                                                                {
                                                                    attributes.length ?
                                                                        <Accordion bordered>
                                                                            {
                                                                                attributes.map((attribute, index) => {
                                                                                    return <Accordion.Panel
                                                                                        header={
                                                                                            <AttributeHeader
                                                                                                id={index}
                                                                                                attribute={attribute}
                                                                                            />
                                                                                        }
                                                                                        eventKey={1}
                                                                                    >
                                                                                        <div className="product_attribute_wrapper flex gap-3">
                                                                                            <div className="col-4">
                                                                                                <label class="form-label">Name:</label>
                                                                                                {
                                                                                                    attribute.type == 'custom' ?
                                                                                                        <>
                                                                                                            <input type="text" class="form-control" name="title"
                                                                                                                onChange={(e) => updateAttributeName(index, e.target.value)}
                                                                                                                value={attribute.name}
                                                                                                            />
                                                                                                        </> : <>
                                                                                                            <br />
                                                                                                            <strong>Grade</strong>
                                                                                                            <div className="d-flex gap-2 mt-4">
                                                                                                                <input type="checkbox" class="form-check-input" id="visible_on_product_page" name="visible_on_product_page" />
                                                                                                                <label class="form-check-label" htmlFor="visible_on_product_page">Visible on the product page</label>
                                                                                                            </div>
                                                                                                            <div className="d-flex gap-2 mt-2">
                                                                                                                <input type="checkbox" class="form-check-input" id="used_for_variations" name="used_for_variations" onChange={(e) => {

                                                                                                                }} />
                                                                                                                <label class="form-check-label" htmlFor="used_for_variations">Used for variations</label>
                                                                                                            </div>
                                                                                                        </>
                                                                                                }
                                                                                            </div>
                                                                                            <div className="col-8">
                                                                                                <label className="form-label">
                                                                                                    Value(s):
                                                                                                </label><br />
                                                                                                {
                                                                                                    attribute.type == 'custom' ?
                                                                                                        <textarea class="form-control"
                                                                                                            placeholder="Enter some text, or some attributes by &quot;|&quot; separating values."
                                                                                                            value={attribute.values.join('|')}
                                                                                                            onChange={(e) => updateAttributeValues(index, e.target.value)}
                                                                                                            rows={'3'}
                                                                                                        /> : <TagPicker
                                                                                                            data={grades}
                                                                                                            onChange={(selectedItems) => {
                                                                                                                updateAttributeGradeValues(index, selectedItems)
                                                                                                                // console.warn(selectedItems)
                                                                                                            }}
                                                                                                            labelKey="label"
                                                                                                            valueKey="value"
                                                                                                            style={{ width: '99%' }}
                                                                                                        />
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </Accordion.Panel>
                                                                                })
                                                                            }
                                                                        </Accordion> : null
                                                                }
                                                                <hr />
                                                                <button type="button" onClick={updateAttributeVariation} className="btn btn-primary">
                                                                    Save attributes
                                                                </button>
                                                            </div>
                                                            <div className={`tab-pane fade ${activeTab == 'variations' ? 'active show' : ''}`} id="variations" role="tabpanel">
                                                                {
                                                                    ! attributes?.length ?
                                                                    (
                                                                        <p className="">Before you can add a variation you need to add some variation attributes on the <strong>Attributes</strong> tab.</p>
                                                                    ): (
                                                                        <></>
                                                                    )
                                                                }
                                                                {
                                                                    variations?.length ?
                                                                        <Accordion bordered>
                                                                            {
                                                                                variations.map((variation, index) => {
                                                                                    return <>
                                                                                        <Accordion.Panel header={
                                                                                            <AccordionHeader
                                                                                                id={variation.id}
                                                                                                variation={variation}
                                                                                            />
                                                                                        }
                                                                                            eventKey={1}>
                                                                                            <div className="row">
                                                                                                <div class="mb-6 col-4">
                                                                                                    <label class="form-label" for="form-repeater-1-1">Options</label>
                                                                                                    <div class="position-relative">
                                                                                                        <select class="select2 form-select select2-hidden-accessible">
                                                                                                            <option value="">Any Grade…</option>
                                                                                                            {
                                                                                                                product?.attributes?.map(attribute => {
                                                                                                                    return <option selected="selected" value={attribute.id}>
                                                                                                                        {attribute?.options[0]}
                                                                                                                    </option>
                                                                                                                })
                                                                                                            }
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr />
                                                                                                <label class="form-label" for="form-repeater-1-1">Featured Image</label>
                                                                                                {
                                                                                                    Object.keys(featureImage).length ?
                                                                                                        <div>
                                                                                                            <img src={featureImage.url} />
                                                                                                            <a className="remove_featured_image mt-4 d-inline-block" href="#" onClick={handleRemoveFeaturedImage}>Remove featured image</a>
                                                                                                        </div> : null
                                                                                                }
                                                                                                <hr />
                                                                                                <label class="form-label" for="form-repeater-1-1">Additional Images</label>
                                                                                                <Uploader action="https://staging.tonyetrade.com/uploader.php" draggable>
                                                                                                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                                        <span>Click or Drag images here to upload</span>
                                                                                                    </div>
                                                                                                </Uploader>
                                                                                                <hr />
                                                                                                <div className="row mb-3">
                                                                                                    <div className="col d-flex gap-4">
                                                                                                        <div class="form-check">
                                                                                                            <label class="form-check-label">Enabled</label>
                                                                                                            <input type="checkbox" class="form-check-input" name="enabled"
                                                                                                                onChange={(e) => {
                                                                                                                    saveVariationInformation('status', e.target.checked === true ? 'publish' : 'private', index)
                                                                                                                }}
                                                                                                                {...(variation.status === 'publish' ? { checked: true } : {})}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div class="form-check">
                                                                                                            <label class="form-check-label">Downloadable</label>
                                                                                                            <input type="checkbox" class="form-check-input" name="enabled"
                                                                                                                onChange={(e) => {
                                                                                                                    saveVariationInformation('downloadable', e.target.checked, index)
                                                                                                                }}
                                                                                                                {...(variation.downloadable === true ? { checked: true } : {})}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div class="form-check">
                                                                                                            <label class="form-check-label">Virtual</label>
                                                                                                            <input type="checkbox" class="form-check-input" name="enabled"
                                                                                                                onChange={(e) => {
                                                                                                                    saveVariationInformation('virtual', e.target.checked, index)
                                                                                                                }}
                                                                                                                {...(variation.virtual === true ? { checked: true } : {})}
                                                                                                            />
                                                                                                        </div>
                                                                                                        <div class="form-check">
                                                                                                            <label class="form-check-label">Manage Stock?</label>
                                                                                                            <input type="checkbox" class="form-check-input" name="enabled"
                                                                                                                onChange={(e) => {
                                                                                                                    saveVariationInformation('manage_stock', e.target.checked, index)
                                                                                                                }}
                                                                                                                {...(variation.manage_stock === true ? { checked: true } : {})}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr />
                                                                                                <div class="row mb-3">
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Regular price ($)</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Variation Price (required)" name={`regular_price[${index}]`}
                                                                                                            value={variation.price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('price', e.target.value, index);
                                                                                                                saveVariationInformation('regular_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Sale price ($)</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Variation Price (required)" name={`sale_price[${index}]`}
                                                                                                            value={variation.sale_price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('sale_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div class="row mb-3">
                                                                                                    <div className="col">
                                                                                                        {
                                                                                                            variation?.manage_stock != true ?
                                                                                                                <>
                                                                                                                    <label class="form-label" for="form-repeater-1-1">Stock Status</label>
                                                                                                                    <select className="select2 form-select select2-hidden-accessible" value={variation.stock_status} onChange={(e) => {
                                                                                                                        saveVariationInformation('stock_status', e.target.value, index);
                                                                                                                    }}>
                                                                                                                        <option value="instock" selected="selected">In stock</option>
                                                                                                                        <option value="outofstock">Out of stock</option>
                                                                                                                        <option value="onbackorder">On backorder</option>
                                                                                                                    </select>
                                                                                                                </> :
                                                                                                                <>
                                                                                                                    <label class="form-label" for="form-repeater-1-1">Stock quantity</label>
                                                                                                                    <input class="form-control" type="number" min="1" placeholder="Stock quantity" name={`stock_quantity[${index}]`}
                                                                                                                        value={variation.stock_quantity}
                                                                                                                        onChange={(e) => {
                                                                                                                            saveVariationInformation('stock_quantity', e.target.value, index);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Shipping class</label>
                                                                                                        <select className="select2 form-select select2-hidden-accessible" value={variation.shipping_class} onChange={(e) => {
                                                                                                            saveVariationInformation('shipping_class', e.target.value, index);
                                                                                                        }}>
                                                                                                            <option value={'parent'}>
                                                                                                                Same as parent
                                                                                                            </option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Tax class</label>
                                                                                                        <select className="select2 form-select select2-hidden-accessible" value={variation.tax_class} onChange={(e) => {
                                                                                                            saveVariationInformation('tax_class', e.target.value, index);
                                                                                                        }}>
                                                                                                            <option value={'parent'}>
                                                                                                                Same as parent
                                                                                                            </option>
                                                                                                            <option value="">Standard</option>
                                                                                                            <option value="reduced-rate">Reduced rate</option>
                                                                                                            <option value="zero-rate">Zero rate</option>
                                                                                                        </select>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    variation?.manage_stock === true ?
                                                                                                        <>
                                                                                                            <div class="row mb-6">
                                                                                                                <div className="col">
                                                                                                                    <label class="form-label" for="form-repeater-1-1">Allow backorders?</label>
                                                                                                                    <select className="select2 form-select select2-hidden-accessible" value={variation.backorders} onChange={(e) => {
                                                                                                                        saveVariationInformation('backorders', e.target.value, index);
                                                                                                                    }}>
                                                                                                                        <option value="no">Do not allow</option>
                                                                                                                        <option value="notify">Allow, but notify customer</option>
                                                                                                                        <option value="yes">Allow</option>
                                                                                                                    </select>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </> : null
                                                                                                }
                                                                                                <div class="row mb-6">
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Description</label>
                                                                                                        <textarea class="form-control" type="number" min="1" name=""
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('description', e.target.value, index);
                                                                                                            }}
                                                                                                            value={variation.description}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <hr />
                                                                                                <label class="form-label" for="form-repeater-1-1">Price Adjustment</label>
                                                                                                <div className="row">
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Amazon Price</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Amazon Price" name={`variable_amazon_price_adjustment[${index}]`}
                                                                                                            value={variation.amazon_price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('amazon_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Ebay Price</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Ebay Price" name={`variable_ebay_price_adjustment[${index}]`}
                                                                                                            value={variation.ebay_price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('ebay_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <label class="form-label" for="form-repeater-1-1">Website Price</label>
                                                                                                        <input class="form-control" type="number" min="1" placeholder="Website Price" name={`variable_website_price_adjustment[${index}]`}
                                                                                                            value={variation.website_price}
                                                                                                            onChange={(e) => {
                                                                                                                saveVariationInformation('website_price', e.target.value, index);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </Accordion.Panel >
                                                                                    </>
                                                                                })
                                                                            }
                                                                        </Accordion> : null
                                                                }
                                                            </div>
                                                            <div className={`tab-pane fade ${activeTab == 'price_adjustment' ? 'active show' : ''}`} id="price_adjustment" role="tabpanel">
                                                                <div class="mb-3">
                                                                    <label class="form-label">Amazon Price</label>
                                                                    <input type="number" class="form-control" placeholder="Amazon Price" name="amazon_price" value={productInformation.amazon_price}
                                                                        onChange={(e) => {
                                                                            saveProductInformation('amazon_price', e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label class="form-label">eBay Price</label>
                                                                    <input type="number" class="form-control" placeholder="eBay Price" name="ebay_price" value={productInformation.ebay_price}
                                                                        onChange={(e) => {
                                                                            saveProductInformation('ebay_price', e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div class="mb-3">
                                                                    <label class="form-label">Website Price</label>
                                                                    <input type="number" class="form-control" placeholder="Website Price" name="website_price" value={productInformation.website_price}
                                                                        onChange={(e) => {
                                                                            saveProductInformation('website_price', e.target.value)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            'variable' == productInformation.type ?
                                                <div class="admin-card mb-6" data-select2-id="144">
                                                    <div class="admin-card-header">
                                                        <h5 class="admin-card-title mb-0">Variations</h5>
                                                        <button type="button" onClick={addNewVariation} className="btn btn-primary">
                                                            Add New Variation
                                                        </button>
                                                    </div>
                                                    <div class="admin-card-body" data-select2-id="143">
                                                        <div class="form-repeater" data-select2-id="142">
                                                            <div data-repeater-list="group-a" data-select2-id="141">
                                                                <div data-repeater-item="">
                                                                    <div class="row">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                        <div class="admin-card mb-3">
                                            <div class="admin-card-header">
                                                <h5 class="admin-card-title mb-0">Additional Information</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                <div className="row">
                                                    <div className="col">
                                                        <div class="mb-3">
                                                            <label class="form-label mb-1">Shipping for eBay</label>
                                                            <select class="select2 form-select select2-hidden-accessible" name="ebay_shipping" value={productInformation.shipping_for_ebay}
                                                                onChange={(e) => {
                                                                    saveProductInformation('shipping_for_ebay', e.target.value)
                                                                }}
                                                            >
                                                                <option value="0">Standard Shipping</option>
                                                                <option value="14.95">Heavy Shipping ($14.95)</option>
                                                                <option value="24.95">Heavy Shipping ($24.95)</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div class="mb-3 col ecommerce-select2-dropdown">
                                                            <label class="form-label mb-1">Mark this Product as Badass?</label>
                                                            <div class="position-relative">
                                                                <select class="select2 form-select select2-hidden-accessible" name="badass_product" value={`${productInformation.badass}`}
                                                                    onChange={(e) => {
                                                                        saveProductInformation('badass', e.target.value)
                                                                    }}
                                                                >
                                                                    <option value="No">No</option>
                                                                    <option value="Yes">Yes</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mb-3">
                                                    <label class="form-label">Card Number</label>
                                                    <input type="number" min="1" class="form-control" placeholder="Card Number" name="card_number" value={productInformation.card_number}
                                                        onChange={(e) => {
                                                            saveProductInformation('card_number', e.target.value)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-6">
                                            <div className="col">
                                                <div class="admin-card mb-3">
                                                    <div class="admin-card-header">
                                                        <h5 class="admin-card-title mb-0">Categories</h5>
                                                    </div>
                                                    <div class="admin-card-body">
                                                        <div className="taxonomies_wrapper h-px-250 overflow-auto">
                                                            {
                                                                categories.map(category => {

                                                                    let _sep = '';
                                                                    if (category.level > 0) {
                                                                        for (var i = 1; i <= category.level; i++) {
                                                                            _sep += '—';
                                                                        }
                                                                    }
                                                                    return (
                                                                        <label class="list-group-item mb-2">
                                                                            <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
                                                                            <input class="form-check-input me-1" name="categories[]" type="checkbox" value={category.term.term_id}
                                                                                {...(isTermInTerms(category.term, product?.categories) ? { checked: true } : {})}
                                                                                onChange={(e) => {
                                                                                    saveProductInformation('categories', e.target.value)
                                                                                }}
                                                                            /> {category.term.name}
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div class="admin-card mb-3">
                                                    <div class="admin-card-header">
                                                        <h5 class="admin-card-title mb-0">Condition</h5>
                                                    </div>
                                                    <div class="admin-card-body">
                                                        <div className="taxonomies_wrapper h-px-250 overflow-auto">
                                                            {
                                                                condition.map(c => {
                                                                    let _sep = '';
                                                                    if (c.level > 0) {
                                                                        for (var i = 1; i <= c.level; i++) {
                                                                            _sep += '—';
                                                                        }
                                                                    }
                                                                    return (
                                                                        <label class="list-group-item mb-2">
                                                                            <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
                                                                            <input class="form-check-input me-1" type="checkbox" name="condition[]" value={c.term.term_id}
                                                                                onChange={(e) => {
                                                                                    saveProductInformation('list_on_ebay', e.target.value)
                                                                                }}
                                                                            /> {c.term.name}
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col">
                                                <div class="admin-card mb-3">
                                                    <div class="admin-card-header">
                                                        <h5 class="admin-card-title mb-0">Team</h5>
                                                    </div>
                                                    <div class="admin-card-body">
                                                        <div className="taxonomies_wrapper h-px-250 overflow-auto">
                                                            {
                                                                team.map(t => {
                                                                    let _sep = '';
                                                                    if (t.level > 0) {
                                                                        for (var i = 1; i <= t.level; i++) {
                                                                            _sep += '—';
                                                                        }
                                                                    }
                                                                    return (
                                                                        <label class="list-group-item mb-2">
                                                                            <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
                                                                            <input class="form-check-input me-1" type="checkbox" name="team[]" value={t.term.term_id}
                                                                                onChange={(e) => {
                                                                                    saveProductInformation('team', e.target.value)
                                                                                }}
                                                                            /> {t.term.name}
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div class="admin-card mb-3">
                                                    <div class="admin-card-header">
                                                        <h5 class="admin-card-title mb-0">Sports</h5>
                                                    </div>
                                                    <div class="admin-card-body">
                                                        <div className="taxonomies_wrapper h-px-250 overflow-auto">
                                                            {
                                                                sports.map(sport => {
                                                                    let _sep = '';
                                                                    if (sport.level > 0) {
                                                                        for (var i = 1; i <= sport.level; i++) {
                                                                            _sep += '—';
                                                                        }
                                                                    }
                                                                    return (
                                                                        <label class="list-group-item mb-2">
                                                                            <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
                                                                            <input class="form-check-input me-1" type="checkbox" name="sports[]" value={sport.term.term_id}
                                                                                onChange={(e) => {
                                                                                    saveProductInformation('sports', e.target.value)
                                                                                }}
                                                                            /> {sport.term.name}
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col">
                                                <div class="admin-card mb-3">
                                                    <div class="admin-card-header">
                                                        <h5 class="admin-card-title mb-0">Manufacturers</h5>
                                                    </div>
                                                    <div class="admin-card-body">
                                                        <div className="taxonomies_wrapper h-px-250 overflow-auto">
                                                            {
                                                                manufacturers.map(m => {
                                                                    let _sep = '';
                                                                    if (m.level > 0) {
                                                                        for (var i = 1; i <= m.level; i++) {
                                                                            _sep += '—';
                                                                        }
                                                                    }
                                                                    return (
                                                                        <label class="list-group-item mb-2">
                                                                            <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
                                                                            <input class="form-check-input me-1" name="manufacturer[]" type="checkbox" value={m.term.term_id}
                                                                                {...(isTermInTerms(m.term, product?.manufacturer, 'slug') ? { checked: true } : {})}
                                                                                onChange={(e) => {
                                                                                    saveProductInformation('manufacturer', e.target.value)
                                                                                }}
                                                                            /> {m.term.name}
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div class="admin-card mb-3">
                                                    <div class="admin-card-header">
                                                        <h5 class="admin-card-title mb-0">Year</h5>
                                                    </div>
                                                    <div class="admin-card-body">
                                                        <div className="taxonomies_wrapper h-px-250 overflow-auto">
                                                            {
                                                                year.map(y => {
                                                                    let _sep = '';
                                                                    if (y.level > 0) {
                                                                        for (var i = 1; i <= y.level; i++) {
                                                                            _sep += '—';
                                                                        }
                                                                    }
                                                                    return (
                                                                        <label class="list-group-item mb-2">
                                                                            <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
                                                                            <input class="form-check-input me-1" name="year[]" type="checkbox" value={y.term.term_id}
                                                                                {...(isTermInTerms(y.term, product?.year, 'slug') ? { checked: true } : {})}
                                                                                onChange={(e) => {
                                                                                    saveProductInformation('year', e.target.value)
                                                                                }}
                                                                            /> {y.term.name}
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col">
                                                <div class="admin-card mb-3">
                                                    <div class="admin-card-header">
                                                        <h5 class="admin-card-title mb-0">Player</h5>
                                                    </div>
                                                    <div class="admin-card-body">
                                                        <div className="taxonomies_wrapper h-px-250 overflow-auto">
                                                            {
                                                                player.map(p => {
                                                                    let _sep = '';
                                                                    if (p.level > 0) {
                                                                        for (var i = 1; i <= p.level; i++) {
                                                                            _sep += '—';
                                                                        }
                                                                    }
                                                                    return (
                                                                        <label class="list-group-item mb-2">
                                                                            <span className="text-white" dangerouslySetInnerHTML={{ __html: _sep }}></span>
                                                                            <input class="form-check-input me-1" name="player[]" type="checkbox" value={p.term.term_id}
                                                                                onChange={(e) => {
                                                                                    saveProductInformation('player', e.target.value)
                                                                                }}
                                                                            /> <span dangerouslySetInnerHTML={{ __html: p.term.name }}></span>
                                                                        </label>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header d-flex row align-center">
                                                <div className="col">
                                                    <h5 class="admin-card-title mb-0">Publish</h5>
                                                </div>
                                                <div style={{
                                                    minWidth: 256 + 'px',
                                                }} className="row col d-flex">
                                                    <div className="col d-flex align-center justify-content-end gap-3">
                                                        {
                                                            formProcessing ?
                                                                <div>
                                                                    <div class="spinner-border text-primary" role="status">
                                                                        <span class="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </div> : null
                                                        }
                                                        <button type="submit" class="btn btn-primary waves-effect waves-light">{editing ? 'Save' : 'Publish'} product</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="admin-card-body">
                                                <div class="mb-6 col ecommerce-select2-dropdown">
                                                    <label class="form-label mb-1" for="status-org">Status </label>
                                                    <div class="position-relative">
                                                        <select class="select2 form-select select2-hidden-accessible" name="status" value={`${productInformation.status}`}
                                                            onChange={(e) => {
                                                                saveProductInformation('status', e.target.value)
                                                            }}>
                                                            <option value="publish">Published</option>
                                                            <option value="pending">Pending Review</option>
                                                            <option value="draft">Draft</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {
                                                    !editingDate ?
                                                        <div class="mb-3 col ecommerce-select2-dropdown">
                                                            <label class="form-check-label">Publish{product && editing ? `ed on: ${formatDate(product?.date_created)}` : ` ${productInformation.date_created.toLocaleString('en-US', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true }).replace(',', '')}`}</label> <a style={{ textDecoration: 'underline' }} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setEditingDate(true)
                                                            }}>Edit</a>
                                                        </div> : null
                                                }
                                                {
                                                    editingDate ?
                                                        <div class="mb-6 d-flex gap-3 col ecommerce-select2-dropdown">
                                                            <DatePicker
                                                                ref={dateRef}
                                                                format="dd MMM yyyy hh:mm:ss aa"
                                                                style={{ width: 100 + '%' }}
                                                                block
                                                                cleanable={false}
                                                                value={editing ? new Date(product?.date_created) : productInformation.date_created}
                                                                onChange={(e) => {
                                                                    let _date = new Date(e);
                                                                    _date = _date.toISOString().split('.')[0]
                                                                    setProductInformation({
                                                                        ...productInformation,
                                                                        date_created: _date,
                                                                    });
                                                                }}
                                                            />
                                                            <button type="button" className="btn btn-primary waves-effect waves-light active" onClick={() => {
                                                                let _date = dateRef.current.target.value ? new Date(dateRef.current.target.value) : new Date()
                                                                _date = _date.toISOString().split('.')[0]
                                                                if (editing && product) {
                                                                    setProductInformation({
                                                                        ...productInformation,
                                                                        date_created: _date,
                                                                    });
                                                                    setEditingDate(false)
                                                                }
                                                            }}>
                                                                Save
                                                            </button>
                                                        </div> : null
                                                }
                                                {
                                                    !productInformation?.ebay_listings?.length ?
                                                        <div class="form-check">
                                                            <label class="form-check-label">List on eBay</label>
                                                            <input type="checkbox" class="form-check-input" name="list_on_ebay" onChange={(e) => {
                                                                saveProductInformation('list_on_ebay', e.target.value)
                                                            }} />
                                                        </div> :
                                                        <>
                                                            <p>
                                                                <strong>This product is linked to {productInformation.ebay_listings.length} eBay listings:</strong>
                                                            </p>
                                                            {
                                                                productInformation.ebay_listings.map(listing => (
                                                                    <p>
                                                                        <strong>{listing.ebay_id}</strong> <em>{listing.status}</em> <a href={listing.ViewItemURL} target="_blank">View on eBay</a>
                                                                    </p>
                                                                ))
                                                            }
                                                        </>

                                                }

                                                {
                                                    !productInformation?.amazon_listings?.length ?
                                                        <div class="form-check">
                                                            <label class="form-check-label">List on Amazon</label>
                                                            <input type="checkbox" class="form-check-input" name="list_on_amazon" onChange={(e) => {
                                                                saveProductInformation('list_on_amazon', e.target.value)
                                                            }} />
                                                        </div> :
                                                        <>
                                                            <p>
                                                                <strong>This product is linked to {productInformation.amazon_listings.length} amazon listings:</strong>
                                                            </p>
                                                            {
                                                                productInformation.amazon_listings.map(listing => (
                                                                    <p>
                                                                        <strong>{listing.asin}</strong> <em>{listing.status}</em> <a href={`https://www.amazon.com/dp/${listing.asin}`} target="_blank">View on Amazon</a>
                                                                    </p>
                                                                ))
                                                            }
                                                        </>

                                                }

                                                <div class="col-md-12 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                    <label class="form-label">Catalog visibility</label>
                                                    <div class="position-relative">
                                                        <select class="select2 form-select select2-hidden-accessible" name="type" value={productInformation.catalog_visibility}
                                                            onChange={(e) => {
                                                                saveProductInformation('catalog_visibility', e.target.value)
                                                            }}
                                                        >
                                                            <option value="visible">Shop and search results</option>
                                                            <option value="catalog">Shop only</option>
                                                            <option value="search">Search results only</option>
                                                            <option value="hidden">Hidden</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="admin-card mb-6">
                                            <div class="admin-card-header d-flex justify-content-between align-items-center">
                                                <h5 class="mb-0 card-title">Featured Image</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                {
                                                    Object.keys(featureImage).length ?
                                                        <div>
                                                            <img src={featureImage.url} />
                                                            <a className="remove_featured_image mt-4 d-inline-block" href="#" onClick={handleRemoveFeaturedImage}>Remove featured image</a>
                                                        </div> : null
                                                }
                                                <Uploader onSuccess={(uploadedImageData) => {
                                                    if (uploadedImageData?.status == 'success' && uploadedImageData?.uploads?.length) {
                                                        setFeaturedImage(uploadedImageData?.uploads[0]);
                                                    }
                                                }} action="https://staging.tonyetrade.com/uploader.php" draggable>
                                                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <span>Click or Drag image here to upload</span>
                                                    </div>
                                                </Uploader>
                                            </div>
                                        </div>
                                        <div class="admin-card mb-6">
                                            <input type="hidden" name="gallery" value={productInformation.gallery} />
                                            <input type="hidden" name="featured_image" value={productInformation.featured_image} />
                                            <div class="admin-card-header d-flex justify-content-between align-items-center">
                                                <h5 class="mb-0 card-title">Product Gallery</h5>
                                            </div>
                                            <div class="admin-card-body">
                                                {
                                                    productGallery?.length ?
                                                        <div className="product_gallery_wrapper d-flex flex-wrap gap-4 mb-5">
                                                            {
                                                                productGallery.map(gallery => {
                                                                    return <div className="product_gallery_single col-3">
                                                                        <img src={gallery.url} />
                                                                    </div>
                                                                })
                                                            }
                                                        </div> : null
                                                }
                                                <Uploader action="https://staging.tonyetrade.com/uploader.php"
                                                    draggable
                                                    multiple={true}
                                                    onSuccess={(uploadedImageData) => {
                                                        if (uploadedImageData?.status == 'success' && uploadedImageData?.uploads?.length) {
                                                            setProductGallery(prevGallery => [
                                                                ...prevGallery,
                                                                uploadedImageData.uploads[0]
                                                            ]);
                                                            // setFeaturedImage(uploadedImageData?.uploads[0]);
                                                        }
                                                    }}
                                                >
                                                    <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <span>Click or Drag images here to upload</span>
                                                    </div>
                                                </Uploader>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div >
    </div >
}
export default AddProduct;