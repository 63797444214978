import { Link } from 'react-router-dom';
import '../Sell/sell.css'
import React, { useEffect, useState } from "react";
import dollarnote from '../../../src/assets/images/dollars_note.png'
import hands from '../../../src/assets/images/hands.png'
import videocover from '../../../src/assets/images/videocover.png'
import ModalVideo from 'react-modal-video';
import { scrollToTop } from '../../utils/utils';

const Sell = () => {
    const [isOpen, setOpen] = useState(false);
    useEffect(()=>{
        scrollToTop()
    },[])
    return (
        <>
            <div className="single_post_header flex">
                <div className="container">
                    <h2 className="entry-title">
                        WE BUY YOUR CARDS
                    </h2>
                </div>
            </div>
            <div className="container pt-50">
                <div className="card_section wrap-small">
                    <div className="card sell full-width-small">
                        <div className="card_upper small">
                            <h2 className="card_heading">WE PAY TOP DOLLAR!</h2>
                            <p className="card_text">TonyeTrade pays top dollar to collectors and dealers for rare sports collectibles.</p>
                        </div>
                        <div className='card_image'>
                            <img src={dollarnote} loading='lazy' className='full-width' />
                        </div>
                        <div className="card_lower">
                            <p className="card_text2 pb-30" >
                                We buy large and small sports card collections and as a convenience can travel to your location for deals involving larger collections or those that require specialized arrangements. All transactions are confidential and all payment terms negotiable.
                            </p>
                            <div className='text-center'>
                                <Link className="card-button" to={`/the-most-wanted-list/`}>SEE WHAT WE'RE LOOKING FOR</Link>
                            </div>
                        </div>
                    </div>
                    <div className="card sell full-width-small">
                        <div className="card_upper small">
                            <h2 className="card_heading">WE HANDLE CONSIGMENTS</h2>
                            <p className="card_text pb-27">Although it is rare that we would not buy an entire collection, there are always exceptions.</p>
                        </div>
                        <div className='card_image'>
                            <img src={hands} loading='lazy' className='full-width' />
                        </div>
                        <div className="card_lower">
                            <p className="card_text2">
                                In many instances, particularly those with high-end graded sports cards, Tonyetrade offers consignment. We offer extremely competitive rates for consignment and flexible terms.
                            </p>
                            <div className='text-center'>
                                <Link className="card-button" to={`/contact`}>QUESTIONS? CONTACT US</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-0 pt-0">
                <div className="image-background">
                    <div className="content-area wide">
                        <div className="content_upper">
                            <h2 className="card_heading text-white text-center">NO ONE OUT BIDS US! SEE THE PROOF</h2>
                        </div>
                        <div className='video_popup_wrapper relative'>
                            <div className='video_popup_image_wrapper'>
                                <div className='video_popup_image_cover'>
                                    <img src={videocover} loading='lazy' />
                                </div>
                            </div>
                            <div className='video_popup_action_wrapper text-center'>
                                <ModalVideo
                                    channel="youtube"
                                    youtube={{ mute: 0, autoplay: 1 }}
                                    isOpen={isOpen}
                                    videoId="w7APMY7CXCo"
                                    onClose={() => setOpen(false)} 
                                />
                                <button className='watch_video' onClick={()=>{
                                    setOpen(true)
                                }}>
                                    WATCH VIDEO
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sell_card_section'>
                <div className='container flex wrap pb-0 wrap-small'>
                    <div className='left flex-60 pr-40 full-width-small'>
                        <h3 className='mt-0 mb-10'>
                            NEED AN APPRAISAL OR WANT TO SELL A CARD ?
                        </h3>
                        <h4 className='mt-0 mb-20'>
                            TonyeTrade offers appraisals and information for many vintage collections, individual cards and rare piece of memorabilia.
                        </h4>
                        <p className="mt-0 mb-20">
                            We also offer clients a variety of options for selling cards including: online web site sales, shows, Ebay sales, auctions, publications, client lists and many other resources.
                        </p>
                        <p className="mt-0 mb-20">
                            TonyeTrade has such a high success rate selling cards that other dealers often utilize our resources to sell their cards.
                        </p>
                    </div>
                    <div className='right flex-37 full-width-small'>
                        <div className='sell_card_box'>
                            <h5 className='mt-0 mb-10'>
                                WE WILL BUY YOUR PRE 1969 COLLECTION !
                            </h5>
                            <p>
                                For more than anyone else or pay you $100 cash!
                            </p>
                            <Link to={`/contact`} className="letstalk">
                                LET'S TALK
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sell