import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { Uploader } from "rsuite";
import axios from 'axios';
import Papa from 'papaparse';
import { processEbayLineData, startEbayBulkImporterBatchFile } from "../../api/adminapi";

const Bulk_Importer = () => {

    const [file, setFile] = useState(null);
    const [data, setData] = useState([]);
    const [processedLines, setProcessedLines] = useState([]);
    const [processStarted, setProcessStarted] = useState(false)
    const [batchFile, setBatchFile] = useState(undefined)
    const [currentSKU, setCurrentSKU] = useState('')

    useEffect(()=>{
        setFile(null)
        setData([])
        setProcessedLines([])
        setProcessStarted(false)
        setBatchFile(undefined)
        setCurrentSKU('')
    },[])

    useEffect(() => {
        if (file && file?.name) {
            processFile()
        }
    }, [file])

    const handleFileDrop = (e) => {
        const droppedFile = e.blobFile;
        if (droppedFile && isValidFile(droppedFile)) {
            setFile(droppedFile);
        } else {
            alert('Please select a valid CSV file.');
        }
    }

    const isValidFile = (file) => {
        const extension = file.name.split('.').pop().toLowerCase();
        return extension === 'csv';
    };

    const processFile = () => {
        if (!file) {
            alert('Please select a valid csv file first.');
            return;
        }
        Papa.parse(file, {
            complete: async (result) => {
                const csvData = result.data;
                setData(csvData);
                // handleFileData(csvData);
            },
        });
    };

    const handleFileData = async (csvData) => {
        let _bFile = ''
        if (!batchFile) {
            await startEbayBulkImporterBatchFile().then(response => {
                if (response.status == 200 && response.data.success) {
                    _bFile = response.data.batch_file
                    setBatchFile(_bFile)
                }
            }).catch(err => {

            })
        }
        let index = 1;
        for (let i = 0; i < csvData.length; i++) {
            if (csvData[i][0] === 'sku' || csvData[i][0] === '') continue;
            const line = {
                sku: csvData[i][0],
                categories: csvData[i][1],
                qty: csvData[i][2],
                team: csvData[i][3],
                sport: csvData[i][4],
                condition: csvData[i][5],
                manufacturer: csvData[i][6],
                year: csvData[i][7],
                name: csvData[i][8],
                description: csvData[i][9],
                price: csvData[i][10],
                cardNumber: csvData[i][11],
                player: csvData[i][12],
                imageFront: csvData[i][13],
                imageBack: csvData[i][14],
                raw: csvData[i][15],
            };
            // Simulating API call
            await processLine(line, _bFile);
        }
    };

    const processLine = async (line, batch_file) => {
        try {
            setCurrentSKU(line?.sku)
            await processEbayLineData(line, batch_file).then(response => {
                if (response.status == 200) {
                    setProcessedLines([
                        ...processedLines,
                        line.sku
                    ])
                }
            })
        } catch (error) {
            console.error('Error processing line', error);
        }
    };

    const handleProcessButton = () => {
        setProcessStarted(true)
        handleFileData(data);
    }

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="admin-card">
                            <div class="card-datatable table-responsive">
                                <h2>Bulk Uploader</h2>
                                {
                                    !file ?
                                        <Uploader accept=".csv" onDrop={handleFileDrop} onUpload={handleFileDrop} action="#" draggable>
                                            <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <div class="uppy-DragDrop-inner">
                                                    <svg aria-hidden="true" focusable="false" class="uppy-c-icon uppy-DragDrop-arrow" width="16" height="16" viewBox="0 0 16 16">
                                                        <path d="M11 10V0H5v10H2l6 6 6-6h-3zm0 0" fillRule="evenodd"></path>
                                                    </svg>
                                                    <div class="uppy-DragDrop-label">Drop files here or <span class="uppy-DragDrop-browse">browse</span></div><span class="uppy-DragDrop-note"></span></div>
                                            </div>
                                        </Uploader> : (
                                            !processStarted ?
                                                <button onClick={handleProcessButton}>Start Processing {file?.name}</button> : <div>
                                                    <button disabled={!(file && processStarted && processedLines.length == (data.length - 1))}>Prepare Selected Products for Amazon</button>
                                                </div>
                                        )
                                }
                                {
                                    file && processStarted && processedLines.length != (data.length - 1) ?
                                        <>
                                            <div>
                                                Do not close this tab
                                            </div>
                                            <div>
                                                {processedLines.length} / {data?.length ? data.length - 1 : 0} Processed
                                            </div>
                                        </> : null
                                }
                                {
                                    processedLines.length == (data.length - 1) ?
                                        <div>
                                            All done. Now you can close this tab
                                        </div> : null
                                }
                                {
                                    file && processStarted && currentSKU && processedLines.length != (data.length - 1) ?
                                        <div>
                                            Processing: {currentSKU}
                                        </div> : null
                                }
                            </div>
                        </div>
                        {
                            data && data?.length ?
                                <div class="admin-card mt-5">
                                    <div class="card-datatable table-responsive">
                                        <table class="datatables-order table border-top">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input type="checkbox" />
                                                    </th>
                                                    <th>#</th>
                                                    <th>SKU</th>
                                                    <th>Description</th>
                                                    <th>Name</th>
                                                    <th>Price</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data?.map((d, index) => {
                                                        if ('sku' != d[0] && '' != d[0]) {
                                                            return <tr key={index}>
                                                                <td>
                                                                    <input type="checkbox" />
                                                                </td>
                                                                <td>
                                                                    {index}
                                                                </td>
                                                                <td>
                                                                    {d[0]}
                                                                </td>
                                                                <td>
                                                                    {d[9]}
                                                                </td>
                                                                <td>
                                                                    {d[8]}
                                                                </td>
                                                                <td>
                                                                    {d[10]}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        !processedLines?.includes(d[0]) ?
                                                                            (
                                                                                processStarted ?
                                                                                    <div className="processing"></div> : <span>Not Started</span>
                                                                            ) : (
                                                                                <span>Done</span>
                                                                            )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div> : null
                        }
                    </div>
                </div>

            </div>

        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div >
}

export default Bulk_Importer;