import { useLocation } from 'react-router-dom'
import { ReactComponent as Filter } from '../../assets/svgs/filter.svg'
import { ReactComponent as Menubar } from '../../assets/svgs/menubar.svg'
import { ReactComponent as Usercard } from '../../assets/svgs/usercard.svg'
import he from 'he'
import { useState, useCallback } from 'react'

const SidebarFilters = ({ filters, isOpen, isFilteringProducts, priceFilters, setAdvancedFiltersOpened, handlePaginationChange, pagination, handleFilterChange, RenderSingleProductCard, products }) => {

    const [layout, setLayout] = useState('grid')
    const handleLayoutChange = (event) => {
        event.preventDefault()
        setLayout(event.currentTarget.getAttribute('data-type'))
    }
    const location = useLocation()
    const renderFilterSection = useCallback((filterKey, filterName) => {
        if (filters?.[filterKey]?.length > 0) {
            return (
                <div id={`filter_${filterKey}`} className="advanced_filter" data-filter={filterKey}>
                    <h2 className="heading">{filterName}</h2>
                    <div className="inner">
                        {filters[filterKey].map(m => (
                            <label key={m.id}>
                                <input value={m.id} type="checkbox" onChange={(event) => handleFilterChange(filterKey, event.target.value, event.target.checked)} />
                                {he.decode(m.name)} <span className="checkmark"></span>
                            </label>
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    }, [filters, handleFilterChange]);

    return <div className={`products_wrapper_outer flex ${isOpen ? 'sidebar_filter_opened' : ''} ${isFilteringProducts ? 'processing' : ''} `}>
        <div id="advanced_filters">
            {renderFilterSection('manufacturer', 'Manufacturer')}
            {renderFilterSection('team', 'Team')}
            {renderFilterSection('sport', 'Sport')}
            {renderFilterSection('player', 'Player')}
            <div id="filter_price" className="advanced_filter" data-filter="price">
                <h2 className="heading">Price</h2>
                <div className="inner">
                    {
                        priceFilters?.map(p => {
                            return (
                                <label>
                                    <input value={p?.val} type="checkbox" name="filter_price" onChange={(event) => {
                                        handleFilterChange('price', event.target.value, event.target.checked)
                                    }} /> {p?.label} <span className="checkmark"></span>
                                </label>
                            )
                        })
                    }
                    <div className="grouped custom_price">
                        <div className="single_custom_price">
                            <span>$</span>
                            <input type="number" name="min_price" placeholder="Min" />
                        </div>
                        <div className="single_custom_price">
                            <span>$</span>
                            <input type="number" name="max_price" placeholder="Max" />
                        </div>
                        <button id="apply_c_price">Apply</button></div>
                </div>
            </div>
            {renderFilterSection('grade', 'Grade')}
            {renderFilterSection('year', 'Year')}
        </div>
        <div className={`products_wrapper_inner ${layout}`}>
            <div className="shop_container">
                <div className="products_filter_header_wrapper">
                    <div className='left'>
                        <a id="adv_filter_opener" href="#" onClick={event => {
                            event.preventDefault()
                            setAdvancedFiltersOpened(!isOpen)
                        }}>
                            <Filter />
                        </a>
                        <span className='left_side_results_holder'>
                            <span className='number'>{pagination?.totalPosts}</span> Results
                        </span>
                    </div>
                    <div className='middle'>
                        <div className='custom_product_sorter'>

                        </div>
                    </div>
                    <div className='right'>
                        <a href="#" data-type="list" className={`${layout == 'list' ? 'active' : ''}`} onClick={event => {
                            handleLayoutChange(event)
                        }}>
                            <Menubar />
                        </a>
                        <a href="#" data-type="grid" className={`${layout == 'grid' ? 'active' : ''}`} onClick={event => {
                            handleLayoutChange(event)
                        }}>
                            <Usercard />
                        </a>
                    </div>
                </div>
                {
                    products?.length > 0 ?
                        <div className="products_wrapper">
                            {
                                products.map(product => {
                                    return (
                                        <RenderSingleProductCard key={product?.id} product={product} />
                                    )
                                })
                            }
                        </div> : null
                }
            </div>
            {
                pagination?.totalPages > 1 ?
                    <>
                        <div id="infinite" className='processing'></div>
                        <div className='shop_pagination'>
                            <ul id="shop_pagination" className='flex gap-20 wrap-small'>
                                {
                                    Array.from({ length: pagination?.totalPages }, (_, index) => {
                                        const searchParams = new URLSearchParams(location.search);
                                        const currentPage = searchParams.get('page') || 1;
                                        return (
                                            <li key={index} className={`${index + 1 == currentPage ? 'active' : ''}`} onClick={() => {
                                                handlePaginationChange(index)
                                            }}>
                                                {index + 1}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    </> : null
            }
        </div>
    </div>
}

export default SidebarFilters;