import '../Home/home.css'
import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { scrollToTop } from '../../utils/utils';
import DocumentTitle from '../../utils/DocumentTitle';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as Baseball } from '../../assets/svgs/baseball.svg';
import { ReactComponent as Basketball } from '../../assets/svgs/basketball.svg';
import { ReactComponent as Boxing } from '../../assets/svgs/boxing.svg';
import { ReactComponent as Comics } from '../../assets/svgs/comics.svg';
import { ReactComponent as Football } from '../../assets/svgs/football.svg';
import { ReactComponent as Hockey } from '../../assets/svgs/hockey.svg';
import { ReactComponent as Memo } from '../../assets/svgs/memo.svg';
import { ReactComponent as NonSport } from '../../assets/svgs/nonsport.svg';
import { ReactComponent as Unopened } from '../../assets/svgs/unopened.svg';
import { ReactComponent as Sets } from '../../assets/svgs/sets.svg';
import { ReactComponent as Next } from '../../assets/svgs/next.svg';
import { ReactComponent as Prev } from '../../assets/svgs/prev.svg';
import Stars from '../../assets/svgs/stars.svg';
import Placeholder from '../../components/Placeholder';

const decades1 = "https://res.cloudinary.com/dzhclyn7c/image/upload/v1725523866/decades_1.3aec967c50a2357a6c0d_1_wzfgvy.png";
const decades2 = "https://res.cloudinary.com/dzhclyn7c/image/upload/v1725523941/decades_2.6f9beb2cbccd1264d862_ayhjui.png";
const decades3 = "https://res.cloudinary.com/dzhclyn7c/image/upload/v1725523985/decades_3.264cd2145c24f3038fc2_djfbbr.png";
const decades4 = "https://res.cloudinary.com/dzhclyn7c/image/upload/v1725524018/decades_4.8c8c8b610a81280baf3f_1_s5kaki.png";
const wanted1 = "https://res.cloudinary.com/dzhclyn7c/image/upload/v1725524084/wanted_1.aef4fcc654bb2d9318fe_cy38r1.png";
const wanted2 = "https://res.cloudinary.com/dzhclyn7c/image/upload/v1725524118/wanted_2.9a6504c7c04eaaef485c_ho0d59.png";
const image = "https://res.cloudinary.com/dzhclyn7c/image/upload/v1725524214/image_daz7w6.png";

const NewsletterForm = lazy(() => import('../../components/NewsletterForm'));
const ProductCard = lazy(() => import('../../components/ProductCard'));

const NextArrow = React.memo(({ className, onClick }) => (
    <div className={`${className} custom_arrow`} onClick={onClick}>
        <Next />
    </div>
));

const PrevArrow = React.memo(({ className, onClick }) => (
    <div className={`${className} custom_arrow`} onClick={onClick}>
        <Prev />
    </div>
));
const Home = ({ title }) => {
    if (title) {
        DocumentTitle(title);
    }
    const [products, setProducts] = useState(undefined)
    const getProducts = async () => {

        const cachedProducts = sessionStorage.getItem('featuredProducts');
        if (cachedProducts) {
            setProducts(JSON.parse(cachedProducts));
            return;
        }

        try {
            axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `homefeaturedproducts`).then(res => {
                if (res?.data?.products && Array.isArray(res?.data?.products) && res?.data?.products?.length > 0) {
                    setProducts(res?.data?.products)
                    sessionStorage.setItem('featuredProducts', JSON.stringify(res.data.products))
                }
            }).catch(err => {

            })
        } catch (error) {

        }

    }
    useEffect(() => {
        setProducts(undefined)
        scrollToTop()
        getProducts()
    }, [])

    const featuredSliderSettings = useMemo(() => ({
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 3 } },
            { breakpoint: 600, settings: { slidesToShow: 2 } },
            { breakpoint: 500, settings: { slidesToShow: 1 } }
        ],
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }))

    return (
        <div className="home_page_wrapper">
            <div className='home_hero_section bg-primary pl-15 pr-15 pt-0-small'>
                <div className='container mid mt-0 flex block-small'>
                    <div className='flex-50 mt-40 pt-10'>
                        <h2 className='hero_heading mt-0 mb-20 text-white'>
                            Welcome to TonyeTrade
                        </h2>
                        <p className='hero_desc mt-0 mb-30 text-white pr-0-small'>
                            Welcome to TonyeTrade, where today’s savvy collector can buy and sell baseball cards, sports memorabilia, vintage toys and other collectibles such as rare comic books.
                        </p>
                        <div className='hero_cta flex wrap align-center gap-15'>
                            <Link className='shop_inventory inline-block' to={`/shop`}>
                                Shop Inventory
                            </Link>
                            <div className='hero_starts flex gap-5'>
                                <img loading='lazy' src={Stars} />
                            </div>
                            <span>from 100+ reviews</span>
                        </div>
                    </div>
                    <div className='flex-50'>
                        <div className='hero_images relative'>
                            <img src={decades1} loading='lazy' className='absolute inline-block' />
                            <img src={decades2} loading='lazy' className='absolute inline-block' />
                            <img src={decades3} loading='lazy' className='absolute inline-block' />
                            <img src={decades4} loading='lazy' className='absolute inline-block' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='category_section'>
                <div className='container mid'>
                    <div className='home_categories'>
                        <div className='home_categories_inner p-10-small'>
                            <ul className='m-0 flex wrap align-center justify-center'>
                                <li>
                                    <Link to={`/product-category/inventory/baseball`}>
                                        <Baseball />
                                        Baseball
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/product-category/inventory/boxing`}>
                                        <Boxing />
                                        Boxing
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/product-category/inventory/hockey`}>
                                        <Hockey />
                                        Hockey
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/product-category/inventory/non-sport`}>
                                        <NonSport />
                                        Non-Sport
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/product-category/inventory/un-opened`}>
                                        <Unopened />
                                        Un-opened
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/product-category/inventory/basketball`}>
                                        <Basketball />
                                        Basketball
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/product-category/inventory/football`}>
                                        <Football />
                                        Football
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/product-category/inventory/comics`}>
                                        <Comics />
                                        Comics
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/product-category/inventory/memorabilia`}>
                                        <Memo />
                                        Memorabilla
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/product-category/inventory/sets`}>
                                        <Sets />
                                        Sets
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='newsletter_section'>
                <div className='container mid mt-0 pt-0 pb-0-small'>
                    <Suspense fallback={''}>
                        <NewsletterForm />
                    </Suspense>
                </div>
            </div>
            <div className='featured_section'>
                <div className='container mid mt-0 pt-0 pb-0-small'>
                    <div className='featured_section_header flex space-between align-center p-10 pl-0 pr-0'>
                        <div className='left'>
                            <h3 className='featured_heading m-0'>
                                Featured Items
                            </h3>
                        </div>
                        <div className='right'>
                            <Link className='view_all_featured' to={`/featured`}>
                                VIEW ALL
                            </Link>
                        </div>
                    </div>
                    <div className='featured_items_wrapper p-10'>
                        {
                            typeof products !== 'undefined' ? (
                                products?.length > 0 ?
                                    <div className='home_featured_products slider-container flexs wrap-smalls'>
                                        <Slider {...featuredSliderSettings}>
                                            {
                                                products?.map(product => {
                                                    return (
                                                        <Suspense fallback={<div>Loading...</div>}>
                                                            <ProductCard key={product?.id} product={product} />
                                                        </Suspense>
                                                    )
                                                })
                                            }
                                        </Slider>
                                    </div> : <p className='text-center'>No featured products found!</p>
                            ) : (
                                <Placeholder />
                            )
                        }
                    </div>
                </div>
            </div>
            <div className='wanted_section pb-30-small'>
                <div className='container mid flex wrap-small'>
                    <div className='wanted_col flex-50 p-10 full-width-small'>
                        <img src={wanted1} loading='lazy' className='mb-20' />
                        <h4 className='wanted_subtitle mb-10 mt-0'>
                            TONYETRADE
                        </h4>
                        <h3 className='wanted_heading mt-0 mb-10'>
                            No One Out Bids Us
                        </h3>
                        <p className='wanted_desc mt-0 mb-20'>
                            We pay top dollar to collections or dealers for collections. We have the means to buy virtually any collection and we can travel to you the following day for large collections. All transactions are confidential and can be made with any form of payment.
                        </p>
                        <Link to={`/shop`} className='shop_inventory inline-block'>
                            Watch the Proof
                        </Link>
                    </div>
                    <div className='wanted_col flex-50 p-10 full-width-small'>
                        <img src={wanted2} loading='lazy' className='mb-20' />
                        <h4 className='wanted_subtitle mb-10 mt-0'>
                            TONYETRADE
                        </h4>
                        <h3 className='wanted_heading mt-0 mb-10'>
                            Most wanted List
                        </h3>
                        <p className='wanted_desc mt-0 mb-20'>
                            TonyeTrade routinely spends millions of dollars each year to purchase collections of sports cards and other collectibles, which puts us in a position of experience for offering information and advice
                        </p>
                        <Link to={`/shop`} className='shop_inventory inline-block'>
                            See what we’re looking for
                        </Link>
                    </div>
                </div>
            </div>
            <div className='decades_section bg-primary'>
                <div className='container mid flex block-small'>
                    <div className='decades_left flex flex-50'>
                        <div className='decades_left_inner p-10 mt-0-small'>
                            <h3 className='decades_heading text-white mb-20 mt-0 pr-0-small'>
                                3 Decades of Collecting
                            </h3>
                            <p className='decades_desc mb-20 text-white mt-0 pr-0-small'>
                                Over more than three decades, TonyeTrade has become one of the most notable and respected figures in the hobby. Today, TonyeTrade handles some of the finest examples of sports trading cards and other items, and every year buys and sells millions of dollars of individual pieces and collections.
                            </p>
                            <Link to={`/shop`} className='shop_inventory inline-block'>
                                Shop Inventory
                            </Link>
                        </div>
                    </div>
                    <div className='decades_right flex-50'>
                        <div className='decades_images relative'>
                            <img src={decades1} loading='lazy' className='absolute inline-block' />
                            <img src={decades2} loading='lazy' className='absolute inline-block' />
                            <img src={decades3} loading='lazy' className='absolute inline-block' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='notable_section bg-primary pb-50'>
                <div className="container mid flex mt-0 wrap-small reverse-column">
                    <div className='notable_left flex flex-30 align-center p-10 full-width-small'>
                        <img loading='lazy' src={image} />
                    </div>
                    <div className='notable_right flex-70 pl-80 pr-10 pb-10 pt-10 full-width-small pr-10-small pl-10-small'>
                        <h3 className='text-white notable_heading mt-0 mb-20 pr-0-small'>
                            Notable baseball Card and Comic Book Sales
                        </h3>
                        <p className='text-white notable_desc mb-20 mt--5 pr-0-small'>Over more than three decades, TonyeTrade has become one of the most notable and respected figures in the hobby. Today, TonyeTrade handles some of the finest examples of sports trading cards and other items, and every year buys and sells millions of dollars of individual pieces and collections.1952 Topps PSA 9 Mickey Mantle</p>
                        <ul className='text-white flex wrap notable_list m-0'>
                            <li>1953 topps PSA 10 Mickey Mantle</li>
                            <li>Three T206 Honus Wagner’s</li>
                            <li>PSA 8 E90-1 Joe Jackson Rookie</li>
                            <li>The only Four base hits King Kelly</li>
                            <li>The only complete Joseph Hall team cabinet collection</li>
                            <li>The only whiz bang Babe Ruth</li>
                            <li>PSA 7 E101 Honus Wagner</li>
                            <li>PSA 8 1915 Cracker Joe Jackson</li>
                            <li>PSA 7 and 8 1915 Sporting News Babe Ruth Rookie</li>
                            <li>CGC 9.6, 9.4 Spider-Man 1</li>
                            <li>CGC 4.5, 3.0, 5.5 Superman 1</li>
                            <li>CGC 7.0, 6.0 Batman 1</li>
                            <li>CGC 9.0, 8.5 Incredible Hulk 1</li>
                            <li>And the list goes on and on...</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="partner_with_us_section p-10-small">
                <div className="container mid">
                    <h4 className="section_title text-center">
                        Partner with Us
                    </h4>
                    <div className="partner_with_us_wrapper flex gap_30 wrap-small">
                        <div className="single_partner full-width-small">
                            <h5>
                                Buy Baseball Cards
                            </h5>
                            <p>
                                TonyeTrade continually handles some of the most sought-after sports collectibles and cards, as well as hard-to-find items such as vintage comic books. With our remarkable inventory and relationships with top collectors throughout the country, TonyeTrade is your source for even the rarest of must-have items.
                            </p>
                        </div>
                        <div className="single_partner full-width-small">
                            <h5>
                                Sell Baseball Cards
                            </h5>
                            <p>
                                TonyeTrade is proud to have earned a reputation for honesty and paying top dollar over more than 30 years in the hobby. Our considerable liquid assets let us purchase even the largest and most valuable collections and items, and TonyeTrade also offers consignment at extremely competitive rates and flexible terms.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home