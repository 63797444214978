import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { priceFilters, scrollToTop } from '../../utils/utils'
import SearchBar from '../../components/Searchbar'
import ProductCard from '../../components/ProductCard'
import SidebarFilters from '../../components/SidebarFilters'
import Placeholder from '../../components/Placeholder'

const RenderSingleProductCard = React.memo(({ product }) => <ProductCard product={product} />);

const Year = () => {
    let { yearslug } = useParams()
    const [products, setProducts] = useState([])
    const [headertitle, setHeaderTitle] = useState('')
    const [paginationLevel, setPaginationLevel] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    const [pagination, setPagination] = useState({
        totalPages: 0,
        currentPage: 1,
        totalPosts: 0,
    })
    const [filters, setFilters] = useState(null)
    const [taxQuery, setTaxQuery] = useState(undefined)
    const [advacnedFiltersOpened, setAdvancedFiltersOpened] = useState(false)

    const [activeFilters, setActiveFilters] = useState({
        manufacturer: [],
        team: [],
        sport: [],
        player: [],
        price: [],
        grade: [],
        year: [],
    })

    const getFilters = useCallback(async (query) => {
        axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `getfilters?query=${query}`).then(res => {
            if (res?.status == 200 && res?.data?.results && Array.isArray(res?.data?.results) && res?.data?.results?.length > 0) {
                const filtersInner = {
                    manufacturer: [],
                    team: [],
                    sport: [],
                    price: [],
                    year: [],
                }
                res?.data?.results.map(result => {
                    if (undefined != filtersInner[result?.facet_name]) {
                        const existingItem = filtersInner[result?.facet_name].find(item => item.id === result?.term_id);
                        if (!existingItem) {
                            filtersInner[result?.facet_name]?.push({
                                name: result?.facet_display_value,
                                id: result?.term_id,
                            });
                        }
                    }
                })
                setFilters(filtersInner)
            }
        }).catch(err => {

        })
    })

    const getProducts = useCallback(async (currentPage = 1) => {
        setPagination({
            ...pagination,
            currentPage: currentPage
        })
        let lastPagination = ''
        if (yearslug == undefined) {
            setHeaderTitle('Inventory')
        } else if (yearslug !== undefined) {
            lastPagination = `${yearslug?.replaceAll('-', ' ')} Sets`
            setHeaderTitle(lastPagination)
            setPaginationLevel(lastPagination)
        }

        if (yearslug?.length) {

            const queryParams = new URLSearchParams({
                page: currentPage,
                taxQuery: JSON.stringify(taxQuery),
                taxonomy: 'c_year'
            })

            axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `products/${yearslug}/?${queryParams}`).then(res => {
                if (res?.status == 200 && res?.data?.products && Array.isArray(res?.data?.products) && res?.data?.products?.length) {
                    const productsInner = res?.data
                    setProducts(productsInner?.products)
                    setPagination({
                        ...pagination,
                        totalPages: productsInner?.total_pages,
                        totalPosts: productsInner?.total_posts,
                    })
                    if (typeof productsInner?.tax_query !== 'undefined' && Array.isArray(productsInner?.tax_query) && productsInner?.tax_query?.length > 0) {
                        setTaxQuery(productsInner?.tax_query)
                    }
                    if (productsInner?.query) {
                        getFilters(productsInner?.query)
                    }
                }
            }).catch(err => {
                
            }).finally(() => {

            })
        }
    })

    useEffect(() => {
        getProducts()
        scrollToTop()
    }, [navigate])

    const handlePaginationChange = useCallback(async (index) => {
        scrollToTop()
        const newPage = index + 1
        setPagination({
            ...pagination,
            currentPage: newPage,
        })
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', newPage);
        const newSearchString = searchParams.toString();
        if (newPage > 1) {
            navigate(location.pathname + `?${newSearchString}`, { replace: true })
        } else {
            navigate(location.pathname, { replace: true })
        }
        getProducts(newPage)
    }, [location.pathname, location.search, getProducts])
    const [isFilteringProducts, setIsFilteringProducts] = useState(false)

    const filterProducts = useCallback(async () => {
        const queryParams = new URLSearchParams({
            taxQuery: JSON.stringify(taxQuery)
        });
        axios.post(process.env.REACT_APP_TONYETRADE_API_BASE + `getfilteredproducts?${queryParams}`, {
            filters: activeFilters,
        }).then(res => {

            const productsInner = res?.data
            if (productsInner && typeof productsInner !== 'undefined' && Array.isArray(productsInner?.products)) {
                setProducts(productsInner?.products)
                setPagination({
                    ...pagination,
                    totalPages: productsInner?.total_pages,
                    totalPosts: productsInner?.total_posts,
                })
                if (typeof productsInner?.tax_query !== 'undefined' && Array.isArray(productsInner?.tax_query) && productsInner?.tax_query?.length > 0) {
                    setTaxQuery(productsInner?.tax_query)
                }
            }

        }).catch(err => {
            
        })
    })

    const handleFilterChange = (type, value, isChecked) => {
        let updatedFilters = { ...activeFilters };
        if (isChecked) {
            if (!updatedFilters[type].includes(value)) {
                updatedFilters[type] = [...updatedFilters[type], value];
            }
        } else {
            updatedFilters[type] = updatedFilters[type].filter(item => item !== value);
        }
        setActiveFilters(updatedFilters);
        filterProducts()
    }

    return (
        <>
            <div className="single_page_header_wrapper">
                <div className="container wide">
                    <div className="single_page_breadcrumb">
                        <ul className="text-white m-0 flex gap-20">
                            <li className="mb-0 small">
                                <Link to={`/shop`}>
                                    Inventory
                                </Link>
                            </li>
                            {paginationLevel?.length > 0 ?
                                <li className="mb-0">
                                    {paginationLevel}
                                </li> : null}

                        </ul>
                    </div>
                    <h3 className="single_page_header_title">
                        {headertitle}
                    </h3>
                </div>
            </div>
            <SearchBar />
            {
                products && products?.length ?
                    <SidebarFilters products={products} handleFilterChange={handleFilterChange} RenderSingleProductCard={RenderSingleProductCard} pagination={pagination} handlePaginationChange={handlePaginationChange} setAdvancedFiltersOpened={setAdvancedFiltersOpened} priceFilters={priceFilters} filters={filters} isOpen={advacnedFiltersOpened} isFilteringProducts={isFilteringProducts} /> : null
            }
            {
                products?.length < 1 ?
                    <Placeholder count={6} /> : null
            }
        </>
    )
}

export default Year