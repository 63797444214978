import React from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";

const Categories = () => {
    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">

            <AdminSidebar />

            <div class="layout-page">

                <AdminTopbar />

                <div class="content-wrapper">

                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="app-ecommerce-category">

                            <div class="admin-card">
                                <div class="card-datatable table-responsive">
                                    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                                        <div class="admin-card-header d-flex flex-wrap py-0 flex-column flex-sm-row">
                                            <div>
                                                <div id="DataTables_Table_0_filter" class="dataTables_filter me-3 mb-sm-6 mb-0 ps-0">
                                                    <label>
                                                        <input type="search" class="form-control ms-0" placeholder="Search Category" aria-controls="DataTables_Table_0" />
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="d-flex justify-content-center justify-content-md-end align-items-baseline">
                                                <div class="dt-action-buttons d-flex justify-content-center flex-md-row align-items-baseline pt-0">
                                                    <div class="dataTables_length" id="DataTables_Table_0_length">
                                                        <label>
                                                            <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" class="form-select ms-0">
                                                                <option value="7">7</option>
                                                                <option value="10">10</option>
                                                                <option value="20">20</option>
                                                                <option value="50">50</option>
                                                                <option value="70">70</option>
                                                                <option value="100">100</option>
                                                            </select>
                                                        </label>
                                                    </div>
                                                    <div class="dt-buttons btn-group flex-wrap">
                                                        <button class="btn btn-secondary add-new btn-primary ms-2 waves-effect waves-light" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEcommerceCategoryList">
                                                            <span>
                                                                <i class="ti ti-plus ti-xs me-0 me-sm-2"></i>
                                                                <span class="d-none d-sm-inline-block">Add Category</span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table class="datatables-category-list table border-top dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                            <thead>
                                                <tr>
                                                    <th class="control sorting_disabled dtr-hidden" rowspan="1" colspan="1" style={{
                                                        width: 43.9453 + 'px',
                                                        display: 'none',
                                                    }} aria-label=""></th>
                                                    <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" rowspan="1" colspan="1" style={{
                                                        width: 36.6172 + 'px',
                                                    }} data-col="1" aria-label="">
                                                        <input type="checkbox" class="form-check-input" />
                                                    </th>
                                                    <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                        width: 202.508 + 'px',
                                                    }} aria-label="Categories: activate to sort column ascending">Categories</th>
                                                    <th class="text-nowrap text-sm-end sorting sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1" style={{
                                                        width: 279.289 + 'px',
                                                    }} aria-label="Total Products &amp;nbsp;: activate to sort column descending" aria-sort="ascending">Total Products &nbsp;</th>
                                                    <th class="text-nowrap text-sm-end sorting_disabled" rowspan="1" colspan="1" style={{
                                                        width: 239.711 + 'px',
                                                    }} aria-label="Total Earning">Total Earning</th>
                                                    <th class="text-lg-center sorting_disabled" rowspan="1" colspan="1" style={{
                                                        width: 145.93 + 'px',
                                                    }} aria-label="Actions">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="odd">
                                                    <td valign="top" colspan="5" class="dataTables_empty">No data available in table</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="row mx-1">
                                            <div class="col-sm-12 col-md-6">
                                                <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                                            </div>
                                            <div class="col-sm-12 col-md-6">
                                                <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                                                    <ul class="pagination">
                                                        <li class="paginate_button page-item previous disabled" id="DataTables_Table_0_previous">
                                                            <a aria-controls="DataTables_Table_0" aria-disabled="true" role="link" data-dt-idx="previous" tabindex="-1" class="page-link">
                                                                <i class="ti ti-chevron-left ti-sm"></i>
                                                            </a>
                                                        </li>
                                                        <li class="paginate_button page-item next disabled" id="DataTables_Table_0_next">
                                                            <a aria-controls="DataTables_Table_0" aria-disabled="true" role="link" data-dt-idx="next" tabindex="-1" class="page-link">
                                                                <i class="ti ti-chevron-right ti-sm"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                class="offcanvas offcanvas-end"
                                tabindex="-1"
                                id="offcanvasEcommerceCategoryList"
                                aria-labelledby="offcanvasEcommerceCategoryListLabel">

                                <div class="offcanvas-header py-6">
                                    <h5 id="offcanvasEcommerceCategoryListLabel" class="offcanvas-title">Add Category</h5>
                                    <button
                                        type="button"
                                        class="btn-close text-reset"
                                        data-bs-dismiss="offcanvas"
                                        aria-label="Close"></button>
                                </div>

                                <div class="offcanvas-body border-top">
                                    <form class="pt-0" id="eCommerceCategoryListForm" onsubmit="return true">

                                        <div class="mb-6">
                                            <label class="form-label" for="ecommerce-category-title">Title</label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="ecommerce-category-title"
                                                placeholder="Enter category title"
                                                name="categoryTitle"
                                                aria-label="category title" />
                                        </div>

                                        <div class="mb-6">
                                            <label class="form-label" for="ecommerce-category-slug">Slug</label>
                                            <input
                                                type="text"
                                                id="ecommerce-category-slug"
                                                class="form-control"
                                                placeholder="Enter slug"
                                                aria-label="slug"
                                                name="slug" />
                                        </div>

                                        <div class="mb-6">
                                            <label class="form-label" for="ecommerce-category-image">Attachment</label>
                                            <input class="form-control" type="file" id="ecommerce-category-image" />
                                        </div>

                                        <div class="mb-6 ecommerce-select2-dropdown">
                                            <label class="form-label" for="ecommerce-category-parent-category">Parent category</label>
                                            <select
                                                id="ecommerce-category-parent-category"
                                                class="select2 form-select"
                                                data-placeholder="Select parent category">
                                                <option value="">Select parent Category</option>
                                                <option value="Household">Household</option>
                                                <option value="Management">Management</option>
                                                <option value="Electronics">Electronics</option>
                                                <option value="Office">Office</option>
                                                <option value="Automotive">Automotive</option>
                                            </select>
                                        </div>

                                        <div class="mb-6">
                                            <label class="form-label">Description</label>
                                            <div class="form-control p-0 py-1">
                                                <div class="comment-editor border-0" id="ecommerce-category-description"></div>
                                                <div class="comment-toolbar border-0 rounded">
                                                    <div class="d-flex justify-content-end">
                                                        <span class="ql-formats me-0">
                                                            <button class="ql-bold"></button>
                                                            <button class="ql-italic"></button>
                                                            <button class="ql-underline"></button>
                                                            <button class="ql-list" value="ordered"></button>
                                                            <button class="ql-list" value="bullet"></button>
                                                            <button class="ql-link"></button>
                                                            <button class="ql-image"></button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-6 ecommerce-select2-dropdown">
                                            <label class="form-label">Select category status</label>
                                            <select
                                                id="ecommerce-category-status"
                                                class="select2 form-select"
                                                data-placeholder="Select category status">
                                                <option value="">Select category status</option>
                                                <option value="Scheduled">Scheduled</option>
                                                <option value="Publish">Publish</option>
                                                <option value="Inactive">Inactive</option>
                                            </select>
                                        </div>

                                        <div class="mb-6">
                                            <button type="submit" class="btn btn-primary me-sm-3 me-1 data-submit">Add</button>
                                            <button type="reset" class="btn btn-label-danger" data-bs-dismiss="offcanvas">Discard</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content-backdrop fade"></div>
                </div>

            </div>

        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default Categories