import axios from 'axios';
import '../Searchbar/searchbar.css'
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { getHighlightedSearchString } from '../../utils/utils';
import { ReactComponent as Search } from '../../assets/svgs/search.svg'
import $ from 'jquery'

const closeSearchDropdown = () => {
    const elements = document.getElementsByClassName('dgwt-wcas-search-results');
    for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('loaded');
    }
}

const openSearchDropdown = () => {
    document.getElementsByClassName('dgwt-wcas-search-results')[0].classList.add('loaded');
    const elements = document.getElementsByClassName('dgwt-wcas-search-results');
    for (let i = 0; i < elements.length; i++) {
        if (i != 0)
            elements[i].classList.add('loaded');
    }
}

const SearchBar = () => {
    const [formData, setFormData] = useState({
        query: '',
        dropdown: '',
    })

    const [sports, setSports] = useState([])
    const [grade, setGrade] = useState([])
    const [team, setTeam] = useState([])
    const [year, setYear] = useState([])
    const [manufacturer, setManufacturer] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const handleFormSubmit = useCallback((event) => {
        const query = document.getElementById('custom_search_query')?.value?.trim()
        if (query?.length > 0)
            navigate(`/search/?s=${query}`)
    })

    const getCategories = async () => {
        await axios.get(process.env.REACT_APP_TONYETRADE_API_BASE + `allcategories`).then(res => {
            if (typeof res?.data == 'object' && Object.keys(res?.data).length > 0) {
                if (Array.isArray(res?.data?.sports) && res?.data?.sports?.length > 0) {
                    setSports(res?.data?.sports)
                }
                if (Array.isArray(res?.data?.grade) && res?.data?.grade?.length > 0) {
                    setGrade(res?.data?.grade)
                }
                if (Array.isArray(res?.data?.team) && res?.data?.team?.length > 0) {
                    setTeam(res?.data?.team)
                }
                if (Array.isArray(res?.data?.year) && res?.data?.year?.length > 0) {
                    setYear(res?.data?.year)
                }
                if (Array.isArray(res?.data?.manufacturer) && res?.data?.manufacturer?.length > 0) {
                    setManufacturer(res?.data?.manufacturer)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        getCategories()
        document.addEventListener('click', function (event) {
            const dropdown = document.getElementById('search_form');
            if (dropdown && !dropdown.contains(event.target)) {
                closeSearchDropdown()
            }
        });

        document.addEventListener('click', function (event) {
            const dropdown = document.getElementById('search_form');
            if (dropdown && dropdown.contains(event.target)) {
                openSearchDropdown()
            }
        });

    }, [])

    const [loadedSuggestions, setLoadedSuggestions] = useState(undefined)
    const [isSearching, setIsSearching] = useState(false)
    const [isSearchResultOpened, setIsSearchResultOpened] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [debouncedSearchQuery] = useDebounce(searchQuery, 500)

    useEffect(() => {
        if (debouncedSearchQuery?.length > 2) {

        } else {
            setIsSearchResultOpened(false)
            setLoadedSuggestions([])
        }
    }, [debouncedSearchQuery])

    useEffect(() => {
        window.runSearchInits()
    }, [navigate])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const queryS = searchParams.get('s') || '';
        if (queryS) {
            setSearchQuery(queryS);
        }

    }, [location.search])

    return (
        <div className="search_form_wrapper dgwt-wcas-search-wrapp dgwt-wcas-has-submit woocommerce dgwt-wcas-style-pirx js-dgwt-wcas-layout-classic dgwt-wcas-layout-classic js-dgwt-wcas-mobile-overlay-enabled">
            <form id="search_form" role="search" method="get" className='dgwt-wcas-search-form ss' onSubmit={event => {
                event.preventDefault()
                handleFormSubmit(event)
            }}>
                <div className='dgwt-wcas-sf-wrapp'>
                    <div className="search_form_wrapper_inner">
                        <select id="custom_search_select" value={formData?.dropdown}>
                            <option value="-1">All</option>
                            {
                                sports?.length > 0 ?
                                    <optgroup label="Sports">
                                        {
                                            sports?.map(s => {
                                                return (
                                                    <option value={s?.id}>
                                                        {s?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </optgroup> : null
                            }
                            {
                                grade?.length > 0 ?
                                    <optgroup label="Grade">
                                        {
                                            grade?.map(gr => {
                                                return (
                                                    <option value={gr?.id}>
                                                        {gr?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </optgroup> : null
                            }
                            {
                                team?.length > 0 ?
                                    <optgroup label="Team">
                                        {
                                            team?.map(t => {
                                                return (
                                                    <option value={t?.id}>
                                                        {t?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </optgroup> : null
                            }
                            {
                                year?.length > 0 ?
                                    <optgroup label="Year">
                                        {
                                            year?.map(y => {
                                                return (
                                                    <option value={y?.id}>
                                                        {y?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </optgroup> : null
                            }
                            {
                                manufacturer?.length > 0 ?
                                    <optgroup label="Manufacturer">
                                        {
                                            manufacturer?.map(m => {
                                                return (
                                                    <option value={m?.id}>
                                                        {m?.name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </optgroup> : null
                            }
                        </select>
                        <input id="custom_search_query" className='dgwt-wcas-search-input' type="text" autoComplete="off" name="s" value={searchQuery} onInput={(e) => {
                            setSearchQuery(e.target.value)
                        }} placeholder="Search for products..." />
                        {
                            searchQuery?.length > 0 ?
                                <a class="search_bar_search_closer" onClick={(e)=>{
                                    e.preventDefault();
                                    setSearchQuery('')
                                }} href='#' style={{ color: '#000', fontSize: 22, position: 'absolute', top: 6, right: 80, padding: 10 }}>
                                    &times;
                                </a> : null
                        }
                        <div className={`dgwt-wcas-preloader search_form_preloader ${isSearching ? 'loaded' : ''}`}>
                            <svg className="dgwt-wcas-loader-circular " viewBox="25 25 50 50">
                                <circle className="dgwt-wcas-loader-circular-path" cx="50" cy="50" r="20" fill="none" strokeMiterlimit="10"></circle>
                            </svg>
                        </div>
                        <div className={`dgwt-wcas-search-results ${isSearchResultOpened ? 'loaded' : ''}`}>
                            {
                                typeof loadedSuggestions !== 'undefined' && loadedSuggestions?.length > 0 ?
                                    <ul>
                                        {
                                            loadedSuggestions?.map((suggestion, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link to={`/${suggestion?.slug}`}>
                                                            <span dangerouslySetInnerHTML={{ __html: getHighlightedSearchString(suggestion?.value, searchQuery) }} />
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                    : null
                            }
                            {
                                typeof loadedSuggestions == 'undefined' ?
                                    <ul>
                                        <li>
                                            No results found!
                                        </li>
                                    </ul> : null
                            }
                        </div>
                        <button type="submit" aria-label="Search" onClick={() => {
                            if ($('#custom_search_query').val().trim().length) {
                                $('#search_form').trigger('submit');
                            }
                        }}>
                            <Search />
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default SearchBar