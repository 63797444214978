import React, { useEffect, useState } from "react";
import '../Header/header.css'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAuth } from '../../Hooks/useAuth'
import { ReactComponent as Baseball } from '../../assets/svgs/baseball.svg';
import { ReactComponent as Basketball } from '../../assets/svgs/basketball.svg';
import { ReactComponent as Boxing } from '../../assets/svgs/boxing.svg';
import { ReactComponent as Comics } from '../../assets/svgs/comics.svg';
import { ReactComponent as Football } from '../../assets/svgs/football.svg';
import { ReactComponent as Hockey } from '../../assets/svgs/hockey.svg';
import { ReactComponent as Memo } from '../../assets/svgs/memo.svg';
import { ReactComponent as NonSport } from '../../assets/svgs/nonsport.svg';
import { ReactComponent as Unopened } from '../../assets/svgs/unopened.svg';
import { ReactComponent as Sets } from '../../assets/svgs/sets.svg';
import { ReactComponent as Menu } from '../../assets/svgs/menu.svg';
import { ReactComponent as HeartBig } from '../../assets/svgs/heart-big.svg';
import { ReactComponent as User } from '../../assets/svgs/user.svg';
import { ReactComponent as Cart } from '../../assets/svgs/cart.svg';
import { ReactComponent as Heart } from '../../assets/svgs/heart.svg';
import { ReactComponent as Search } from '../../assets/svgs/search.svg';
import { headerMenu } from "../../utils/utils";
import { useCallback } from "react";

const iconMapper = (label) => {
    const icons = {
        Baseball,
        Basketball,
        Boxing,
        Comics,
        Football,
        Hockey,
        Memorabilia: Memo,
        "Non-Sport": NonSport,
        "Un-Opened": Unopened,
        Sets,
    };

    return icons[label] || null;
};

const Header = React.memo(() => {
    const productsCount = useSelector((state) => state?.cartReducer?.item_count)
    const wishlistProductsCount = useSelector((state) => state?.wishlistReducer?.count)
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const auth = useAuth()

    useEffect(() => {
        const validateUser = async () => {
            const isLoggedin = await auth.isUserLoggedIn()
            setIsUserLoggedIn(isLoggedin)
        }
        validateUser()

    }, [auth])

    const [searchQueryheader, setSearchQueryHeader] = useState('')
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const queryS = searchParams.get('s') || '';
        if (queryS) {
            setSearchQueryHeader(queryS);
        }

    }, [location.search])

    const logout = useCallback(async () => {
        const deleteUser = await auth.deleteCurrentUser();
        if (deleteUser) {
            window.location.assign('/');
        }
    }, [auth]);

    const loadSearchInits = useCallback(() => {
        setTimeout(() => {
            if (window.runSearchInits && typeof window.runSearchInits === 'function') {
                window.runSearchInits();
            }
        }, 300);
    }, []);

    useEffect(() => {
        loadSearchInits();
    }, [loadSearchInits, location]);

    return (
        <>
            <link rel="preconnect" href="https://fonts.googleapis.com"></link>
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true"></link>
            <link href="https://fonts.googleapis.com/css2?family=Inter:wght@500;700;800;900&display=swap" media="print" rel="stylesheet" onload="this.media='all'"></link>
            <header id="header">
                <div className="header-wrapper">
                    <div className="header-inner pl-10 pr-10">
                        <div id="logo">
                            <Link to={`/`}>
                                <img loading="lazy" width="181" height="37" src="https://res.cloudinary.com/dzhclyn7c/image/upload/v1725515711/logo.11382c5ec95cfd207528_1_r310yc.png" className="header_logo header-logo lazyloaded" alt="TonyeTrade" />
                            </Link>
                        </div>
                        <div className="header_middle">
                            <ul className="header-nav">
                                {headerMenu.map((menuItem, index) => (
                                    <li key={index} className={menuItem.submenu ? "has_dropdown" : ""}>
                                        <Link to={menuItem.path}>{menuItem.label}</Link>

                                        {menuItem.submenu && (
                                            <ul className={`sub-menu flex-nav`}>
                                                {menuItem.submenu.map((subItem, subIndex) => {
                                                    const IconComponent = iconMapper(subItem.label);
                                                    return (
                                                        <li key={subIndex}>
                                                            <Link to={subItem.path}>
                                                                {IconComponent && (
                                                                    <span className="icon">
                                                                        <IconComponent />
                                                                    </span>
                                                                )}
                                                                {subItem.label}
                                                                {subItem.sub && <span className="sub">{subItem.sub}</span>}
                                                            </Link>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                            <div className="header_search_wrapper mr-10 dgwt-wcas-search-wrapp dgwt-wcas-has-submit woocommerce dgwt-wcas-style-pirx js-dgwt-wcas-layout-classic dgwt-wcas-layout-classic js-dgwt-wcas-mobile-overlay-enabled">
                                <form id="header_search_form" className="dgwt-wcas-search-form ss" role="search" method="get" onSubmit={(event) => {
                                    event.preventDefault()
                                    const query = document.getElementById('header_search_query').value?.trim()
                                    if (query?.length > 0)
                                        navigate(`/search?s=${query}`)
                                }}>
                                    <div className="dgwt-wcas-sf-wrapp">
                                        <input value={searchQueryheader} className="dgwt-wcas-search-input" type="text" autoComplete="off" id="header_search_query" name="s" placeholder="Search for products..." onInput={(e)=>{
                                            setSearchQueryHeader(e.target.value)
                                        }} />
                                        <div className={`dgwt-wcas-preloader`}>
                                        </div>
                                    </div>
                                    <button type="submit">
                                        <Search />
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="header_right_desktop">
                            <ul className="header-nav header-nav-main">
                                <li className="header-mobile-search-icon">
                                    <Link to={'/search'}>
                                        <Search />
                                    </Link>
                                </li>
                                <li className="header-wishlist-icon">
                                    <Link to={`/my-favorites`}>
                                        <Heart />
                                        <span className="count">{wishlistProductsCount}</span>
                                    </Link>
                                </li>
                                <li className="header-cart-icon">
                                    <Link to={`/cart`}>
                                        <Cart />
                                        <span className="count">{productsCount}</span>
                                    </Link>
                                </li>
                                <li className="header-account-icon has-dropdown">
                                    <Link to={`/my-account`}>
                                        <User />
                                    </Link>
                                    {
                                        isUserLoggedIn ?
                                            <ul className="sub-menu">
                                                <li>
                                                    <Link to={`/my-account`}>
                                                        Account Settings
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`/my-account/orders`}>
                                                        My Orders
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`/my-sets`}>
                                                        My Sets
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`/my-favorites`}>
                                                        <HeartBig /> My Favorites
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={`/contact`}>
                                                        Help Center
                                                    </Link>
                                                </li>
                                                <li className="logout">
                                                    <a href="#" onClick={event => {
                                                        event.preventDefault()
                                                        logout()
                                                    }}>
                                                        Sign Out
                                                    </a>
                                                </li>
                                            </ul> : null
                                    }
                                </li>
                            </ul>
                        </div>
                        <div className="header_right_mobile">
                            <a href="#" id="open_mobile_menu">
                                <Menu />
                            </a>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
});

export default Header