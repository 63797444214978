import '../EmptyCart/emptycart.css'
import React from "react"
import { Link } from "react-router-dom"

const EmptyCart = (props) => {
    return (
        <>
            {
                props?.isCheckout ?
                    <div className='checkout_spacer'></div> : null
            }
            <div className='empty_cart_wrapper text-center'>
                <p className='empty_cart_text'>
                    Your cart is currently empty.
                </p>
                <Link to={`/shop`} className='return_to_shop'>
                    Return to shop
                </Link>
            </div>
        </>
    )
}
export default EmptyCart