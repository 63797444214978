import '../Blog/blog.css'
import React, { useEffect, useState } from "react";
import { getBlogPosts } from "../../api/api";
import { Link } from "react-router-dom";
import ContentLoader from 'react-content-loader';
import he from 'he'
import { scrollToTop } from '../../utils/utils';

const formatDate = ( dateString )=>{
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
}

const RenderPlaceholderCategories = ()=>{
    return (
        <div className='categoriesPlaceholder'>
            <ContentLoader viewBox="0 0 100 40">
                <rect x="0" y="0" rx="2" ry="2" width="16" height="7" />
                <rect x="18" y="0" rx="2" ry="2" width="16" height="7" />
                <rect x="36" y="0" rx="2" ry="2" width="16" height="7" />
                <rect x="54" y="0" rx="2" ry="2" width="16" height="7" />
                <rect x="72" y="0" rx="2" ry="2" width="16" height="7" />
            </ContentLoader>
        </div>
    )
}

const RenderPlaceholder = ()=>{
    return (
        <>
            <div className="single_post_wrapper placeholder">
                <div className="single_post_wrapper_inner">
                    <div className="single_post_thumbnail_wrapper box-image">
                        <div className="image-cover">
                            <ContentLoader viewBox="0 0 50 40">
                                <rect x="0" y="0" rx="2" ry="2" width="500" height="400" />
                            </ContentLoader>
                        </div>
                    </div>
                    <div className="single_post_content_wrapper">
                        <h5 className="post-title">
                            <ContentLoader viewBox="0 0 100 40">
                                <rect x="0" y="0" rx="2" ry="2" width="90" height="5" />
                                <rect x="0" y="10" rx="2" ry="2" width="40" height="3" />
                                <rect x="0" y="18" rx="2" ry="2" width="95" height="3" />
                                <rect x="0" y="25" rx="2" ry="2" width="95" height="3" />
                                <rect x="0" y="32" rx="2" ry="2" width="95" height="3" />
                            </ContentLoader>
                        </h5>
                        <div className="post-meta">
                            
                        </div>
                        <div className="from_the_blog_excerpt">
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="single_post_wrapper placeholder">
                <div className="single_post_wrapper_inner">
                    <div className="single_post_thumbnail_wrapper box-image">
                        <div className="image-cover">
                            <ContentLoader viewBox="0 0 50 40">
                                <rect x="0" y="0" rx="2" ry="2" width="500" height="400" />
                            </ContentLoader>
                        </div>
                    </div>
                    <div className="single_post_content_wrapper">
                        <h5 className="post-title">
                            <ContentLoader viewBox="0 0 100 40">
                                <rect x="0" y="0" rx="2" ry="2" width="90" height="5" />
                                <rect x="0" y="10" rx="2" ry="2" width="40" height="3" />
                                <rect x="0" y="18" rx="2" ry="2" width="95" height="3" />
                                <rect x="0" y="25" rx="2" ry="2" width="95" height="3" />
                                <rect x="0" y="32" rx="2" ry="2" width="95" height="3" />
                            </ContentLoader>
                        </h5>
                        <div className="post-meta">
                            
                        </div>
                        <div className="from_the_blog_excerpt">
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="single_post_wrapper placeholder">
                <div className="single_post_wrapper_inner">
                    <div className="single_post_thumbnail_wrapper box-image">
                        <div className="image-cover">
                            <ContentLoader viewBox="0 0 50 40">
                                <rect x="0" y="0" rx="2" ry="2" width="500" height="400" />
                            </ContentLoader>
                        </div>
                    </div>
                    <div className="single_post_content_wrapper">
                        <h5 className="post-title">
                            <ContentLoader viewBox="0 0 100 40">
                                <rect x="0" y="0" rx="2" ry="2" width="90" height="5" />
                                <rect x="0" y="10" rx="2" ry="2" width="40" height="3" />
                                <rect x="0" y="18" rx="2" ry="2" width="95" height="3" />
                                <rect x="0" y="25" rx="2" ry="2" width="95" height="3" />
                                <rect x="0" y="32" rx="2" ry="2" width="95" height="3" />
                            </ContentLoader>
                        </h5>
                        <div className="post-meta">
                            
                        </div>
                        <div className="from_the_blog_excerpt">
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const extractCategories = ( posts )=>{
    let categories = []
    posts.map(post=>{
        const _embedded = post?._embedded
        let name = '', id = 0
        if( _embedded['wp:term'] != undefined && _embedded['wp:term'][0] != undefined ){
            name = _embedded['wp:term'][0][0]?.name
            id = _embedded['wp:term'][0][0]?.id
        }
        if ( id > 0 && !categories.some( category => category.id === id ) ) {
            categories.push({ 
                id: id,
                name: name
            });
        }
    })
    return categories
}

const RenderSinglePost = (props)=>{
    const post = props.post, _embedded = post?._embedded
    let featuredImage = '', category_id = 0
    if( post?._embedded['wp:featuredmedia'] != undefined ){
        featuredImage = post?._embedded['wp:featuredmedia'][0]?.source_url
    }
    if( _embedded['wp:term'] != undefined && _embedded['wp:term'][0] != undefined ){
        category_id = _embedded['wp:term'][0][0]?.id
    }
    return(
        <div className="single_post_wrapper" data-category={category_id}>
            <div className="single_post_wrapper_inner">
                <Link to={`/post/${post.slug}`}></Link>
                {
                    featuredImage?.length > 0 ?
                    <div className="single_post_thumbnail_wrapper box-image">
                        <div className="image-cover">
                            <img src={featuredImage?.replace('staging.','www.')} loading='lazy' />
                        </div>
                    </div>:null
                }
                <div className="single_post_content_wrapper">
                    <h5 className="post-title">
                        {he.decode(post?.title?.rendered)}
                    </h5>
                    <div className="post-meta">
                        {formatDate(post?.date_gmt)}
                    </div>
                    <div className="from_the_blog_excerpt">
                        <div dangerouslySetInnerHTML={{ __html: post?.excerpt?.rendered }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

const RenderBlogPosts = (props)=>{
    const posts = props.posts
    return posts.map(post=>{
        return (
            <RenderSinglePost key={post.id} post={post} />
        )
    })
}

const Blog = ()=>{
    const [posts,setPosts] = useState([])
    const [filteredPosts,setFilteredPosts] = useState([])
    const [categories,setCategories] = useState([])
    useEffect(()=>{
        const getBlogPostsInner = async ()=>{
            const postsInner = await getBlogPosts()
            if(Array.isArray(postsInner) && postsInner?.length > 0){
                setPosts(postsInner)
                setFilteredPosts(postsInner)
                setCategories( extractCategories( postsInner ) )
            }
        }
        getBlogPostsInner()
        scrollToTop()
    },[])

    const RenderBlogPostsCategories = (props) => {
        const categories = props.categories
        return categories?.map( category => {
            return(
                <a href="#" data-id={category?.id} onClick={(event)=>{
                    event.preventDefault();
                    filterPosts( category?.id )
                }}>
                    {category?.name}
                </a>
            )
        })
    }

    const filterPosts = (type)=>{
        if( 'all' == type ){
            setFilteredPosts(posts)
            return
        }
        type = parseInt(type)
        if(type > 0){
            const _filteredPostsInner = posts.filter(post => {
                let category_id = 0, _embedded = post?._embedded
                if( _embedded['wp:term'] != undefined && _embedded['wp:term'][0] != undefined ){
                    category_id = _embedded['wp:term'][0][0]?.id
                }
                return category_id == type
            })
            setFilteredPosts(_filteredPostsInner)
        }
        
    } 

    return(
        <>
            <div className="single_post_header flex">
                <div className="container">
                    <h2 className="entry-title">
                        Blog Articles
                    </h2>
                </div>
            </div>
            <div className="blog_posts_categories_wrapper">
                <div className='blog_posts_categories_wrapper_inner'>
                    {
                        categories?.length > 0 ?
                        <>
                            <a href="#" data-id="all" onClick={(event)=>{
                                event.preventDefault();
                                filterPosts('all')
                            }}>
                                All
                            </a>
                            <RenderBlogPostsCategories categories={categories} />
                        </>:
                        <RenderPlaceholderCategories />
                    }
                </div>
            </div>
            <div className="blog_posts_wrapper">
                <div className="container">
                    <div className="blog_posts_wrapper_inner flex">
                        {
                            filteredPosts?.length > 0 ? 
                            <RenderBlogPosts posts={filteredPosts} /> : <RenderPlaceholder />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blog