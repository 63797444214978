import { decryptData } from "../utils/utils"

const getCurrentUser = async () => {
    try {
        let user = localStorage.getItem('activeuser')
        if (user){
            user = await decryptData(user)
            // user = await JSON.parse(user)
        }
        return user
    } catch (error) {

    }
}

const isUserLoggedIn = async () => {
    let user = localStorage.getItem('activeuser')
    // if( user )
    // user = await JSON.parse(user)
    // console.log(user ? true : false)
    return user ? true : false
}

const setCurrentUser = async (user) => {
    try {
        // localStorage.setItem('activeuser', JSON.stringify(user))
        localStorage.setItem('activeuser', user)
        return true
    } catch (error) {

    }
}

const deleteCurrentUser = async () => {
    try {
        localStorage.removeItem('activeuser')
        return true
    } catch (error) {
        return false
    }
}

const authManager = {
    setCurrentUser,
    getCurrentUser,
    deleteCurrentUser,
    isUserLoggedIn, 

}
export default authManager