import { ADD_TO_CART, CLEAR_CART, GET_CART_PRODUCT_COUNT, REMOVE_FROM_CART, SET_CART } from "../constants";

export const initialState = localStorage.getItem('tonyetradecartlocal') ? JSON.parse(localStorage.getItem('tonyetradecartlocal')) : {
    cart_hash: '',
    cart_key: '',
    coupons: [],
    cross_sells: [],
    currency: {},
    customer: {},
    fees: [],
    item_count: 0,
    items: [],
    items_weight: 0,
    needs_payment: false,
    needs_shipping: false,
    notices: null,
    removed_items: [],
    shipping: null,
    taxes: [],
    totals: 0,
}

export const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CART:
            localStorage.setItem('tonyetradecartlocal', JSON.stringify(action.data))
            const newCart = {
                ...action.data
            }
            return newCart
        case ADD_TO_CART:
            const newProductsArr = [
                ...state.products,
                action.data
            ]
            const newQty = state.qty + 1
            const newData = {
                ...initialState,
                qty: newQty,
                products: newProductsArr,
            }
            localStorage.setItem('tonyetradecartlocal', JSON.stringify(newData))
            return newData
        case REMOVE_FROM_CART:
            let newDataCart = state?.items
            newDataCart = state?.items?.filter(item => {
                return item?.id !== action?.data?.id
            })
            const afterRemovedData = {
                ...state,
                item_count: newDataCart?.length,
                items: newDataCart
            }
            localStorage.setItem('tonyetradecartlocal', JSON.stringify(afterRemovedData))
            return afterRemovedData
        case GET_CART_PRODUCT_COUNT:
            return state?.products?.length
        case CLEAR_CART:
            localStorage.removeItem('tonyetradecartlocal')
            return {
                cart_hash: '',
                cart_key: '',
                coupons: [],
                cross_sells: [],
                currency: {},
                customer: {},
                fees: [],
                item_count: 0,
                items: [],
                items_weight: 0,
                needs_payment: false,
                needs_shipping: false,
                notices: null,
                removed_items: [],
                shipping: null,
                taxes: [],
                totals: 0,
            }
        default:
            return state
    }
}