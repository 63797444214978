import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { getTags } from "../../api/adminapi";
import { useParams } from "react-router-dom";
import placeholder from "../../assets/images/placeholder.png";
import EditTagCanvas from "../../components/EditTagCanvas";
import { getCache, setCache } from "../../cacheUtil";
import he from 'he';
import AdminTableMeta from "../../components/AdminTableMeta";

const CACHE_KEY_EDIT_TAGS = 'edit_tags_data';

const strip_title = (title = '')=>{
    return title.replace('_', ' ');
}

const EditTags = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { taxonomy } = useParams();
    const [tags, setTags] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [perPage, setPerPage] = useState(20)
    const [total, setTotal] = useState(0)
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [selectedTags, setSelectedTags] = useState([]);

    const fetchTags = async (page = null, skip_cache = false) => {
        setLoading(true)
        try {
            await getTags(taxonomy, page ? page : currentPage, perPage, skip_cache, document.getElementById('search_term').value).then(response => {
                if (response.status == 200) {
                    const _data = response.data;
                    setTotalPages(_data?.total_pages)
                    setTotal(_data?.total_items)
                    setCurrentPage(_data?.current_page)
                    setTags(_data.terms)
                    if (!_data.nocache) {
                        setCache(`${CACHE_KEY_EDIT_TAGS}_${taxonomy}`, _data);
                    }
                }
            }).finally(() => {
                setLoading(false)
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        const loadCachedTags = () => {
            const cachedData = getCache(`${CACHE_KEY_EDIT_TAGS}_${taxonomy}`);
            if (cachedData) {
                setTotalPages(cachedData.total_items);
                setTotal(cachedData.length);
                setCurrentPage(cachedData?.current_page)
                setTags(cachedData.terms);
            }
        };
        loadCachedTags();
        fetchTags();
    }, [])

    const openModal = (term) => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedTerm(null);
        setIsModalOpen(false);
    };

    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allTagIds = tags.map(tag => tag.term.id);
            setSelectedTags(allTagIds);
        } else {
            setSelectedTags([]);
        }
    };

    const handleTagCheckbox = (e, tagId) => {
        if (e.target.checked) {
            setSelectedTags(prev => [...prev, tagId]);
        } else {
            setSelectedTags(prev => prev.filter(id => id !== tagId));
        }
    };

    const handleSearchFormSubmit = (query) => {
        if (loading || !query?.trim()?.length)
            return;
        setLoading(true)
        fetchTags();
    }

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="row">
                            <div class="col-12 col-lg-12">
                                <div class="admin-card mb-6">
                                    <div class="admin-card-header d-flex justify-content-between align-items-center">
                                        <div>
                                            <div id="DataTables_Table_0_filter" class="dataTables_filter me-3 mb-sm-0 mb-0 ps-0">
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSearchFormSubmit(document.getElementById('search_term').value)
                                                }}>
                                                    <label>
                                                        <input type="search" id="search_term" class="form-control ms-0" placeholder={`Search ${strip_title(taxonomy)}`} aria-controls="DataTables_Table_0" />
                                                    </label>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-center justify-content-md-end align-items-baseline">
                                            <div class="dt-action-buttons d-flex justify-content-center flex-md-row align-items-center pt-0">
                                                <div class={`spinner-border text-primary opacity-${loading ? '1' : '0'} me-3`} role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                                <div class="dt-buttons btn-group flex-wrap">
                                                    <button onClick={() => {
                                                        setSelectedTerm(null)
                                                        openModal();
                                                    }} class="btn btn-secondary add-new btn-primary ms-2 waves-effect waves-light" tabindex="0" aria-controls="DataTables_Table_0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasEcommerceCategoryList">
                                                        <span>
                                                            <i class="ti ti-plus ti-xs me-0 me-sm-2"></i>
                                                            <span class="d-none d-sm-inline-block">Add {strip_title(taxonomy)}</span>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-datatable table-responsive">
                                        <table class="datatables-order-details table border-top">
                                            <thead>
                                                <tr>
                                                    <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all w-5" rowspan="1" colspan="1" data-col="1" aria-label="">
                                                        <input type="checkbox" class="form-check-input"
                                                            onChange={handleAllSelector}
                                                            checked={tags && selectedTags.length === tags.length}
                                                        />
                                                    </th>
                                                    <th class="w-10">Image</th>
                                                    <th class="w-25">Name</th>
                                                    <th class="w-20">Description</th>
                                                    <th class="w-20">Slug</th>
                                                    <th class="w-10">Count</th>
                                                    <th class="w-10">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    'undefined' == typeof tags ?
                                                        (<p>Processing</p>) : (
                                                            tags.length ?
                                                                tags.map(tag => {
                                                                    let term = tag.term,
                                                                        _sep = '';
                                                                    if (tag.level > 0) {
                                                                        for (var i = 1; i <= tag.level; i++) {
                                                                            _sep += '— ';
                                                                        }
                                                                    }
                                                                    return (
                                                                        <tr>
                                                                            <td className="w-5">
                                                                                <input type="checkbox" class="form-check-input"
                                                                                    checked={selectedTags.includes(term.id)}
                                                                                    onChange={(e) => handleTagCheckbox(e, term.id)}
                                                                                />
                                                                            </td>
                                                                            <td className="w-10">
                                                                                <img width={40} src={placeholder} />
                                                                            </td>
                                                                            <td class="w-25">
                                                                                <span>
                                                                                    {_sep}
                                                                                    {
                                                                                        he.decode(term.name)
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                            <td className="w-20">
                                                                                <span dangerouslySetInnerHTML={{ __html: term.description ? term.description : '—' }} />
                                                                            </td>
                                                                            <td className="w-20">
                                                                                <span>
                                                                                    {
                                                                                        term.slug
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                            <td className="w-10">
                                                                                <span>
                                                                                    {
                                                                                        term.count
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <div class="d-flex align-items-sm-center justify-content-sm-center gap-2">
                                                                                    <button class="btn btn-icon btn-text-secondary rounded-pill waves-effect waves-light" onClick={() => {
                                                                                        setSelectedTerm(term)
                                                                                        openModal(true)
                                                                                    }}>
                                                                                        <i class="ti ti-edit"></i>
                                                                                    </button>
                                                                                    {/* <button onClick={() => {

                                                                                    }} class="btn btn-icon btn-text-secondary rounded-pill waves-effect waves-light dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        <i class="ti ti-dots-vertical ti-md"></i>
                                                                                    </button> */}
                                                                                    <div class={`dropdown-menu dropdown-menu-end m-0`}>
                                                                                        <a href="javascript:0;" class="dropdown-item">Delete</a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                : <p>No items</p>
                                                        )
                                                }
                                            </tbody>
                                        </table>
                                        <EditTagCanvas taxonomy={taxonomy} term={selectedTerm} isOpen={isModalOpen} onClose={closeModal} refresh={fetchTags} />
                                        <AdminTableMeta showPagination={true}
                                            pagination={{
                                                total: total,
                                                per_page: 20,
                                                currentpage: currentPage,
                                                refresh: fetchTags,
                                                setCurrentPage: setCurrentPage,
                                            }}
                                            refresh={fetchTags}
                                            loading={loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-overlay layout-menu-toggle"></div>
        <div class="drag-target"></div>
    </div>
}

export default EditTags;