import axios from "axios"
import { GET_SUGGESTIONS_LOCAL } from "../constants"

export const initialState = [{
    finalSuggestions: [],
    allSuggestions: [
        {
            query: 'dad',
            suggestions: [],
        }
    ]
}]

export const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUGGESTIONS_LOCAL:
            let exactMatches = [], partialMatches = []
            exactMatches = state[0]?.allSuggestions.filter(suggestion => {
                return suggestion?.query == action.data?.query
            })
            if (exactMatches?.length > 0) {
                return [{
                    ...state[0],
                    finalSuggestions: exactMatches,
                }]
            }

            let queryArr = action.data?.query?.split(' ').filter(word => word !== '')
            queryArr?.map(q => {
                let partialMatchesInner = state[0]?.allSuggestions.filter(suggestion => {
                    return suggestion?.query?.indexOf(q) > -1
                })
                if (partialMatchesInner?.length) {
                    partialMatches.concat(partialMatchesInner)
                }
            })

            if (partialMatches?.length > 0) {
                return [{
                    ...state[0],
                    finalSuggestions: partialMatches,
                }]
            }

            if (exactMatches?.length < 1 && partialMatches?.length < 1) {

                const params = new URLSearchParams({
                    s: action.data?.query,
                })

                // const fetchFromAPI = async () => {
                //     await axios.get(`https://tonyetrade.com/wp-json/tonyetradeapi/v1/getsearchsuggestions?${params}`).then(res => {
                //         if( res?.status == 200 && res?.data?.suggestions && Array.isArray( res?.data?.suggestions ) && res?.data?.suggestions?.length ){
                //             let suggestions = []
                //             res?.data?.suggestions?.map( suggestion => {
                //                 if( suggestion?.type == 'product' ){
                //                     suggestions.push({
                //                         value: suggestion?.value,
                //                         slug: suggestion?.url?.replace('https://www.tonyetrade.com/', '')?.replace('/','')
                //                     })
                //                 }
                //             } )

                //             const obj = {
                //                 ...state[0],
                //                 finalSuggestions: suggestions,
                //                 allSuggestions: [ ...state[0]?.allSuggestions, { query: action.data?.query, suggestions: suggestions } ]
                //             };

                //             return obj;

                //         }
                //     }).catch(err => {
                //         console.log(err)
                //     })
                // }

                // return fetchFromAPI()

                // return [{
                //     ...state[0],
                //     finalSuggestions: []
                // }]

            }

            return state
        default:
            return state
    }
}