import { Link } from "react-router-dom";
import Pagination from "../Pagination";
import { performBulkAction_API } from "../../api/adminapi";
import toast from "react-hot-toast";

const AdminTableMeta = ({ showSearch, search, actions, showPagination, setLoading, loading, pagination, showBulkActions, bulkActions, selectedItems, post_type, refresh, bulkFn, actionCallback, handleSearchFormSubmit }) => {
    const handleButtonClick = async () => {

        if (loading)
            return

        const _selectedAction = document.getElementById('bulkactions').value
        if (-1 == _selectedAction) {
            toast.error('Invalid action', {
                position: "top-center",
                style: {
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            })
            return;
        }

        if (!selectedItems?.length) {
            toast.error('Select an item first', {
                position: "top-center",
                style: {
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            })
            return;
        }
        if ('edit_in_bulk' == _selectedAction || 'edit_one_by_one' == _selectedAction) {
            bulkFn && bulkFn(_selectedAction)
            return
        }

        if (setLoading)
            setLoading(true)

        await performBulkAction_API(_selectedAction, post_type, selectedItems).then(response => {
            if (response.status == 200) {
                if (refresh)
                    refresh(null, true)
                if (response.data?.message) {
                    toast.success(response.data?.message, {
                        position: "top-center",
                        style: {
                            borderColor: 'green',
                            borderWidth: '1px',
                            borderStyle: 'solid'
                        }
                    })
                }
            }
        }).catch((error) => {
            console.warn(error)
        }).finally(() => {
            setLoading(false)
        })

    }

    return <div class="admin-card-header d-flex">
        {
            showBulkActions ?
                <div className="col-4">
                    <div class="mb-6 col ecommerce-select2-dropdown">
                        <div class="position-relative d-flex gap-3" style={{ top: 8 }}>
                            {
                                bulkActions?.actions?.length ?
                                    <select id="bulkactions" className="select2 form-select select2-hidden-accessible"
                                        onChange={(e) => {
                                            actionCallback && actionCallback(e.target.value)
                                        }}
                                    >
                                        <option value="-1">Bulk actions</option>
                                        {
                                            bulkActions.actions.map((action, index) => {
                                                return <option key={index} value={action.key}>
                                                    {action.value}
                                                </option>
                                            })
                                        }
                                    </select> : null
                            }
                            <button class="btn add-new btn-primary ms-2 ms-sm-0 waves-effect waves-light" tabindex="0" aria-controls="DataTables_Table_0" type="button" onClick={handleButtonClick}>
                                Apply
                            </button>
                        </div>
                    </div>
                </div> : null
        }
        <div className="col align-center d-flex justify-content-end gap-4">
            <div class={`spinner-border text-primary opacity-${loading ? '1' : '0'} me-3`} role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
            {
                showSearch ?
                    <div class="ms-n4 mb-n6 mb-md-0">
                        <div id="DataTables_Table_0_filter" class="dataTables_filter">
                            <form id="search-form" onSubmit={(e)=>{
                                e.preventDefault();
                                handleSearchFormSubmit && handleSearchFormSubmit(document.getElementById('search_query').value)
                            }}>
                                <label className="m-0">
                                    <input type="search" name="s" id="search_query" class="form-control" placeholder={search.placeholder} aria-controls="DataTables_Table_0" value={search.searchQuery} onChange={(e) => {
                                        search.setSearchQuery(e.target.value)
                                    }} />
                                </label>
                            </form>
                        </div>
                    </div> : null
            }
            {
                actions && actions?.length ?
                    <div class="d-flex justify-content-start justify-content-md-end align-items-baseline">
                        <div class="dt-action-buttons d-flex flex-column align-items-start align-items-sm-center justify-content-sm-center pt-0 gap-sm-4 gap-sm-0 flex-sm-row">
                            <div class="dt-buttons btn-group flex-wrap d-flex mb-6 mb-sm-0">
                                <Link to={'/admin/products/add'} class="btn add-new btn-primary ms-2 ms-sm-0 waves-effect waves-light" tabindex="0" aria-controls="DataTables_Table_0" type="button">
                                    <span><i class="ti ti-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">Add Product</span></span>
                                </Link>
                            </div>
                        </div>
                    </div> : null
            }
            {
                showPagination ?
                    <Pagination
                        totalItems={pagination.total}
                        itemsPerPage={pagination.per_page}
                        currentPage={pagination.currentpage}
                        onPageChange={(number) => {
                            pagination.setCurrentPage(number)
                        }}
                        refresh={refresh}
                    /> : null
            }

        </div>
    </div>
}

export default AdminTableMeta;