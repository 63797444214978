import { combineReducers } from 'redux'
import { cartReducer } from './reducers/cartReducer'
import { wishlistReducer } from './reducers/wishlistReducer'
import { searchReducer } from './reducers/searchReducer'
import adminSlice from './slices/adminSlice'

const allReducers = combineReducers({
    cartReducer: cartReducer,
    wishlistReducer: wishlistReducer,
    searchReducer: searchReducer,
    admin: adminSlice
})

const rootReducer = (state, action) => {
    return allReducers (state, action)
}

export default rootReducer