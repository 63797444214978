import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/AdminSidebar";
import AdminTopbar from "../../components/AdminTopbar";
import { getBlogPosts, getBlogPostsStatuses } from "../../api/adminapi";
import { getBulkActions, scrollToTop } from "../../utils/utils";
import { getCache, setCache } from "../../cacheUtil";
import PostStatuses from "../../components/PostStatuses";
import AdminTableMeta from "../../components/AdminTableMeta";
import he from 'he';
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const CACHE_KEY_POSTS = 'posts_data';
const CACHE_KEY_COUNTERS = 'posts_counters';

const Posts = () => {
    const [activeFilters, setActiveFilters] = useState({
        status: 'all',
    });
    const [counters, setCounters] = useState({
        all: { c: 0, t: 'All' },
        publish: { c: 0, t: 'Publish' },
        draft: { c: 0, t: 'Draft' },
        trash: { c: 0, t: 'Trash' },
        pending: { c: 0, t: 'Pending' },
    });

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [total, setTotal] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate()

    const fetchPosts = async (page = 1) => {
        if (loading) return;

        try {
            setLoading(true);
            scrollToTop();
            const response = await getBlogPosts(page, activeFilters.status);
            if (response.status === 200) {
                let _data = response.data;
                if (Object.keys(_data).length) {
                    _data.posts = _data.posts.filter(post => {
                        return post.post_status != 'auto-draft';
                    });
                    setTotalPages(_data.total_posts);
                    setTotal(_data.total_posts);
                    setCurrentPage(_data.current_page);
                    setPosts(_data.posts);
                }
                setCache(CACHE_KEY_POSTS, {
                    posts: _data.posts,
                    total: _data.total_posts,
                    totalPages: _data.total_posts,
                });
                getPostCounts();
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching posts:', error);
            setLoading(false);
        }
    };

    const getPostCounts = async () => {
        try {
            const response = await getBlogPostsStatuses();
            const _counts = response.data;
            if (_counts) {
                setCounters(prevCounters => {
                    const updatedCounters = { ...prevCounters };
                    Object.entries(_counts)?.forEach(([key, value]) => {
                        if (updatedCounters[key]) {
                            updatedCounters[key] = {
                                ...updatedCounters[key],
                                c: value,
                            };
                        }
                    });
                    return updatedCounters;
                });
            }
        } catch (error) {
            console.error('Error fetching post counts:', error);
        }
    };

    useEffect(() => {
        const loadCachedPosts = async () => {
            const cachedData = getCache(CACHE_KEY_POSTS);
            if (cachedData) {
                setPosts(cachedData.posts);
                setTotalPages(cachedData.totalPages);
                setTotal(cachedData.total);
                setCurrentPage(1);
            }
        };
        loadCachedPosts();
        fetchPosts();
    }, [activeFilters, currentPage, searchQuery]);

    const handleStatusFilter = (status) => {
        setActiveFilters({
            ...activeFilters,
            status: status,
        });
    };

    const [selectedPosts, setSelectedPosts] = useState([]);
    const handleAllSelector = (e) => {
        if (e.target.checked) {
            const allPostsIds = posts.map((post) => post.ID);
            setSelectedPosts(allPostsIds);
        } else {
            setSelectedPosts([]);
        }
    };

    const handlePostCheckbox = (e, postId) => {
        if (e.target.checked) {
            setSelectedPosts((prev) => [...prev, postId]);
        } else {
            setSelectedPosts((prev) => prev.filter((id) => id !== postId));
        }
    };

    const handlePostNavigation = (e, post) => {
        e.preventDefault()
        if ('trash' == post.post_status) {
            toast.error('Trash post can\'t be edited', {
                position: "top-center",
                style: {
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            })
            return;
        }
        navigate(`/admin/posts/add/${post.ID}`)
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <AdminSidebar />
                <div className="layout-page">
                    <AdminTopbar />
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="admin-card">
                                <AdminTableMeta
                                    showPagination={true}
                                    pagination={{
                                        total: total,
                                        per_page: 20,
                                        currentpage: currentPage,
                                        setCurrentPage: setCurrentPage,
                                    }}
                                    refresh={fetchPosts}
                                    loading={loading}
                                    showSearch={true}
                                    search={{
                                        searchQuery: searchQuery,
                                        setSearchQuery: setSearchQuery,
                                        placeholder: 'Search posts...',
                                    }}
                                    showBulkActions={true}
                                    bulkActions={{
                                        actions: getBulkActions('post'),
                                    }}
                                    selectedItems={selectedPosts}
                                    setLoading={setLoading}
                                    post_type={'post'}
                                />
                                <div className="card-datatable table-responsive">
                                    <PostStatuses
                                        counters={counters}
                                        activeFilters={activeFilters}
                                        handleStatusFilter={handleStatusFilter}
                                    />
                                    <table className="datatables-posts table dataTable no-footer dtr-column" id="DataTables_Table_0">
                                        <thead className="border-top">
                                            <tr>
                                                <th className="sorting_disabled pr-0 dt-checkboxes-cell dt-checkboxes-select-all" style={{ width: '18.6328px' }}>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        onChange={handleAllSelector}
                                                        checked={posts && selectedPosts.length === posts.length}
                                                    />
                                                </th>
                                                <th className="sorting_asc" aria-sort="ascending">Title</th>
                                                <th className="sorting">Status</th>
                                                <th className="sorting">Date</th>
                                                <th className="sorting">Categories</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {posts?.map(post => (
                                                <tr key={post.ID}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            onChange={(e) => {
                                                                handlePostCheckbox(e, post.ID)
                                                            }}
                                                            checked={selectedPosts.includes(post.ID)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <a href="#" onClick={(e) => {
                                                            handlePostNavigation(e, post)
                                                        }}>
                                                            <div class="d-flex justify-content-start align-items-center product-name">
                                                                {/* <div class="avatar-wrapper">
                                                                <div class="avatar avatar me-4 rounded-2 bg-label-secondary">
                                                                    {
                                                                        product?.image ?
                                                                            <img src={product?.image?.replace('staging.', 'admin.')} alt="Product-9" class="rounded-2" /> : null
                                                                    }
                                                                </div>
                                                            </div> */}
                                                                <div class="d-flex flex-column">
                                                                    <h6 class="mb-0">
                                                                        {he.decode(post.post_title)}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <span class={`badge ${post.post_status == 'trash' ? 'bg-label-danger' : 'bg-label-success'}`} text-capitalized="">
                                                            {
                                                                post.post_status
                                                            }
                                                        </span>
                                                    </td>
                                                    <td>{new Date(post.post_date).toLocaleDateString()}</td>
                                                    <td>
                                                        {
                                                            post?.categories?.length ? (
                                                                <div className="product_cats">
                                                                    {
                                                                        post?.categories?.map((cat, index) => {
                                                                            return (
                                                                                <React.Fragment key={cat.term_id}>
                                                                                    <Link to={`/admin/edit-tags/category/?term_id=${cat.term_id}`}>
                                                                                        {cat.name}
                                                                                    </Link>
                                                                                    {
                                                                                        index < post?.categories.length - 1 && <span>, </span>
                                                                                    }
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            ) : null
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Posts;
