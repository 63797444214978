import '../HowToValuate/howtovaluate.css'
import React, { useEffect } from "react";
import dollars from '../../assets/images/dollars.png'
import dollars2 from '../../assets/images/dollars2.png'
import { Link } from 'react-router-dom';
import { scrollToTop } from '../../utils/utils';

const HowToValuate = ()=>{

    useEffect(()=>{
        scrollToTop()
    },[])

    return(
        <>
            <div className="single_post_header flex">
                <div className="container">
                    <h2 className="entry-title">
                        HOW TO VALUATE COLLECTIONS
                    </h2>
                    <p className="sub-heading mb-10">
                        Determining the value of old baseball cards
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="card_section wrap-small">
                    <div className="card full-width-small">
                        <div className="card_upper">
                            <h2 className="card_heading">GETTING THE BEST PRICE FOR YOUR COLLECTION OF SPORTS CARDS!</h2>
                            <p className="card_text">Unlike individual sports cards or pieces of
                                memorabilia, there are additional variables when determining the value of sports cards
                                collections.</p>
                        </div>
                        <div className="image-card">
                            <img className="img-carrd" src={dollars} />
                        </div>
                        <div className="card_lower">
                            <p className="card_text2">TonyeTrade routinely spends millions of
                                dollars each year to purchase collections of sports cards and other collectibles, which puts
                                us in a position of experience for offering information and advice.</p>
                            <div className='text-center'>
                                <Link className="card-button" to={`/the-most-wanted-list/`}>SEE WHAT WE'RE LOOKING FOR</Link>
                            </div>
                        </div>
                    </div>

                    <div className="card full-width-small">
                        <div className="card_upper">
                            <h2 className="card_heading">INDIVIDUAL SPORTS CARDS VS ENTIER COLLECTIONS</h2>
                            <p className="card_text">When it comes to the value of old baseball
                                cards, there are unfortunately dishonest or unscrupulous individuals who look to take
                                advantage of those with less knowledge.</p>
                        </div>
                        <div className="image-card">
                            <img className="img-carrd" src={dollars2} />
                        </div>
                        <div className="card_lower">
                            <p className="card_text2">While “cherry-picking” can bring in some revenue, it doesn’t account for
                                the value of the collection of sports cards as a whole. For this reason, it’s essential to
                                find a buyer committed to purchasing your entire collection — not just the jewels it
                                contains.</p>
                            <div className='text-center'>
                                <Link className="card-button" to={`/contact`}>QUESTIONS? CONTACT US</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-0 pb-30">
                <div className="main-div wrap-small">
                    <div className="child-div full-width-small">
                        <h2 className="child-title">FIND THE RIGHT BUYER FOR SPORTS CARDS COLLECTIONS</h2>
                        <p className="child-des"> It takes a specific type of buyer if you’re looking to sell your collection of
                            sports cards. Most buyers look to cherry-pick only the best sports cards in a collection, and
                            then quickly flip them for a tidy profit. Buyers who are serious about handling entire
                            collections typically have large reserves of liquid assets, as well as an extensive list of
                            contacts interested in sports cards and other kinds of collectibles.</p>
                    </div>
                    <div className="child-div full-width-small">
                        <h2 className="child-title">WHEN TO GRADE SPORTS CARDS & COLLECTIBLES</h2>
                        <p className="child-des">It might be tempting to send the jewels of your sports cards collection to be
                            graded, but this decision warrants careful consideration. In situations where you’re considering
                            grading your sports cards, a far better alternative is to find a buyer who knows when and how to
                            get sports cards graded.</p>
                    </div>
                    <div className="child-div full-width-small">
                        <h2 className="child-title">FIND THE RIGHT BUYER FOR SPORTS CARDS COLLECTIONS</h2>
                        <p className="child-des">With more than 30 years of experience handling some of the largest and most
                            valuable collections of sports cards, TonyeTrade is your source for top dollar. Expect an easy,
                            stress-free experience where your questions and concerns are addressed, and a premium is placed
                            up your convenience. If you’re looking to sell a collection of sports cards or other
                            memorabilia, please contact TonyeTrade today.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HowToValuate