import { ADD_TO_CART, ADD_TO_WISHLIST, CLEAR_CART, GET_SUGGESTIONS, GET_SUGGESTIONS_LOCAL, REMOVE_FROM_CART, REMOVE_FROM_WISHLIST, SET_CART } from "./constants";

export function addToCart(data){
    return {
        type: ADD_TO_CART,
        data: data,
    }
}

export function setCart(data){
    return {
        type: SET_CART,
        data: data,
    }
}

export function clearCart(){
    return {
        type: CLEAR_CART
    }
}

export function removeFromCart(data){
    return {
        type: REMOVE_FROM_CART,
        data: data,
    }
}

export function addToWishlist(data){
    return {
        type: ADD_TO_WISHLIST,
        data: data,
    }
}

export function removeFromWishlist(data){
    return {
        type: REMOVE_FROM_WISHLIST,
        data: data,
    }
}

export function getSuggestions( data ){
    return {
        type: GET_SUGGESTIONS,
        data: data,
    }
}

export function getSuggestionsLocal( data ){
    return {
        type: GET_SUGGESTIONS_LOCAL,
        data: data,
    }
}