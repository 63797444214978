import { Link } from 'react-router-dom'
import '../UpdateShippingDetails/updateshippingdetails.css'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Hooks/useAuth'
import { countries, encryptData, scrollToTop } from '../../../utils/utils'
import axios from 'axios'
import toast from 'react-hot-toast'

const UpdateShipingDetails = () => {
  const auth = useAuth()
  const [user, setUser] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    country: '',
    address1: '',
    city: '',
    email: '',
    state: '',
    zipcode: '',
  })
  useEffect(() => {
    const getUserdata = async () => {
      const userInner = await auth.getCurrentUser()
      if (userInner) {
        setFormData({
          firstName: userInner?.shipping?.first_name,
          lastName: userInner?.shipping?.last_name,
          company: userInner?.shipping?.company,
          country: userInner?.shipping?.country,
          address1: userInner?.shipping?.address_1,
          city: userInner?.shipping?.city,
          email: userInner?.shipping?.email,
          state: userInner?.shipping?.state,
          zipcode: userInner?.shipping?.postcode,
        })

        setUser(userInner)
      }
    }
    scrollToTop()
    getUserdata()
  }, [])
  const handleFormSubmit = async () => {
    if (isProcessing)
      return

    let userInner = await auth.getCurrentUser()
    if (!userInner) {
      return
    }
    setIsProcessing(true)
    let customerdata = {
      shipping: {},
    }

    customerdata.shipping.first_name = formData?.firstName
    customerdata.shipping.last_name = formData?.lastName
    customerdata.shipping.company = formData?.company
    customerdata.shipping.address_1 = formData?.address1
    customerdata.shipping.city = formData?.city
    customerdata.shipping.state = formData?.state
    customerdata.shipping.postcode = formData?.zipcode
    customerdata.shipping.country = formData?.country

    await axios.post(process.env.REACT_APP_WC_API_BASE + `customers/${userInner?.id}`, customerdata, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
      }
    }).then(async res => {
      if (res.status == 200) {
        toast.success('Shipping details updated successfully', {
          position: "top-center",
          style: {
            borderColor: 'green',
            borderWidth: '1px',
            borderStyle: 'solid'
          }
        })
        scrollToTop()
        userInner.shipping = res?.data?.shipping
        const encryptedUser = await encryptData(userInner)
        await auth.setCurrentUser(encryptedUser)
      } else {
        toast.error('Error occured, try again', {
          position: "top-center",
          style: {
            borderColor: 'red',
            borderWidth: '1px',
            borderStyle: 'solid'
          }
        })
      }
    }).catch(err => {
      toast.error(err?.data?.msg, {
        position: "top-center",
        style: {
          borderColor: 'red',
          borderWidth: '1px',
          borderStyle: 'solid'
        }
      })
      console.log(err)
    }).finally(() => {
      setIsProcessing(false)
    })

  }
  return (
    <div className="container mid">
      <div className="account-details justify-center">
        <div className="left-panel flex-65 full-width-small">
          <div className="left-account-detail">
            <h3 className="account-title">Shipping Details</h3>
            <form className={`flex wrap space-between ${isProcessing ? 'processing' : ''}`} onSubmit={event => {
              event.preventDefault()
              handleFormSubmit()
            }}>
              <div className="form-group flex-49 full-width-small">
                <label className="form-label" htmlFor="firstname">First Name</label>
                <input type="text" value={formData?.firstName} name="firstname" required="required" placeholder="John" onChange={event => {
                  setFormData({
                    ...formData,
                    firstName: event.target.value
                  })
                }} />
              </div>
              <div className="form-group flex-49 full-width-small">
                <label className="form-label" htmlFor="lastname">Last Name</label>
                <input type="text" value={formData?.lastName} name="lastname" required="required" placeholder="Doe" onChange={event => {
                  setFormData({
                    ...formData,
                    lastName: event.target.value
                  })
                }} />
              </div>
              <div className="form-group flex-49 full-width-small">
                <label className="form-label" htmlFor="company">Company</label>
                <input type="text" value={formData?.company} name="company" placeholder="Company" onChange={event => {
                  setFormData({
                    ...formData,
                    company: event.target.value
                  })
                }} />
              </div>
              <div className="form-group flex-49 full-width-small">
                <label className="form-label" htmlFor="country">Country</label>
                <select name="country" value={formData?.country} onChange={event => {
                  setFormData({
                    ...formData,
                    country: event.target.value
                  })
                }}>
                  {
                    Object.entries(countries)?.map((country, index) => {
                      return (
                        <option key={index} value={country[0]}>
                          {country[1]}
                        </option>
                      )
                    })
                  }
                </select>
              </div>
              <div className="form-group flex-100">
                <label className="form-label" htmlFor="Address">Address</label>
                <input type="text" value={formData?.address1} name="Address" required="required" placeholder="Address" onChange={event => {
                  setFormData({
                    ...formData,
                    address1: event.target.value
                  })
                }} />
              </div>
              <div className="form-group flex-100">
                <label className="form-label" htmlFor="city">City</label>
                <input type="text" value={formData?.city} name="city" required="required" placeholder="City" onChange={event => {
                  setFormData({
                    ...formData,
                    city: event.target.value
                  })
                }} />
              </div>
              <div className="form-group flex-100">
                <label className="form-label" htmlFor="state">State</label>
                <input type="text" value={formData?.state} name="state" required="required" placeholder="State" onChange={event => {
                  setFormData({
                    ...formData,
                    state: event.target.value
                  })
                }} />
              </div>
              <div className="form-group flex-100">
                <label className="form-label" htmlFor="username">Zipcode</label>
                <input type="text" value={formData?.zipcode} name="zipcode" required="required" placeholder='Zipcode' onChange={event => {
                  setFormData({
                    ...formData,
                    zipcode: event.target.value
                  })
                }} />
              </div>
              <div className="btn-form mt-0 grouped-form-btns align-center flex gap-15">
                <input type="submit" className="submit-btn" value="Save Changes" />
                <Link className="cancel-btn" to={`/my-account`}>
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpdateShipingDetails