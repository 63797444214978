import { Link, useNavigate } from 'react-router-dom'
import '../Checkout/checkout.css'
import React, { useCallback, useEffect, useState } from 'react'
import EmptyCart from '../../components/EmptyCart'
import { convertToCommaSeparate, countriesStates } from '../../utils/utils'
import { useDispatch, useSelector, useStore } from 'react-redux'
import toast from 'react-hot-toast'
import { useAuth } from '../../Hooks/useAuth'
import axios from 'axios'
import { clearCart, setCart } from '../../redux/action'
import he from 'he'
import { PayPalButtons } from '@paypal/react-paypal-js'
import { getUserAgent } from 'universal-user-agent'

const getFormattedPhone = (phone) => {
    return phone.replace(/-/g, '').replace(/[^\d]/g, '')
}
const getPayerObject = () => {
    let payer = {}
    const billing_first_name = document.getElementById('billing_first_name').value?.trim()
    const billing_last_name = document.getElementById('billing_last_name').value?.trim()
    const billing_country = document.getElementById('billing_country').value?.trim()
    const billing_address_1 = document.getElementById('billing_address_1').value?.trim()
    const billing_address_2 = document.getElementById('billing_address_2').value?.trim()
    const billing_city = document.getElementById('billing_city').value?.trim()
    const billing_state = document.getElementById('billing_state').value?.trim()
    const billing_postcode = document.getElementById('billing_postcode').value?.trim()
    const billing_phone = getFormattedPhone(document.getElementById('billing_phone').value?.trim())
    const billing_email = document.getElementById('billing_email').value?.trim()

    payer.email_address = billing_email
    payer.name = {
        surname: billing_first_name,
        given_name: billing_last_name,
    }
    payer.phone = {
        phone_type: 'HOME',
        phone_number: {
            national_number: billing_phone,
        }
    }
    payer.address = {
        address_line_1: billing_address_1,
        address_line_2: billing_address_2,
        admin_area_1: billing_state,
        admin_area_2: billing_city,
        postal_code: billing_postcode,
        country_code: billing_country,
    }
    return payer
}

const parsePrice = (price, divide = true, addComma = true) => {
    let divider = 100
    if (!divide)
        divider = 1
    price = price / divider
    if (addComma)
        price = convertToCommaSeparate(price)
    return price
}

const Checkout = () => {

    const [isCartValidating, setIsCartValidating] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    const [ip, setIP] = useState(null);
    const [stateType, setStateType] = useState('text')
    const [shippingStateType, setShippingStateType] = useState('text')
    const [isCouponFormLoaded, setIsCouponFormLoaded] = useState(false)
    const [paymentMethods, setPaymentMethods] = useState(undefined)
    const [isDifferentShipping, setIsDifferentShipping] = useState(false)
    const [fundingSource, setFundingSource] = useState('paypal')
    const [enablePlaceOrderButton, setEnablePlaceOrderButton] = useState(true)
    const [userAgent, setUserAgent] = useState(null)
    
    const [formData, setFormData] = useState({
        billing: {
            firstName: '',
            lastName: '',
            company: '',
            country: '',
            address_1: '',
            address_2: '',
            city: '',
            state: '',
            zipcode: '',
            phone: '',
            email: '',
        },
        shipping: {
            firstName: '',
            lastName: '',
            company: '',
            country: '',
            address_1: '',
            address_2: '',
            city: '',
            state: '',
            zipcode: '',
        },
        notes: '',
    })

    const cartItems = useSelector((state) => state.cartReducer?.items),
        dispatcher = useDispatch(),
        store = useStore(),
        cart = useSelector((state) => state.cartReducer)
    const navigation = useNavigate()

    let currency_symbol = ''
    if (cart) {
        currency_symbol = cart?.currency?.currency_symbol ? cart?.currency?.currency_symbol : '$'
    }

    const handleCouponSubmitForm = useCallback(async () => {
        if (isCartValidating)
            return
        if (couponCode?.trim()?.length < 1) {
            toast.error('Invalid coupon code', {
                position: "top-center",
                style: {
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            })
            return
        }
        const _cartKey = cart?.cart_key ? cart?.cart_key : ''
        if (_cartKey?.length < 1)
            return
        setIsCartValidating(true)
        axios.post(process.env.REACT_APP_WC_STORE_API_BASE + `cart/coupons?code=${couponCode}&cart_key=${_cartKey}`).then(res => {
            if (res?.status == 201) {
                setCouponCode('')
                validateCart()
                setIsCouponFormLoaded(false)
            } else {
                setIsCartValidating(false)
            }
        }).catch(err => {
            setIsCartValidating(false)
            if (err?.response?.status == 400) {
                let msg = err?.response?.data?.message
                if (err?.response?.data?.code == 'rest_invalid_param') {
                    msg = 'Invalid coupon code'
                }
                toast.error(he.decode(msg), {
                    position: "top-center",
                    style: {
                        borderColor: 'red',
                        borderWidth: '1px',
                        borderStyle: 'solid'
                    }
                })
            }
        })
    }, [couponCode, cart, isCartValidating])

    const auth = useAuth()

    const updateCheckoutCustomerData = (customer) => {
        if (customer) {
            const _serverCustomerBilling = customer?.billing_address
            const _serverCustomerShipping = customer?.shipping_address
            if (_serverCustomerBilling) {
                let localCustomerBilling = formData?.billing
                if (_serverCustomerBilling?.billing_first_name?.trim()?.length > 1) {
                    localCustomerBilling.firstName = _serverCustomerBilling?.billing_first_name?.trim()
                }
                if (_serverCustomerBilling?.billing_last_name?.trim()?.length > 1) {
                    localCustomerBilling.lastName = _serverCustomerBilling?.billing_last_name?.trim()
                }
                if (_serverCustomerBilling?.billing_company?.trim()?.length > 1) {
                    localCustomerBilling.company = _serverCustomerBilling?.billing_company?.trim()
                }
                if (_serverCustomerBilling?.billing_country?.trim()?.length > 1) {
                    localCustomerBilling.country = _serverCustomerBilling?.billing_country?.trim()
                }
                if (_serverCustomerBilling?.billing_address_1?.trim()?.length > 1) {
                    localCustomerBilling.address_1 = _serverCustomerBilling?.billing_address_1?.trim()
                }
                if (_serverCustomerBilling?.billing_address_2?.trim()?.length > 1) {
                    localCustomerBilling.address_2 = _serverCustomerBilling?.billing_address_2?.trim()
                }
                if (_serverCustomerBilling?.billing_city?.trim()?.length > 1) {
                    localCustomerBilling.city = _serverCustomerBilling?.billing_city?.trim()
                }
                if (_serverCustomerBilling?.billing_state?.trim()?.length > 1) {
                    localCustomerBilling.state = _serverCustomerBilling?.billing_state?.trim()
                }
                if (_serverCustomerBilling?.billing_postcode?.trim()?.length > 1) {
                    localCustomerBilling.zipcode = _serverCustomerBilling?.billing_postcode?.trim()
                }
                if (_serverCustomerBilling?.billing_phone?.trim()?.length > 1) {
                    localCustomerBilling.phone = _serverCustomerBilling?.billing_phone?.trim()
                }
                if (_serverCustomerBilling?.billing_email?.trim()?.length > 1) {
                    localCustomerBilling.email = _serverCustomerBilling?.billing_email?.trim()
                }
                setFormData({
                    ...formData,
                    billing: localCustomerBilling
                })
            }

            if (_serverCustomerShipping) {
                let localCustomerShipping = formData?.shipping
                if (_serverCustomerShipping?.shipping_first_name?.trim()?.length) {
                    localCustomerShipping.firstName = _serverCustomerShipping?.shipping_first_name?.trim()
                }
                if (_serverCustomerShipping?.shipping_last_name?.trim()?.length) {
                    localCustomerShipping.lastName = _serverCustomerShipping?.shipping_last_name?.trim()
                }
                if (_serverCustomerShipping?.shipping_company?.trim()?.length) {
                    localCustomerShipping.company = _serverCustomerShipping?.shipping_company?.trim()
                }
                if (_serverCustomerShipping?.shipping_country?.trim()?.length) {
                    localCustomerShipping.country = _serverCustomerShipping?.shipping_country?.trim()
                }
                if (_serverCustomerShipping?.shipping_address_1?.trim()?.length) {
                    localCustomerShipping.address_1 = _serverCustomerShipping?.shipping_address_1?.trim()
                }
                if (_serverCustomerShipping?.shipping_address_2?.trim()?.length) {
                    localCustomerShipping.address_2 = _serverCustomerShipping?.shipping_address_2?.trim()
                }
                if (_serverCustomerShipping?.shipping_city?.trim()?.length) {
                    localCustomerShipping.city = _serverCustomerShipping?.shipping_city?.trim()
                }
                if (_serverCustomerShipping?.shipping_state?.trim()?.length) {
                    localCustomerShipping.state = _serverCustomerShipping?.shipping_state?.trim()
                }
                if (_serverCustomerShipping?.shipping_postcode?.trim()?.length) {
                    localCustomerShipping.zipcode = _serverCustomerShipping?.shipping_postcode?.trim()
                }
                setFormData({
                    ...formData,
                    shipping: localCustomerShipping
                })
            }

        }
    }

    const validateCart = useCallback(async () => {
        const userInner = await auth.getCurrentUser()
        let headers = {}, queryParamsObj = {}
        if (userInner) {
            headers = {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                }
            }
        }
        if (!userInner && cart?.cart_key) {
            queryParamsObj.cart_key = cart?.cart_key
        }
        let queryParams = new URLSearchParams(queryParamsObj);
        if (userInner || 1) {
            setIsCartValidating(true)
            return axios.get(process.env.REACT_APP_CC_API_BASE + `cart?${queryParams}`, headers).then(res => {
                if (res?.status == 200 && undefined != res?.data?.cart_key) {
                    if( document.getElementById('billing_address_1').value?.trim()?.length > 0 )
                        res.data.customer.billing_address.billing_address_1 = document.getElementById('billing_address_1').value?.trim()
                    // console.warn(res?.data?.customer)
                    updateCheckoutCustomerData(res?.data?.customer)
                    if (countriesStates[res?.data?.customer?.billing_address?.billing_country]?.states?.length) {
                        setStateType('select')
                    }
                    if (countriesStates[res?.data?.customer?.shipping_address?.shipping_country]?.states?.length) {
                        setShippingStateType('select')
                    }
                    if (cart?.cart_hash != res?.data?.cart_hash) {
                        dispatcher(setCart(res?.data))
                    }

                }
            }).catch(err => {

            }).finally(() => {
                setIsCartValidating(false)
            })
        }
    }, [cart, auth, dispatcher])

    useEffect(() => {
        if (cartItems?.length > 0) {
            validateCart()
        } else {
            navigation('/cart')
        }
    }, [navigation])


    const handleCheckoutFormSubmit = async () => {
        if (!cart || cart?.item_count < 1) {
            alert('Invalid cart')
            return
        }
        if (isCartValidating)
            return

        const userInner = await auth.getCurrentUser()
        const activeGateway = paymentMethods?.filter(method => {
            return method?.active
        })
        if ('cheque' == activeGateway[0]?.stringID) {
            let billing = {}, shipping = {}, line_items = [], shipping_lines = [], coupon_lines = [], tax_lines = []

            billing.first_name = formData?.billing?.firstName
            billing.last_name = formData?.billing?.lastName
            billing.company = document.getElementById('billing_company') ? document.getElementById('billing_company').value : ''
            billing.address_1 = formData?.billing?.address_1
            billing.address_2 = formData?.billing?.address_2
            billing.city = formData?.billing?.city
            billing.state = formData?.billing?.state
            billing.postcode = formData?.billing?.zipcode
            billing.country = formData?.billing?.country
            billing.email = formData?.billing?.email
            billing.phone = formData?.billing?.phone

            if (!document.getElementById('ship-to-different-address-checkbox').checked) {
                shipping = billing
            } else {
                shipping.first_name = formData?.shipping?.firstName
                shipping.last_name = formData?.shipping?.lastName
                shipping.address_1 = formData?.shipping?.address_1
                shipping.address_2 = formData?.shipping?.address_2
                shipping.city = formData?.shipping?.city
                shipping.state = formData?.shipping?.state
                shipping.postcode = formData?.shipping?.zipcode
                shipping.country = formData?.shipping?.country
            }

            cart?.items?.map(item => {
                line_items.push({
                    product_id: item?.id,
                    quantity: item?.quantity?.value
                })
            })

            let body = {
                payment_method: 'cheque',
                payment_method_title: activeGateway[0]?.title,
                set_paid: false,
                billing: billing,
                shipping: shipping,
                line_items: line_items,
                created_via: 'checkout',
                status: 'on-hold',
                customer_id: userInner ? userInner?.id : 0,
                customer_note: formData?.notes,
                cart_hash: cart?.cart_hash,
                meta_data: [{
                    key: 'cart_hash',
                    value: cart?.cart_hash,
                }, {
                    key: 'headless_order',
                    value: 'yes',
                }, {
                    key: '_payment_method_title',
                    value: 'Check / Money order'
                }, {
                    key: '_payment_method',
                    value: 'cheque'
                }]
            }

            if (ip) {
                body.meta_data.push({
                    key: '_customer_ip_address',
                    value: ip
                })
            }

            if (userAgent) {
                body.meta_data.push({
                    key: '_customer_user_agent',
                    value: userAgent
                })
            }

            if (parseInt(cart?.totals?.shipping_total)) {
                shipping_lines.push({
                    method_id: 'flat_rate',
                    method_id: 'Flat Rate',
                    total: parsePrice(cart?.totals?.shipping_total, true)
                })
                body.shipping_lines = shipping_lines
            }

            if (cart?.coupons?.length) {
                cart?.coupons?.map(coupon => {
                    coupon_lines.push({
                        code: coupon?.coupon,
                        discount: parsePrice(Math.abs(coupon?.saving), true)
                    })
                })
                body.coupon_lines = coupon_lines
            }

            if (cart?.taxes) {
                let taxKeys = Object.keys(cart?.taxes)
                if (taxKeys && taxKeys?.length) {
                    taxKeys?.map(taxKey => {
                        tax_lines.push({
                            rate_code: taxKey,
                            rate_id: taxKey,
                            label: cart?.taxes[taxKey]?.name,
                            compound: false,
                            tax_total: parsePrice(cart?.totals?.total_tax, true),
                            shipping_tax_total: cart?.totals?.shipping_tax && parseInt(cart?.totals?.shipping_tax) > 0 ? parsePrice(cart?.totals?.shipping_tax, true) : 0,
                        })
                    })
                }
                if (tax_lines?.length) {
                    body.tax_lines = tax_lines
                }
            }
            setIsCartValidating(true)
            axios.post(process.env.REACT_APP_WC_API_BASE + `orders`, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                }
            }).then(async res => {
                if (res?.status == 201) {
                    let checkoutData = res?.data
                    if (checkoutData) {
                        dispatcher(clearCart())
                        let headers = {}, queryParamsObj = {}
                        if (userInner) {
                            headers = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                                }
                            }
                        }
                        if (!userInner && cart?.cart_key) {
                            queryParamsObj.cart_key = cart?.cart_key
                        }
                        let queryParams = new URLSearchParams(queryParamsObj);
                        axios.post(process.env.REACT_APP_CC_API_BASE + `cart/clear?${queryParams}`, {}, headers).then(res => {

                            if (res?.status == 201) {
                                let checkoutData = res?.data
                                if (checkoutData) {
                                    dispatcher(clearCart())
                                    let headers = {}, queryParamsObj = {}
                                    if (userInner) {
                                        headers = {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                                            }
                                        }
                                    }
                                    if (!userInner && cart?.cart_key) {
                                        queryParamsObj.cart_key = cart?.cart_key
                                    }
                                    let queryParams = new URLSearchParams(queryParamsObj);
                                    axios.post(process.env.REACT_APP_CC_API_BASE + `cart/clear?${queryParams}`, {}, headers).then(res => {

                                        if (res?.status == 201) {
                                            let checkoutData = res?.data
                                            if (checkoutData) {
                                                dispatcher(clearCart())
                                                let headers = {}, queryParamsObj = {}
                                                if (userInner) {
                                                    headers = {
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                                                        }
                                                    }
                                                }
                                                if (!userInner && cart?.cart_key) {
                                                    queryParamsObj.cart_key = cart?.cart_key
                                                }
                                            }
                                        }
                                    }).catch(err => {
                                        console.log(err)
                                    })
                                    navigation(`/checkout/order-received/${checkoutData?.id}/?key=${checkoutData?.order_key}`, {
                                        state: {
                                            checkoutData: checkoutData
                                        }
                                    })
                                }
                            }
                        }).catch(err => {
                            console.log(err)
                        }).finally(() => {

                        })
                        navigation(`/checkout/order-received/${checkoutData?.id}/?key=${checkoutData?.order_key}`, {
                            state: {
                                checkoutData: checkoutData
                            }
                        })
                    }
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setIsCartValidating(false)
            })

        }
    }
    const handleChangePaymentMethod = (method) => {
        const updatedPaymentMethods = paymentMethods?.map(m => {
            if (m?.id === method?.id) {
                if (method?.stringID == 'cheque') {
                    setEnablePlaceOrderButton(true)
                } else {
                    setEnablePlaceOrderButton(false)
                }
                return { ...m, active: true };
            } else {
                return { ...m, active: false };
            }
        });
        setPaymentMethods(updatedPaymentMethods);
    }



    const handleRemoveCoupon = useCallback(async (coupon) => {
        if (isCartValidating)
            return
        if (coupon?.length < 1)
            return
        const _cartKey = cart?.cart_key ? cart?.cart_key : ''
        if (_cartKey?.length < 1)
            return
        setIsCartValidating(true)
        axios.delete(process.env.REACT_APP_WC_STORE_API_BASE + `cart/coupons/${coupon}?cart_key=${_cartKey}`).then(res => {
            if (res?.status == 204) {
                validateCart()
            }
        }).catch(err => {
            console.log(err)
        })
    }, [cart, isCartValidating])

    const updateCheckoutCustomerDataServer = async () => {
        let customerdata = {
            billing: {},
            shipping: {},
        }
        let condition = formData?.billing?.country?.length &&
            formData?.billing?.city?.trim()?.length &&
            formData?.billing?.state?.trim()?.length &&
            formData?.billing?.zipcode?.trim()?.length
        if (document.getElementById('ship-to-different-address-checkbox').checked) {
            condition = condition &&
                formData?.shipping?.country?.length &&
                formData?.shipping?.city?.trim()?.length &&
                formData?.shipping?.state?.trim()?.length &&
                formData?.shipping?.zipcode?.trim()?.length
        }

        const userInner = await auth.getCurrentUser()
        const billing_first_name = document.getElementById('billing_first_name').value?.trim()
        const billing_last_name = document.getElementById('billing_last_name').value?.trim()
        const billing_company = document.getElementById('billing_company').value?.trim()
        const billing_country = document.getElementById('billing_country').value?.trim()
        const billing_address_1 = document.getElementById('billing_address_1').value?.trim()
        const billing_address_2 = document.getElementById('billing_address_2').value?.trim()
        const billing_city = document.getElementById('billing_city').value?.trim()
        const billing_state = document.getElementById('billing_state').value?.trim()
        const billing_postcode = document.getElementById('billing_postcode').value?.trim()
        const billing_phone = document.getElementById('billing_phone').value?.trim()
        const billing_email = document.getElementById('billing_email').value?.trim()

        customerdata.billing.first_name = billing_first_name
        customerdata.billing.last_name = billing_last_name
        customerdata.billing.company = billing_company
        customerdata.billing.address_1 = billing_address_1
        customerdata.billing.address_2 = billing_address_2
        customerdata.billing.city = billing_city
        customerdata.billing.state = billing_state
        customerdata.billing.postcode = billing_postcode
        customerdata.billing.country = billing_country
        customerdata.billing.email = billing_email
        customerdata.billing.phone = billing_phone

        if (document.getElementById('ship-to-different-address-checkbox').checked) {
            const shipping_first_name = document.getElementById('shipping_first_name').value?.trim()
            const shipping_last_name = document.getElementById('shipping_last_name').value?.trim()
            const shipping_company = document.getElementById('shipping_company').value?.trim()
            const shipping_country = document.getElementById('shipping_country').value?.trim()
            const shipping_address_1 = document.getElementById('shipping_address_1').value?.trim()
            const shipping_address_2 = document.getElementById('shipping_address_2').value?.trim()
            const shipping_city = document.getElementById('shipping_city').value?.trim()
            const shipping_state = document.getElementById('shipping_state').value?.trim()
            const shipping_postcode = document.getElementById('shipping_postcode').value?.trim()
            customerdata.shipping.first_name = shipping_first_name
            customerdata.shipping.last_name = shipping_last_name
            customerdata.shipping.company = shipping_company
            customerdata.shipping.address_1 = shipping_address_1
            customerdata.shipping.address_2 = shipping_address_2
            customerdata.shipping.city = shipping_city
            customerdata.shipping.state = shipping_state
            customerdata.shipping.postcode = shipping_postcode
            customerdata.shipping.country = shipping_country
        } else {
            customerdata.shipping.first_name = billing_first_name
            customerdata.shipping.last_name = billing_last_name
            customerdata.shipping.company = billing_company
            customerdata.shipping.address_1 = billing_address_1
            customerdata.shipping.address_2 = billing_address_2
            customerdata.shipping.city = billing_city
            customerdata.shipping.state = billing_state
            customerdata.shipping.postcode = billing_postcode
            customerdata.shipping.country = billing_country
        }

        if (condition && userInner) {

            setIsCartValidating(true)
            await axios.post(process.env.REACT_APP_WC_API_BASE + `customers/${userInner?.id}`, customerdata, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                }
            }).then(res => {
                if (res.status == 200) {
                    validateCart()
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                setIsCartValidating(false)
            })

        } else if (condition && !userInner && cart?.cart_key) {
            setIsCartValidating(true)
            await axios.post(process.env.REACT_APP_TONYETRADE_API_BASE + `updatenoncustomercart`, {
                cart_key: cart?.cart_key,
                shipping: customerdata?.shipping,
                billing: customerdata?.billing
            }).then(res => {

            }).catch(err => {
                console.log(err)
            }).finally(() => {
                validateCart()
            })
        }

    }

    const onPaypalCancel = async data => {

    }

    const onPaypalApprove = async (data, actions) => {
        const userInner = await auth.getCurrentUser()
        return actions.order.capture().then(function (details) {

            setIsCartValidating(true)
            const intent = details?.intent
            const payer = details?.payer
            const purchaseUnits = details?.purchase_units[0]
            const paymentSource = data?.paymentSource
            let billing = {}, shipping = {}, line_items = [];
            billing.first_name = payer?.name?.given_name
            billing.last_name = payer?.name?.surname
            billing.company = document.getElementById('billing_company') ? document.getElementById('billing_company').value : ''
            billing.address_1 = payer?.address?.address_line_1
            billing.address_2 = payer?.address?.address_line_2 ? payer?.address?.address_line_2 : ''
            billing.city = payer?.address?.admin_area_2
            billing.state = payer?.address?.admin_area_1
            billing.postcode = payer?.address?.postal_code
            billing.country = payer?.address?.country_code
            billing.phone = document.getElementById('billing_phone') ? getFormattedPhone(document.getElementById('billing_phone').value?.trim()) : ''
            billing.email = formData?.billing?.email ? formData?.billing?.email : payer?.email_address

            if (document.getElementById('ship-to-different-address-checkbox').checked) {
                shipping.first_name = document.getElementById('shipping_first_name').value?.trim()
                shipping.last_name = document.getElementById('shipping_last_name').value?.trim()
                shipping.address_1 = document.getElementById('shipping_address_1').value?.trim()
                shipping.address_2 = document.getElementById('shipping_address_2').value?.trim()
                shipping.city = document.getElementById('shipping_city').value?.trim()
                shipping.state = document.getElementById('shipping_state').value?.trim()
                shipping.postcode = document.getElementById('shipping_postcode').value?.trim()
                shipping.country = document.getElementById('shipping_country').value?.trim()
            } else {
                shipping.first_name = document.getElementById('billing_first_name').value?.trim()
                shipping.last_name = document.getElementById('billing_last_name').value?.trim()
                shipping.address_1 = document.getElementById('billing_address_1').value?.trim()
                shipping.city = document.getElementById('billing_city').value?.trim()
                shipping.state = document.getElementById('billing_state').value?.trim()
                shipping.postcode = document.getElementById('billing_postcode').value?.trim()
                shipping.country = document.getElementById('billing_country').value?.trim()
            }

            purchaseUnits?.items?.map(item => {
                line_items.push({
                    quantity: parseInt(item?.quantity),
                    sku: item?.sku
                })
            })

            let body = {
                payment_method: paymentSource,
                payment_method_title: paymentSource,
                set_paid: true,
                billing: billing,
                shipping: shipping,
                line_items: line_items,
                created_via: 'checkout',
                status: 'processing',
                customer_id: userInner ? userInner?.id : 0,
                meta_data: [{
                    key: 'headless_order',
                    value: 'yes',
                }, {
                    key: '_payment_method',
                    value: 'ppcp-gateway',
                }, {
                    key: '_created_via',
                    value: 'checkout',
                }, {
                    key: '_ppcp_paypal_order_id',
                    value: data?.orderID,
                }, {
                    key: '_ppcp_paypal_intent',
                    value: intent,
                }, {
                    key: '_ppcp_paypal_payment_mode',
                    value: 'live',
                }, {
                    key: '_transaction_id',
                    value: purchaseUnits?.payments?.captures[0]?.id,
                }, {
                    key: '_payment_method_title',
                    value: paymentSource == 'card' ? 'Credit or debit cards (via PayPal)' : paymentSource
                }, {
                    key: '_cart_hash',
                    value: cart?.cart_hash
                }]
            }

            if (purchaseUnits?.amount?.breakdown?.tax_total?.value && parseFloat(purchaseUnits?.amount?.breakdown?.tax_total?.value) > 0) {
                body.tax_lines = [{
                    tax_total: parseFloat(purchaseUnits?.amount?.breakdown?.tax_total?.value) + '',
                    total: parseFloat(purchaseUnits?.amount?.breakdown?.tax_total?.value) + '',
                    rate_id: 28,
                    label: 'Tax',
                    rate_code: 'US-AZ-TAX-1',
                }]
            }

            if (purchaseUnits?.amount?.breakdown?.shipping?.value && parseFloat(purchaseUnits?.amount?.breakdown?.shipping?.value) > 0) {
                body.shipping_lines = [{
                    total: parseFloat(purchaseUnits?.amount?.breakdown?.shipping?.value) + '',
                    method_title: 'Flat rate',
                    method_id: cart?.shipping?.packages?.default?.rates[cart?.shipping?.packages?.default?.chosen_method]?.method_id,
                }]
            }

            if (ip) {
                body.meta_data.push({
                    key: '_customer_ip_address',
                    value: ip
                })
            }

            if (userAgent) {
                body.meta_data.push({
                    key: '_customer_user_agent',
                    value: userAgent
                })
            }

            if (purchaseUnits?.amount?.breakdown?.discount?.value && parseFloat(purchaseUnits?.amount?.breakdown?.discount?.value) > 0) {
                let cartInner = store?.getState()?.cartReducer
                if (typeof cartInner?.coupons != 'undefined' && Array.isArray(cartInner?.coupons) && cartInner?.coupons?.length) {
                    body.coupon_lines = [{
                        code: cartInner?.coupons[0]?.coupon,
                        discount: purchaseUnits?.amount?.breakdown?.discount.value
                    }]
                    body.discount_total = purchaseUnits?.amount?.breakdown?.discount.value
                }
            }

            axios.post(process.env.REACT_APP_WC_API_BASE + `orders`, body, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                }
            }).then(async res => {
                if (res?.status == 201) {
                    let checkoutData = res?.data
                    if (checkoutData) {
                        dispatcher(clearCart())
                        let headers = {}, queryParamsObj = {}
                        if (userInner) {
                            headers = {
                                headers: {
                                    'Content-Type': 'application/json',
                                    "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                                }
                            }
                        }
                        if (!userInner && cart?.cart_key) {
                            queryParamsObj.cart_key = cart?.cart_key
                        }
                        let queryParams = new URLSearchParams(queryParamsObj);
                        axios.post(process.env.REACT_APP_CC_API_BASE + `cart/clear?${queryParams}`, {}, headers).then(res => {

                            if (res?.status == 201) {
                                let checkoutData = res?.data
                                if (checkoutData) {
                                    dispatcher(clearCart())
                                    let headers = {}, queryParamsObj = {}
                                    if (userInner) {
                                        headers = {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                "Authorization": "Basic " + btoa(`${userInner?.email}:${userInner?.password}`)
                                            }
                                        }
                                    }
                                    if (!userInner && cart?.cart_key) {
                                        queryParamsObj.cart_key = cart?.cart_key
                                    }
                                }
                            }
                        }).catch(err => {
                            console.log(err)
                        })
                        navigation(`/checkout/order-received/${checkoutData?.id}/?key=${checkoutData?.order_key}`, {
                            state: {
                                checkoutData: checkoutData
                            }
                        })
                    }
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {

            })

        });
    }

    const onPaypalClick = async (data, actions) => {
        if (isCartValidating)
            return actions.reject()
        let errors = []
        setFundingSource(data?.fundingSource)
        const billing_first_name = document.getElementById('billing_first_name').value?.trim()
        const billing_last_name = document.getElementById('billing_last_name').value?.trim()
        const billing_country = document.getElementById('billing_country').value?.trim()
        const billing_address_1 = document.getElementById('billing_address_1').value?.trim()
        const billing_city = document.getElementById('billing_city').value?.trim()
        const billing_state = document.getElementById('billing_state').value?.trim()
        const billing_postcode = document.getElementById('billing_postcode').value?.trim()
        const billing_phone = document.getElementById('billing_phone').value?.trim()
        const billing_email = document.getElementById('billing_email').value?.trim()

        if (billing_first_name?.trim()?.length < 1) {
            errors.push('Billing first name is required')
        }

        if (billing_last_name?.trim()?.length < 1) {
            errors.push('Billing last name is required')
        }

        if (billing_address_1?.trim()?.length < 1) {
            errors.push('Billing address is required')
        }

        if (billing_city?.trim()?.length < 1) {
            errors.push('Billing city is required')
        }

        if (billing_state?.trim()?.length < 1) {
            errors.push('Billing state is required')
        }

        if (billing_country?.trim()?.length < 1) {
            errors.push('Billing country is required')
        }

        if (billing_postcode?.trim()?.length < 1) {
            errors.push('Billing zip code is required')
        }

        if (billing_phone?.trim()?.length < 1) {
            errors.push('Billing phone is required')
        }

        if (billing_email?.trim()?.length < 1) {
            errors.push('Billing email is required')
        }

        if (document.getElementById('ship-to-different-address-checkbox').checked) {

            const shipping_first_name = document.getElementById('shipping_first_name').value?.trim()
            const shipping_last_name = document.getElementById('shipping_last_name').value?.trim()
            const shipping_country = document.getElementById('shipping_country').value?.trim()
            const shipping_address_1 = document.getElementById('shipping_address_1').value?.trim()
            const shipping_city = document.getElementById('shipping_city').value?.trim()
            const shipping_state = document.getElementById('shipping_state').value?.trim()
            const shipping_postcode = document.getElementById('shipping_postcode').value?.trim()

            if (shipping_first_name?.trim()?.length < 1) {
                errors.push('Shipping first name is required')
            }

            if (shipping_last_name?.trim()?.length < 1) {
                errors.push('Shipping last name is required')
            }

            if (shipping_country?.trim()?.length < 1) {
                errors.push('Shipping country is required')
            }

            if (shipping_address_1?.trim()?.length < 1) {
                errors.push('Shipping address is required')
            }

            if (shipping_city?.trim()?.length < 1) {
                errors.push('Shipping city is required')
            }

            if (shipping_state?.trim()?.length < 1) {
                errors.push('Shipping state is required')
            }

            if (shipping_postcode?.trim()?.length < 1) {
                errors.push('Shipping zip code is required')
            }

        }

        if (errors?.length) {
            errors.map(err => {
                toast.error((he.decode(err)), {
                    position: "top-center",
                    style: {
                        borderColor: 'red',
                        borderWidth: '1px',
                        borderStyle: 'solid'
                    }
                })
            })
            return actions.reject()
        }

    }

    const onPaypalError = async data => {
        console.log('error', data)
    }

    const createOrder = async (data, actions) => {
        let products = []
        cart?.items?.map(item => {
            products.push({
                id: item?.id,
                quantity: item?.quantity?.value ? item?.quantity?.value : 1,
            })
        })

        return await axios.post(process.env.REACT_APP_TONYETRADE_API_BASE + `ppchangecart`, {
            headless_api_key: "25250bfd-5fc1-4573-a74e-8c702be129b4",
            products: products,
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(async res => {
            if (res.status == 200 && res?.data?.body_res?.success) {
                let purchase_units = res?.data?.body_res?.data
                let payer = getPayerObject()

                purchase_units[0].payee = {
                    merchant_id: process.env.REACT_APP_PAYPAL_MERCHANT_ID,
                }

                if (document.getElementById('ship-to-different-address-checkbox').checked) {
                    purchase_units[0].shipping = {
                        type: 'SHIPPING',
                        name: {
                            full_name: document.getElementById('shipping_first_name')?.value?.trim() + ' ' + document.getElementById('shipping_last_name')?.value?.trim()
                        },
                        address: {
                            address_line_1: document.getElementById('shipping_address_1')?.value?.trim(),
                            address_line_2: document.getElementById('shipping_address_2')?.value?.trim(),
                            admin_area_2: document.getElementById('shipping_city')?.value?.trim(),
                            admin_area_1: document.getElementById('shipping_state')?.value?.trim(),
                            postal_code: document.getElementById('shipping_postcode')?.value?.trim(),
                            country_code: document.getElementById('shipping_country')?.value?.trim(),
                        }
                    }
                }

                let cartInner = store?.getState()?.cartReducer

                if (cartInner) {

                    if (cartInner && cartInner?.needs_shipping) {
                        purchase_units[0].amount.breakdown.shipping.currency_code = cartInner?.currency?.currency_code
                        purchase_units[0].amount.breakdown.shipping.value = parsePrice(cartInner?.totals?.shipping_total) + ''
                        purchase_units[0].amount.value = parsePrice(cartInner?.totals?.total, true, false) + ''
                    }

                    if (typeof cartInner?.totals?.total_tax != 'undefined' && parseInt(cartInner?.totals?.total_tax) > 0) {
                        purchase_units[0].amount.breakdown.tax_total.currency_code = cartInner?.currency?.currency_code
                        purchase_units[0].amount.breakdown.tax_total.value = parsePrice(cartInner?.totals?.total_tax, true, false) + ''
                    }

                    if (typeof cartInner?.coupons != 'undefined' && Array.isArray(cartInner?.coupons) && cartInner?.coupons?.length) {
                        let _coupon = cartInner?.coupons[0];
                        if (_coupon) {
                            _coupon = parseFloat(Math.abs(_coupon?.saving) / 100).toFixed(2)
                            purchase_units[0].amount.breakdown.discount = {
                                currency_code: cartInner?.currency?.currency_code,
                                value: _coupon
                            }
                        }
                    } else {
                        delete purchase_units[0].amount.breakdown.discount
                    }

                } else {

                    if (cart && cart?.needs_shipping) {
                        purchase_units[0].amount.breakdown.shipping.currency_code = cart?.currency?.currency_code
                        purchase_units[0].amount.breakdown.shipping.value = parsePrice(cart?.totals?.shipping_total) + ''
                        purchase_units[0].amount.value = parsePrice(cart?.totals?.total, true, false) + ''
                    }

                    if (typeof cart?.totals?.total_tax != 'undefined' && parseInt(cart?.totals?.total_tax) > 0) {
                        purchase_units[0].amount.breakdown.tax_total.currency_code = cart?.currency?.currency_code
                        purchase_units[0].amount.breakdown.tax_total.value = parsePrice(cart?.totals?.total_tax, true, false) + ''
                    }

                    if (typeof cart?.coupons != 'undefined' && Array.isArray(cart?.coupons) && cart?.coupons?.length) {
                        let _coupon = cart?.coupons[0];
                        if (_coupon) {
                            _coupon = parseFloat(Math.abs(_coupon?.saving) / 100).toFixed(2)
                            purchase_units[0].amount.breakdown.discount = {
                                currency_code: cart?.currency?.currency_code,
                                value: _coupon
                            }
                        }
                    } else {
                        delete purchase_units[0].amount.breakdown.discount
                    }

                }

                let createOrderObject = {
                    purchase_units: purchase_units,
                    payer: payer,
                    payment_method: 'ppcp-gateway',
                    funding_source: fundingSource,
                    context: 'product',
                }

                if (document.getElementById('ship-to-different-address-checkbox').checked) {
                    createOrderObject.application_context = {
                        shipping_preference: 'SET_PROVIDED_ADDRESS'
                    }
                }

                return actions.order.create(createOrderObject).then((orderid) => {
                    return orderid
                })

            }
        }).catch(err => {
            console.log('first err', err)
        })
    }

    useEffect(() => {
        axios.get(process.env.REACT_APP_WC_API_BASE + `payment_gateways`, {
            headers: {
                'Authorization': 'Basic ' + btoa(process.env.REACT_APP_WC_CONSUMER_KEY + ':' + process.env.REACT_APP_WC_CONSUMER_SECRET),
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res?.status == 200) {
                let gateways = res?.data
                let gatewayIndex = 0, gatewaysFinal = []
                if (gateways?.length) {
                    gateways.map(gateway => {
                        if (gateway?.enabled) {
                            gateway.description = gateway?.description?.replaceAll('\r\n', '<br />')
                            gatewaysFinal.push({
                                id: gatewayIndex,
                                stringID: gateway?.id,
                                methodTitle: gateway?.method_title,
                                title: gateway?.title,
                                description: gateway?.description,
                                active: gatewayIndex == 0 ? true : false,
                            })
                            gatewayIndex++;
                        }
                    })
                }
                setPaymentMethods(gatewaysFinal)
            }
        }).catch(err => {
            console.log(err)
        })

        axios.get("https://api.ipify.org/?format=json").then((res) => {
            if (res?.status == 200 && res?.data?.ip)
                setIP(res?.data?.ip)
        }).catch(err => {
            console.log(err => {
                console.log(err)
            })
        })

        setUserAgent(getUserAgent())

    }, [])

    return (
        <>
            <div className="checkout-page-title">
                <nav className="cart-breadcrumbs">
                    <Link to={`/cart`} >Shopping Cart</Link>
                    <Link to={`/checkout`} className="hide-for-small active-link">Checkout details</Link>
                    <Link to={``} className="hide-for-small">Order Complete</Link>
                </nav>
            </div>
            {
                cartItems?.length < 1 ?
                    <EmptyCart isCheckout={true} /> : null
            }
            {
                cartItems?.length > 0 ?
                    <>
                        <div className="container pt-0 mt-0">
                            <div className="woocommerce-form-coupon-toggle">
                                <div className="woocommerce-info message-wrapper">
                                    <div className="message-container container p-0">Have a coupon? <a href="#" className="showcoupon" onClick={event => {
                                        event.preventDefault()
                                        setIsCouponFormLoaded(!isCouponFormLoaded)
                                    }}>Click here to enter your code</a></div>
                                </div>
                            </div>
                            <form className={`checkout_coupon_form ${isCouponFormLoaded ? 'loaded' : ''} ${isCartValidating ? 'processing' : ''}`} onSubmit={(e) => {
                                e.preventDefault()
                                handleCouponSubmitForm()
                            }}>
                                <p>If you have a coupon code, please apply it below.</p>
                                <div className="coupon">
                                    <div className="coupon_content wrap-small">
                                        <div className="coupon_field full-width-small">
                                            <input type="text" name="coupon_code" className="input-text" placeholder="Coupon code" id="coupon_code" value={couponCode} onChange={(e) => {
                                                setCouponCode(e.target.value)
                                            }} />
                                        </div>
                                        <div className="apply_coupon_btn full-width-small">
                                            <button type="submit" className="button expand" name="apply_coupon" value="Apply coupon">Apply coupon</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="checkout-notices-wrapper"></div>
                            <form name="checkout" method="post" className="checkout woocommerce-checkout" onSubmit={event => {
                                event.preventDefault()
                                handleCheckoutFormSubmit()
                            }}>
                                <div className="checkout_left wrap-small">
                                    <div id="customer_details full-width-small" className='flex-60'>
                                        <div className="clear">
                                            <div className="woocommerce-billing-fields relative pr-35 pr-0-small">
                                                <h3 className="checkout_heading mt-0 pt-10">
                                                    Billing details
                                                </h3>
                                                <div className="field-wrapper">
                                                    <div className='flex space-between gap-35 wrap-small gap-0-small'>
                                                        <p className="fields-row required full-width" data-priority="10">
                                                            <label htmlFor="billing_first_name" className="label-checkout">First name&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                            <span className="woocommerce-input-wrapper">
                                                                <input id="billing_first_name" type="text" className="input-text" placeholder="" value={formData?.billing?.firstName} autoComplete="given-name" required="required" onChange={event => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        billing: {
                                                                            ...formData?.billing,
                                                                            firstName: event.target.value
                                                                        }
                                                                    })
                                                                }} onBlur={() => {
                                                                    updateCheckoutCustomerDataServer()
                                                                }} />
                                                            </span>
                                                        </p>
                                                        <p className="fields-row required full-width" id="billing_last_name_field" data-priority="20">
                                                            <label htmlFor="billing_last_name" className="label-checkout">Last name&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                            <span className="woocommerce-input-wrapper">
                                                                <input id="billing_last_name" type="text" className="input-text " placeholder="" value={formData?.billing?.lastName} autoComplete="family-name" required="required" onChange={event => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        billing: {
                                                                            ...formData?.billing,
                                                                            lastName: event.target.value
                                                                        }
                                                                    })
                                                                }} onBlur={() => {
                                                                    updateCheckoutCustomerDataServer()
                                                                }} />
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <p className="fields-row required" id="billing_company_field" data-priority="30">
                                                        <label htmlFor="billing_company" className="label-checkout">
                                                            Company name&nbsp;
                                                            <span className="optional">
                                                                (optional)
                                                            </span>
                                                        </label>
                                                        <span className="woocommerce-input-wrapper">
                                                            <input id="billing_company" type="text" className="input-text" placeholder="" value={formData?.billing?.company} autoComplete="organization" onChange={event => {
                                                                setFormData({
                                                                    ...formData,
                                                                    billing: {
                                                                        ...formData?.billing,
                                                                        company: event.target.value
                                                                    }
                                                                })
                                                            }} />
                                                        </span>
                                                    </p>
                                                    <p className="fields-row required" id="billing_country_field" data-priority="40">
                                                        <label htmlFor="billing_country" className="label-checkout">Country / Region&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                        <span className="woocommerce-input-wrapper">
                                                            <select id="billing_country" className="full-width country_to_state full-width" autoComplete="country" data-placeholder="Select a country / region…" data-label="Country / Region" tabIndex="-1" aria-hidden="true" value={formData?.billing?.country} onChange={event => {
                                                                setFormData({
                                                                    ...formData,
                                                                    billing: {
                                                                        ...formData?.billing,
                                                                        country: event.target.value
                                                                    }
                                                                })
                                                                if (countriesStates[event.target.value]?.states?.length) {
                                                                    setStateType('select')
                                                                } else {
                                                                    setStateType('text')
                                                                }
                                                                updateCheckoutCustomerDataServer()
                                                            }}>
                                                                {
                                                                    Object.entries(countriesStates)?.map(country => {
                                                                        return (
                                                                            <option key={country[0]} value={country[0]}>
                                                                                {country[1]?.name}
                                                                            </option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </span>
                                                    </p>
                                                    <p className="fields-row required full-width" id="billing_address_1_field" data-priority="50">
                                                        <label htmlFor="billing_address_1" className="label-checkout">Street address&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                        <span className="woocommerce-input-wrapper">
                                                            <input id="billing_address_1" type="text" className="input-text" placeholder="House number and street name" value={formData?.billing?.address_1} autoComplete="address-line1" required="required" onChange={event => {
                                                                setFormData({
                                                                    ...formData,
                                                                    billing: {
                                                                        ...formData?.billing,
                                                                        address_1: event.target.value
                                                                    }
                                                                })
                                                            }} onBlur={(event) => {
                                                                updateCheckoutCustomerDataServer()
                                                            }} />
                                                        </span>
                                                    </p>
                                                    <p className="fields-row required full-width" id="billing_address_2_field" data-priority="60">
                                                        <label htmlFor="billing_address_2" className="label-checkout">Apartment, suite, unit etc.&nbsp;
                                                            <span className="optional">
                                                                (optional)
                                                            </span>
                                                        </label>
                                                        <span className="woocommerce-input-wrapper">
                                                            <input id="billing_address_2" type="text" className="input-text" placeholder="Apartment, suite, unit, etc. (optional)" value={formData?.billing?.address_2} autoComplete="address-line2" onChange={event => {
                                                                setFormData({
                                                                    ...formData,
                                                                    billing: {
                                                                        ...formData?.billing,
                                                                        address_2: event.target.value
                                                                    }
                                                                })
                                                            }} onBlur={() => {
                                                                updateCheckoutCustomerDataServer()
                                                            }} />
                                                        </span>
                                                    </p>
                                                    <p className="fields-row required" id="billing_city_field" data-priority="70">
                                                        <label htmlFor="billing_city" className="label-checkout">City&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                        <span className="woocommerce-input-wrapper">
                                                            <input id="billing_city" type="text" className="input-text" placeholder="" value={formData?.billing?.city} autoComplete="address-level2" required="required" onChange={event => {
                                                                setFormData({
                                                                    ...formData,
                                                                    billing: {
                                                                        ...formData?.billing,
                                                                        city: event.target.value
                                                                    }
                                                                })
                                                            }} onBlur={(event) => {
                                                                updateCheckoutCustomerDataServer()
                                                            }} />
                                                        </span>
                                                    </p>
                                                    {
                                                        stateType == 'text' ?
                                                            <p className="fields-row required" id="billing_state_field" data-priority="90">
                                                                <label htmlFor="billing_state" className="label-checkout">State&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input id="billing_state" type="text" className="input-text" placeholder="" autocomplete="state" required="" value={formData?.billing?.state} onInput={(event) => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            billing: {
                                                                                ...formData.billing,
                                                                                state: event.target.value,
                                                                            }
                                                                        })
                                                                    }} onBlur={(event) => {
                                                                        updateCheckoutCustomerDataServer()
                                                                    }} />
                                                                </span>
                                                            </p> : null
                                                    }
                                                    {
                                                        stateType == 'select' ?
                                                            <p className="fields-row required" id="billing_state_field" data-priority="80">
                                                                <label htmlFor="billing_state" className="label-checkout">State&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <select id="billing_state" autoComplete="address-level1" data-placeholder="Select an option…" data-input-classes="" data-label="State" tabIndex="-1" aria-hidden="true" className='full-width' value={formData?.billing?.state} onChange={event => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            billing: {
                                                                                ...formData?.billing,
                                                                                state: event.target.value
                                                                            }
                                                                        })
                                                                        setTimeout(() => {
                                                                            updateCheckoutCustomerDataServer();
                                                                        }, 500);
                                                                    }}>
                                                                        <option value="">
                                                                            Select an option…
                                                                        </option>
                                                                        {
                                                                            countriesStates[formData?.billing?.country]?.states.length ?
                                                                                countriesStates[formData?.billing?.country]?.states?.map(state => {
                                                                                    return (
                                                                                        <option value={state?.key}>
                                                                                            {state?.value}
                                                                                        </option>
                                                                                    )
                                                                                }) : null
                                                                        }
                                                                    </select>
                                                                </span>
                                                            </p>
                                                            : null
                                                    }
                                                    <p className="fields-row required" id="billing_postcode_field" data-priority="90">
                                                        <label htmlFor="billing_postcode" className="label-checkout">ZIP Code&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                        <span className="woocommerce-input-wrapper">
                                                            <input id="billing_postcode" type="text" className="input-text" placeholder="" value={formData?.billing?.zipcode} autoComplete="postal-code" required="required" onChange={event => {
                                                                setFormData({
                                                                    ...formData,
                                                                    billing: {
                                                                        ...formData?.billing,
                                                                        zipcode: event.target.value
                                                                    }
                                                                })
                                                            }} onBlur={(event) => {
                                                                updateCheckoutCustomerDataServer()
                                                            }} />
                                                        </span>
                                                    </p>
                                                    <p className="fields-row required" id="billing_phone_field" data-priority="100">
                                                        <label htmlFor="billing_phone" className="label-checkout">Phone&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                        <span className="woocommerce-input-wrapper">
                                                            <input id="billing_phone" type="tel" className="input-text" placeholder="" value={formData?.billing?.phone} autoComplete="tel" required="required" onChange={event => {
                                                                setFormData({
                                                                    ...formData,
                                                                    billing: {
                                                                        ...formData?.billing,
                                                                        phone: event.target.value
                                                                    }
                                                                })
                                                            }} onBlur={() => {
                                                                updateCheckoutCustomerDataServer()
                                                            }} />
                                                        </span>
                                                    </p>
                                                    <p className="fields-row required" id="billing_email_field" data-priority="110">
                                                        <label htmlFor="billing_email" className="label-checkout">Email address&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                        <span className="woocommerce-input-wrapper">
                                                            <input id="billing_email" type="email" className="input-text" placeholder="" value={formData?.billing?.email} autoComplete="email" required="required" onChange={event => {
                                                                setFormData({
                                                                    ...formData,
                                                                    billing: {
                                                                        ...formData?.billing,
                                                                        email: event.target.value
                                                                    }
                                                                })
                                                            }} onBlur={() => {
                                                                updateCheckoutCustomerDataServer()
                                                            }} />
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clear">
                                            <div className="woocommerce-shipping-fields">
                                                <h3 id="ship-to-different-address">
                                                    <label className="woocommerce-form__label">
                                                        <input id="ship-to-different-address-checkbox" className="woocommerce-form__input woocommerce-form__input-checkbox input-checkbox" type="checkbox" name="ship_to_different_address" value="1" onChange={(event) => {
                                                            setIsDifferentShipping(event.target.checked)
                                                            updateCheckoutCustomerDataServer()
                                                        }} />
                                                        <span>Ship to a different address?</span>
                                                    </label>
                                                </h3>
                                            </div>
                                            {
                                                isDifferentShipping ?
                                                    <div className="woocommerce-shipping-fields relative pr-35 pr-0-small">
                                                        <div className="field-wrapper">
                                                            <div className='flex space-between gap-35 wrap-small gap-0-small'>
                                                                <p className="fields-row required full-width" data-priority="10">
                                                                    <label htmlFor="shipping_first_name" className="label-checkout">First name&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                    <span className="woocommerce-input-wrapper">
                                                                        <input id="shipping_first_name" type="text" className="input-text" placeholder="" value={formData?.shipping?.firstName} autoComplete="given-name" required="required" onChange={event => {
                                                                            setFormData({
                                                                                ...formData,
                                                                                shipping: {
                                                                                    ...formData?.shipping,
                                                                                    firstName: event.target.value
                                                                                }
                                                                            })
                                                                        }} onBlur={() => {
                                                                            updateCheckoutCustomerDataServer()
                                                                        }} />
                                                                    </span>
                                                                </p>
                                                                <p className="fields-row required full-width" id="shipping_last_name_field" data-priority="20">
                                                                    <label htmlFor="shipping_last_name" className="label-checkout">Last name&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                    <span className="woocommerce-input-wrapper">
                                                                        <input id="shipping_last_name" type="text" className="input-text " placeholder="" value={formData?.shipping?.lastName} autoComplete="family-name" required="required" onChange={event => {
                                                                            setFormData({
                                                                                ...formData,
                                                                                shipping: {
                                                                                    ...formData?.shipping,
                                                                                    lastName: event.target.value
                                                                                }
                                                                            })
                                                                        }} onBlur={() => {
                                                                            updateCheckoutCustomerDataServer()
                                                                        }} />
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <p className="fields-row required" id="shipping_company_field" data-priority="30">
                                                                <label htmlFor="shipping_company" className="label-checkout">
                                                                    Company name&nbsp;
                                                                    <span className="optional">
                                                                        (optional)
                                                                    </span>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input id="shipping_company" type="text" className="input-text" placeholder="" value={formData?.billing?.company} autoComplete="organization" onChange={event => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            shipping: {
                                                                                ...formData?.shipping,
                                                                                company: event.target.value
                                                                            }
                                                                        })
                                                                    }} />
                                                                </span>
                                                            </p>
                                                            <p className="fields-row required" id="shipping_country_field" data-priority="40">
                                                                <label htmlFor="shipping_country" className="label-checkout">Country / Region&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <select id="shipping_country" className="full-width country_to_state full-width" autoComplete="country" data-placeholder="Select a country / region…" data-label="Country / Region" tabIndex="-1" aria-hidden="true" value={formData?.shipping?.country} required="required" onChange={event => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            shipping: {
                                                                                ...formData?.shipping,
                                                                                country: event.target.value
                                                                            }
                                                                        })
                                                                        if (countriesStates[event.target.value]?.states?.length) {
                                                                            setShippingStateType('select')
                                                                        } else {
                                                                            setShippingStateType('text')
                                                                        }

                                                                        updateCheckoutCustomerDataServer()

                                                                    }}>
                                                                        {
                                                                            Object.entries(countriesStates)?.map(country => {
                                                                                return (
                                                                                    <option key={country[0]} value={country[0]}>
                                                                                        {country[1]?.name}
                                                                                    </option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </span>
                                                            </p>
                                                            <p className="fields-row required full-width" id="shipping_address_1_field" data-priority="50">
                                                                <label htmlFor="shipping_address_1" className="label-checkout">Street address&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input id="shipping_address_1" type="text" className="input-text" placeholder="House number and street name" value={formData?.shipping?.address_1} autoComplete="address-line1" required="required" onChange={event => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            shipping: {
                                                                                ...formData?.shipping,
                                                                                address_1: event.target.value
                                                                            }
                                                                        })
                                                                    }} onBlur={(event) => {
                                                                        updateCheckoutCustomerDataServer()
                                                                    }} />
                                                                </span>
                                                            </p>
                                                            <p className="fields-row required full-width" id="shipping_address_2_field" data-priority="60">
                                                                <label htmlFor="shipping_address_2" className="label-checkout">Apartment, suite, unit etc.&nbsp;
                                                                    <span className="optional">
                                                                        (optional)
                                                                    </span>
                                                                </label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input id="shipping_address_2" type="text" className="input-text" placeholder="Apartment, suite, unit, etc. (optional)" value={formData?.shipping?.address_2} autoComplete="address-line2" onChange={event => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            shipping: {
                                                                                ...formData?.shipping,
                                                                                address_2: event.target.value
                                                                            }
                                                                        })
                                                                    }} onBlur={() => {
                                                                        updateCheckoutCustomerDataServer()
                                                                    }} />
                                                                </span>
                                                            </p>
                                                            <p className="fields-row required" id="shipping_city_field" data-priority="70">
                                                                <label htmlFor="shipping_city" className="label-checkout">City&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input id="shipping_city" type="text" className="input-text" placeholder="" value={formData?.shipping?.city} autoComplete="address-level2" required="required" onChange={event => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            shipping: {
                                                                                ...formData?.shipping,
                                                                                city: event.target.value
                                                                            }
                                                                        })
                                                                    }} onBlur={(event) => {
                                                                        updateCheckoutCustomerDataServer()
                                                                    }} />
                                                                </span>
                                                            </p>
                                                            {
                                                                shippingStateType == 'text' ?
                                                                    <p className="fields-row required" id="shipping_state_field" data-priority="90">
                                                                        <label htmlFor="shipping_state" className="label-checkout">State&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                        <span id="shipping_state" className="woocommerce-input-wrapper">
                                                                            <input type="text" className="input-text" placeholder="" autoComplete="state" required="required" value={formData?.shipping?.state} onInput={(event) => {
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    shipping: {
                                                                                        ...formData.shipping,
                                                                                        state: event.target.value,
                                                                                    }
                                                                                })
                                                                            }} onBlur={(event) => {
                                                                                updateCheckoutCustomerDataServer()
                                                                            }} />
                                                                        </span>
                                                                    </p> : null
                                                            }
                                                            {
                                                                shippingStateType == 'select' ?
                                                                    <p className="fields-row required" id="shipping_state_field" data-priority="80">
                                                                        <label htmlFor="shipping_state" className="label-checkout">State&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                        <span className="woocommerce-input-wrapper">
                                                                            <select id="shipping_state" autoComplete="address-level1" data-placeholder="Select an option…" data-input-classes="" data-label="State" tabIndex="-1" aria-hidden="true" className='full-width' value={formData?.shipping?.state} onChange={event => {
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    shipping: {
                                                                                        ...formData?.shipping,
                                                                                        state: event.target.value
                                                                                    }
                                                                                })
                                                                                updateCheckoutCustomerDataServer()
                                                                            }}>
                                                                                <option value="">
                                                                                    Select an option…
                                                                                </option>
                                                                                {
                                                                                    countriesStates[formData?.shipping?.country]?.states.length ?
                                                                                        countriesStates[formData?.shipping?.country]?.states?.map(state => {
                                                                                            return (
                                                                                                <option value={state?.key}>
                                                                                                    {state?.value}
                                                                                                </option>
                                                                                            )
                                                                                        }) : null
                                                                                }
                                                                            </select>
                                                                        </span>
                                                                    </p>
                                                                    : null
                                                            }
                                                            <p className="fields-row required" id="shipping_postcode_field" data-priority="90">
                                                                <label htmlFor="shipping_postcode" className="label-checkout">ZIP Code&nbsp;<abbr className="required" title="required">*</abbr></label>
                                                                <span className="woocommerce-input-wrapper">
                                                                    <input id="shipping_postcode" type="text" className="input-text" placeholder="" value={formData?.shipping?.zipcode} autoComplete="postal-code" required="required" onChange={event => {
                                                                        setFormData({
                                                                            ...formData,
                                                                            shipping: {
                                                                                ...formData?.shipping,
                                                                                zipcode: event.target.value
                                                                            }
                                                                        })
                                                                    }} onBlur={(event) => {
                                                                        updateCheckoutCustomerDataServer()
                                                                    }} />
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            <div className="woocommerce-additional-fields">
                                                <div className="field-wrapper">
                                                    <p className="form-row notes" id="order_comments_field" data-priority="">
                                                        <label htmlFor="order_comments" className="label-checkout">Order notes&nbsp;
                                                            <span className="optional">
                                                                (optional)
                                                            </span>
                                                        </label>
                                                        <span className="woocommerce-input-wrapper">
                                                            <textarea name="order_comments" className="input-text " id="order_comments" placeholder="Notes about your order, e.g. special notes for delivery." rows="2" cols="5" value={formData?.notes} onChange={event => {
                                                                setFormData({
                                                                    ...formData,
                                                                    notes: event.target.value
                                                                })
                                                            }}></textarea>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkout_right flex-40 full-width-small">
                                        <div className="checkout_div">
                                            <div className={`checkout_right_inner ${isCartValidating ? 'processing' : ''}`}>
                                                <h3 className="checkout_heading mt-0 mb-10">Your order</h3>
                                                <div id="order_review" className="woocommerce-checkout-review-order">
                                                    <table className="shop_table woocommerce-checkout-review-order-table full-width">
                                                        <thead>
                                                            <tr>
                                                                <th className="product-name">Product</th>
                                                                <th className="product-total">Subtotal</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                cartItems?.map(item => {
                                                                    return (
                                                                        <tr key={item?.id} className="cart_item">
                                                                            <td className="product-name">{he.decode(item?.name)}&nbsp;
                                                                                <strong className="product-quantity">
                                                                                    ×&nbsp;{item?.quantity?.value}
                                                                                </strong>
                                                                            </td>
                                                                            <td className="product-total">
                                                                                <span className="woocommerce-Price-amount amount">
                                                                                    <bdi>
                                                                                        <span className="woocommerce-Price-currencySymbol">
                                                                                            {currency_symbol}
                                                                                        </span>
                                                                                        {parsePrice(item?.totals?.subtotal)}
                                                                                    </bdi>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                        <tfoot>
                                                            <tr className="cart-subtotal table-row">
                                                                <th>Subtotal</th>
                                                                <td>
                                                                    <span className="woocommerce-Price-amount amount">
                                                                        <bdi>
                                                                            <span className="woocommerce-Price-currencySymbol">
                                                                                {currency_symbol}
                                                                            </span>
                                                                            {parsePrice(cart?.totals?.subtotal)}
                                                                        </bdi>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            {
                                                                Array.isArray(cart?.coupons) && cart?.coupons?.length ?
                                                                    cart?.coupons?.map(coupon => {
                                                                        return (
                                                                            <tr className='coupon_row'>
                                                                                <th>{coupon?.label}</th>
                                                                                <td>
                                                                                    <span dangerouslySetInnerHTML={{ __html: coupon?.saving_html }} /> <a className='remove_applied_coupon' href='#' onClick={(event) => {
                                                                                        event.preventDefault()
                                                                                        handleRemoveCoupon(coupon?.coupon)
                                                                                    }}>[Remove]</a>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }) : null
                                                            }
                                                            {
                                                                typeof cart?.totals?.shipping_total != 'undefined' && parseInt(cart?.totals?.shipping_total) > 0 ?
                                                                    <tr className='table-row'>
                                                                        <th>Shipping</th>
                                                                        <td>
                                                                            <span className="woocommerce-Price-amount amount"><bdi><span className="woocommerce-Price-currencySymbol">{currency_symbol}</span>{parsePrice(cart?.totals?.shipping_total)}</bdi></span>
                                                                        </td>
                                                                    </tr> : null
                                                            }
                                                            {
                                                                typeof cart?.totals?.total_tax != 'undefined' && parseInt(cart?.totals?.total_tax) > 0 ?
                                                                    <tr className="order-total table-row">
                                                                        <th>Tax</th>
                                                                        <td>
                                                                            <strong>
                                                                                <span className="woocommerce-Price-amount amount">
                                                                                    <bdi>
                                                                                        <span className="woocommerce-Price-currencySymbol">
                                                                                            {currency_symbol}
                                                                                        </span>
                                                                                        {parsePrice(cart?.totals?.total_tax, true)}
                                                                                    </bdi>
                                                                                </span>
                                                                            </strong>
                                                                        </td>
                                                                    </tr> : null
                                                            }
                                                            <tr className="order-total table-row">
                                                                <th>Total</th>
                                                                <td>
                                                                    <strong>
                                                                        <span className="woocommerce-Price-amount amount">
                                                                            <bdi>
                                                                                <span className="woocommerce-Price-currencySymbol">
                                                                                    {currency_symbol}
                                                                                </span>
                                                                                {parsePrice(cart?.totals?.total)}
                                                                            </bdi>
                                                                        </span>
                                                                    </strong>
                                                                </td>
                                                            </tr>
                                                        </tfoot>
                                                    </table>
                                                    <div id="payment" className="woocommerce-checkout-payment">
                                                        {
                                                            paymentMethods?.length > 0 ?
                                                                <ul className="ul_payment">
                                                                    {
                                                                        paymentMethods?.map(paymentMethod => {
                                                                            return (
                                                                                <li key={paymentMethod?.id} className="li_payment_method_cheque">
                                                                                    <input type="radio" className="input-radio" data-order_button_text="" checked={paymentMethod.active ? true : false} onChange={(event) => {
                                                                                        handleChangePaymentMethod(paymentMethod)
                                                                                    }} />
                                                                                    <label htmlFor="payment_method_cheque inline-block">{paymentMethod?.title}</label>
                                                                                    {
                                                                                        paymentMethod?.description && paymentMethod?.active ?
                                                                                            <span className="payment_box payment_method_cheque" dangerouslySetInnerHTML={{ __html: paymentMethod?.description }}></span>
                                                                                            : null
                                                                                    }
                                                                                    {
                                                                                        paymentMethod?.title == 'PayPal' && paymentMethod?.active ?
                                                                                            <PayPalButtons
                                                                                                onApprove={onPaypalApprove}
                                                                                                onCancel={onPaypalCancel}
                                                                                                onClick={onPaypalClick}
                                                                                                onError={onPaypalError}
                                                                                                createOrder={createOrder}
                                                                                            /> : null
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                                : null
                                                        }
                                                        <div className="form-row place-order">
                                                            <noscript>
                                                                Since your browser does not support JavaScript, or it is disabled, please ensure you click the
                                                                <em>
                                                                    Update Totals
                                                                </em>
                                                                button before placing your order. You may be charged more than the amount stated above if you
                                                                fail to do so.
                                                                <br />
                                                                <button type="submit" className="button alt wp-element-button" name="woocommerce_checkout_update_totals" value="Update totals">Update totals</button>
                                                            </noscript>
                                                            {
                                                                enablePlaceOrderButton ?
                                                                    <button type="submit" className="button alt wp-element-button" name="woocommerce_checkout_place_order" id="place_order" value="Place order" data-value="Place order">Place order</button> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </> : null
            }
        </>
    )
}

export default Checkout