export const SET_CART = "set_cart"
export const CLEAR_CART = "clear_cart"
export const ADD_TO_CART = "add_to_cart"
export const ADD_TO_WISHLIST = "add_to_wishlist"
export const REMOVE_FROM_CART = "remove_form_cart"
export const REMOVE_FROM_WISHLIST = "remove_form_wishlist"
export const DEFAULT_CONSTANT = "default_constant"
export const GET_CART_PRODUCT_COUNT = "get_cart_product_count"
export const SET_SUGGESTIONS = "set_suggestions"
export const GET_SUGGESTIONS = "get_suggestions"
export const GET_SUGGESTIONS_LOCAL = "get_suggestions_local"