import React from "react";

const About = ()=>{
    return(
        <>
            About s
        </>
    )
}

export default About