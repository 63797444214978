import { useEffect, useState } from "react"
import AdminSidebar from "../../components/AdminSidebar"
import AdminTopbar from "../../components/AdminTopbar"
import { getBatchFileData_API } from "../../api/adminapi"
import { getCache, setCache } from "../../cacheUtil";

const delay = () => new Promise((resolve) => setTimeout(resolve, 2000));
const CACHE_KEY_BULK_EBAY_LISTER = 'bulk_ebay_lister_data';

const BulkEbayLister = () => {

    const [processing, setProcessing] = useState(false)
    const [lines, setLines] = useState(undefined)
    const [preparedLines, setPreparedLines] = useState([])
    const [selectedItems, setSelectedItems] = useState([])
    const [currentItem, setCurrentItem] = useState(undefined)

    const getBatchFileData = async () => {
        setProcessing(true)
        await getBatchFileData_API().then(response => {
            if (response.status == 200) {
                const _data = response.data, _lines = _data?.lines;
                setLines(_lines);
                setCache(CACHE_KEY_BULK_EBAY_LISTER, _lines);
            }
        }).catch(err => {

        }).finally(() => {
            setProcessing(false)
        })
    }

    useEffect(() => {
        const loadCachedLines = () => {
            const cachedLines = getCache(CACHE_KEY_BULK_EBAY_LISTER);
            if (cachedLines) {
                setLines(cachedLines);
            }
            // Fetch fresh data from the server
            getBatchFileData();
        };

        loadCachedLines();
        // setLines(undefined)
    }, [])

    const handleButtonClick = () => {
        lines.map(async line => {
            setCurrentItem(line.id)
            setPreparedLines(
                ...preparedLines,
                line.id
            )
            await delay()
            setCurrentItem(undefined)
        })
    }

    return <div class="layout-wrapper layout-content-navbar">
        <div class="layout-container">
            <AdminSidebar />
            <div class="layout-page">
                <AdminTopbar />
                <div class="content-wrapper">
                    <div class="container-xxl flex-grow-1 container-p-y">
                        <div class="admin-card">
                            <div class="card-datatable table-responsive">
                                <h2>Bulk eBay Lister</h2>
                                <button disabled={!selectedItems.length} onClick={handleButtonClick}>
                                    Bulk Lister for eBay
                                </button>
                                {
                                    processing ? <div className="processing"></div> : null
                                }
                                {
                                    lines?.length ?
                                        <table class="datatables-order table border-top">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <input type="checkbox" onChange={(e) => {
                                                            if (e.target.checked) {
                                                                let _selected = []
                                                                lines?.map((line, index) => {
                                                                    _selected.push(line.id)
                                                                })
                                                                setSelectedItems(_selected)
                                                            } else {
                                                                setSelectedItems([])
                                                            }
                                                        }} />
                                                    </th>
                                                    <th>ID</th>
                                                    <th>SKU</th>
                                                    <th>Description</th>
                                                    <th>Name</th>
                                                    <th>Price</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lines?.map((line, index) => {
                                                        return <tr key={index}>
                                                            <td>
                                                                <input onChange={(e) => {
                                                                    if (e.target.checked) {
                                                                        setSelectedItems([
                                                                            ...selectedItems,
                                                                            line.id
                                                                        ])
                                                                    } else {
                                                                        let _filtered = selectedItems.filter(item => {
                                                                            return item != line.id
                                                                        })
                                                                        let _filteredItems = [];
                                                                        _filtered.map(item => {
                                                                            _filteredItems.push(item.id)
                                                                        })
                                                                        setSelectedItems([..._filteredItems])
                                                                    }
                                                                }} type="checkbox" checked={selectedItems.includes(line.id)} />
                                                            </td>
                                                            <td>
                                                                {line.id}
                                                            </td>
                                                            <td>
                                                                {line.sku}
                                                            </td>
                                                            <td>
                                                                {line.desc}
                                                            </td>
                                                            <td>
                                                                {line.name}
                                                            </td>
                                                            <td>
                                                                {line.price}
                                                            </td>
                                                            <td>
                                                                {
                                                                    currentItem == line.id ?
                                                                        <div className="processing"></div> : null
                                                                }
                                                                {
                                                                    line.is_prepared == true ?
                                                                        (
                                                                            <span>Prepared</span>
                                                                        ) : (
                                                                            preparedLines.includes(line.id) ?
                                                                                <span>Prepared</span> :
                                                                                <span>Pending</span>
                                                                        )
                                                                }
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default BulkEbayLister