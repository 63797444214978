import '../Memoribilia/memoribilia.css'
import React, { useEffect } from "react";
import { scrollToTop } from '../../utils/utils';

const Memoribilia = () => {
    useEffect(()=>{
        scrollToTop()
    },[])
    return (
        <>
            <div className="single_post_header flex">
                <div className="container">
                    <h2 className="entry-title mt-0 mb-10">
                        SPORTS MEMORABILIA AUCTION SITES
                    </h2>
                    <p>
                        TonyeTrade lets you avoid sports memorabilia auction sites
                    </p>
                </div>
            </div>
            <div className='container flex howtosell space-between pb-0 wrap-small'>
                <div className='flex-50 pr-35 full-width-small pr-10-small'>
                    <h3 className='sell_heading mt-0 mb-10'>
                        CONSIDERING SPORTS MEMORABILIA AUCTION SITES?
                    </h3>
                    <p>
                        At TonyeTrade, we’re serious about getting you the most money for sports cards, memorabilia, and other collectibles. And we pull out all the stops to ensure you have an easy experience that leaves you with peace of mind. Before you call on sports memorabilia auction sites, see what TonyeTrade can do for you.
                    </p>
                    <h3 className='sell_heading mt-0 mb-10 pt-30'>
                        ABOUT SPORTS MEMORABILIA AUCTION SITES
                    </h3>
                    <p>
                        Sports memorabilia auction sites might seem like a good route for getting higher prices, but you have other factors to consider. For starters, these sites typically charge a hefty percentage of the final sale.They say “seller’s fees” or “buyer’s fee” but at end of the day, it’s less money for you. And then there’s waiting for the auction to conclude and funds to be dispersed. That’s not to mention the hassle of arranging for the packaging and transportation of your items. Many of our repeat clients started off selling on sports memorabilia auction sites, only to discover TonyeTrade offers a superior experience.
                    </p>
                    <h3 className='sell_heading mt-0 mb-10 pt-30'>
                        ABOUT PERSON-TO-PERSON SALES
                    </h3>
                    <p>
                        It can seem like a windfall when you find an individual collector interested in purchasing your items, as it’s a way to bypass sports memorabilia auction sites. However, now you’re faced with several unknowns, such as that individual’s track record and their personal integrity. TonyeTrade offers you all the upsides of a one-on-one transaction, and we’re backed by a reputation for fair and honest dealings that we’ve maintained over more than 30 years in business.
                    </p>
                    <h3 className='sell_heading mt-0 mb-10 pt-30'>
                        TONYETRADE IS THE BEST PLACE TO SELL YOUR COLLECTION
                    </h3>
                    <p>
                        TonyeTrade offers the higher payouts associated with sports memorabilia auction sites, as well as the convenience of an in-person sale — without the downsides of either option. If you have sports cards or other collectibles you’re ready to sell, make the smart move and contact TonyeTrade.
                    </p>
                </div>
                <div className='flex-40 full-width-small'>
                    <div className='memmembers full-width-small'>
                        <h3 className='sell_heading mt-0 mb-10'>
                            TONYETRADE IS A BETTER OPTION
                        </h3>
                        <p>
                            When you have valuable sports cards or other collectibles, your first move might be to contact sports memorabilia auction sites. But this would be a mistake. TonyeTrade provides a far better experience, end to end, with a number of highlights:
                        </p>
                        <p>
                            Top dollar paid
                        </p>
                        <p>
                            Immediate payouts
                        </p>
                        <p>
                            Hassle-free transactions
                        </p>
                        <p>
                            Honest dealings
                        </p>
                        <p>
                            Numerous options
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Memoribilia