import ContentLoader from "react-content-loader";

const Placeholder = ({ count = 6 }) => {
    return (
        <div className='placeholder_wrappers'>
            {Array.from({ length: count }).map((_, idx) => (
                <div className='single_placeholder' key={idx}>
                    <ContentLoader viewBox="0 0 40 40">
                        <rect x="0" y="0" rx="1" ry="1" width="100%" height="25" />
                        <rect x="0" y="27.5" rx="2" ry="2" width="100%" height="3.5" />
                        <rect x="0" y="33" rx="2" ry="2" width="80%" height="3" />
                    </ContentLoader>
                </div>
            ))}
        </div>
    )
}

export default Placeholder;