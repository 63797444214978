import './login.css'
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkStoredUser, loginAdmin } from '../../redux/slices/adminSlice';
import toast from 'react-hot-toast';

const AdminLogin = () => {

    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    })

    useEffect(() => {
        dispatch(checkStoredUser());
    }, []);

    const handleFormSubmit = () => {
        if (!formData.username.length || !formData.password.length || isLoading)
            return
        setIsLoading(true)
        dispatch(loginAdmin(formData))
    }

    const { isAuthenticated, error } = useSelector((state) => state.admin);

    useEffect(() => {
        if (error) {
            toast.error(error, {
                position: "top-center",
                style: {
                    borderColor: 'red',
                    borderWidth: '1px',
                    borderStyle: 'solid'
                }
            })
        }
    }, [error])

    // if (isAuthenticated) {
    //     // navigate('/admin')
    //     return <></>
    //     // return <Navigate to="/admin" />;
    //     // nav
    // }

    useEffect(()=>{
        if( isAuthenticated ){
            console.warn('dajshdsajd')
            navigate('/admin')
        }
    },[isAuthenticated])

    return <div className="container-xxl">
        <div className="authentication-wrapper authentication-basic container-p-y">
            <div className="authentication-inner py-6">
                <div className="admin-card">
                    <div className="admin-card-body">
                        <div className="app-brand justify-content-center mb-6">
                            <span className="app-brand-logo demo">
                                <img width={150} src="https://res.cloudinary.com/dzhclyn7c/image/upload/v1725515711/logo.11382c5ec95cfd207528_1_r310yc.png" />
                            </span>
                        </div>
                        <form id="formAuthentication" className="mb-4" onSubmit={(event) => {
                            event.preventDefault()
                            handleFormSubmit()
                        }}>
                            <div className="mb-6">
                                <label htmlFor="email" className="form-label">Username or Email Address</label>
                                <input
                                    value={formData.username}
                                    onInput={(event) => {
                                        setFormData({
                                            ...formData,
                                            username: event.target.value.trim()
                                        })
                                    }}
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="email-username"
                                    placeholder=""
                                    autoFocus />
                            </div>
                            <div className="mb-6 form-password-toggle">
                                <label className="form-label" htmlFor="password">Password</label>
                                <div className="input-group input-group-merge">
                                    <input
                                        value={formData.password}
                                        onInput={(event) => {
                                            setFormData({
                                                ...formData,
                                                password: event.target.value.trim()
                                            })
                                        }}
                                        type="password"
                                        id="password"
                                        className="form-control"
                                        name="password"
                                        placeholder=""
                                        aria-describedby="password" />
                                    <span className="input-group-text cursor-pointer"><i className="ti ti-eye-off"></i></span>
                                </div>
                            </div>
                            <div className="my-8">
                                <div className="d-flex justify-content-between">
                                    <div className="form-check mb-0 ms-2">
                                        <input className="form-check-input" type="checkbox" id="remember-me" />
                                        <label className="form-check-label" htmlFor="remember-me"> Remember Me </label>
                                    </div>
                                    <a href="auth-forgot-password-basic.html">
                                        <p className="mb-0">Lost your password?</p>
                                    </a>
                                </div>
                            </div>
                            <div className="mb-6">
                                <button className="btn btn-primary d-flex gap-1 w-100" type="submit">
                                    {isLoading ?
                                        <span className="spinner-border me-1" role="status" aria-hidden="true"></span> : 'Login'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default AdminLogin