import { Link } from 'react-router-dom';
import '../AccountDetails/accountdetails.css'
import React, { useEffect, useState } from "react";
import { scrollToTop } from '../../../utils/utils';
import { useAuth } from '../../../Hooks/useAuth';

const AccountDetails = () => {
    const [user, setUser] = useState(null)
    const auth = useAuth()
    const getUserdata = async () => {
        const userInner = await auth.getCurrentUser()
        if (userInner) {
            setUser(userInner)
        }
    }
    useEffect(() => {
        getUserdata()
        scrollToTop()
    }, [])
    
    return (
        <div className="main-containerr details">
            <div className="container p-0-small mt-0-small">
                <div className="account-details wrap-small">
                    <div className="left-panel full-width-small">
                        <h3 className="account-title">Your Account Details</h3>
                        <div className="left-account-detail">
                            <div className="left-side-detail">
                                <p className="account-det-heading">Username</p>
                                <p className="account-det-text">{user?.display_name}</p>
                            </div>
                            <div className="left-side-detail">
                                <p className="account-det-heading">Name</p>
                                <p className="account-det-text">{user?.first_name} {user?.last_name}</p>
                            </div>
                            <div className="left-side-detail">
                                <p className="account-det-heading">Email</p>
                                <p className="account-det-text">{user?.email}</p>
                            </div>
                            <hr className="divider" />
                            <Link to={`/my-account/editaccountdetails`} className="custom_dashboard_button red">
                                Edit Details
                            </Link>
                            <Link to={`/my-account/changepassword`} className="custom_dashboard_button red">
                                Change Password
                            </Link>
                        </div>
                    </div>

                    <div className="right-panel hidden-small">

                    </div>

                </div>
            </div>
        </div>
    )
}

export default AccountDetails