import '../SinglePost/singlepost.css'
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getSinglePostBySlug } from "../../api/api";
import { Link } from "react-router-dom";
import he from 'he'
import ContentLoader from 'react-content-loader';
import { scrollToTop } from '../../utils/utils';

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

const RenderPlaceholder = () => {
    useEffect(() => {
        scrollToTop()
    }, [])
    return (
        <>
            <div className="single_post_header">
                <div className="container">
                    <h6 className="entry-category">
                        <ContentLoader viewBox="0 0 320 40">
                            <rect x="0" y="0" rx="2" ry="2" width="30" height="3" />
                            <rect x="0" y="8" rx="2" ry="2" width="150" height="5" />
                            <rect x="0" y="17" rx="2" ry="2" width="40" height="4" />
                        </ContentLoader>
                    </h6>
                </div>
            </div>
            <div className="blog-wrapper blog-single">
                <div className="container">
                    <div className="entry-content">
                        {[...Array(5)].map((_, i) => (
                            <ContentLoader key={i} viewBox="0 0 320 30">
                                <rect x="0" y="0" rx="2" ry="2" width="300" height="3" />
                                <rect x="0" y="8" rx="2" ry="2" width="300" height="3" />
                                <rect x="0" y="17" rx="2" ry="2" width="250" height="3" />
                            </ContentLoader>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

const RenderPostHeader = ({ post }) => {
    const _embedded = post?._embedded
    let name = '', id = 0
    if (_embedded['wp:term'] != undefined && _embedded['wp:term'][0] != undefined) {
        name = _embedded['wp:term'][0][0]?.name
        id = _embedded['wp:term'][0][0]?.id
    }
    return (
        <div className="single_post_header">
            <div className="container">
                <h6 className="entry-category">
                    <Link to={`/blog?category=${id}`}>
                        {name}
                    </Link>
                </h6>
                <h1 className="entry-title">
                    {he.decode(post?.title?.rendered)}
                </h1>
                <div className="entry-meta">
                    {formatDate(post?.date_gmt)}
                </div>
            </div>
        </div>
    )
}

const RenderPostBody = ({ post }) => {
    return (
        <div className="blog-wrapper blog-single">
            <div className="container">
                <div className="entry-content">
                    <div dangerouslySetInnerHTML={{ __html: he.decode(post?.content?.rendered) }} />
                </div>
            </div>
        </div>
    )
}

const SinglePost = () => {
    let { slug } = useParams()
    const [post, setPost] = useState(undefined)
    useEffect(() => {
        const getSinglePost = async () => {
            const postInner = await getSinglePostBySlug(slug)
            if (Array.isArray(postInner) && postInner?.length > 0) {
                setPost(postInner[0])
            }
        }
        getSinglePost()
    }, [slug])
    return (
        <>
            {
                post != undefined ?
                    <div className="single_blog_post_wrapper">
                        <RenderPostHeader post={post} />
                        <RenderPostBody post={post} />
                    </div>
                    : <RenderPlaceholder />
            }
        </>
    )
}

export default SinglePost