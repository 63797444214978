import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { convertToCommaSeparate } from "../../../utils/utils";

const ViewOrderModal = ({ isOpen, onClose, order, type = 'wc_order' }) => {
    useEffect(() => {
        if (isOpen && order) {

        }
    }, [isOpen, order]);

    if (!isOpen) {
        return null;
    }

    return (
        <div id="view_order_modal" className={`modal-overlay fade ${isOpen ? 'show' : ''}`} onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className='modal_header'>
                    <div className='order_d'>
                        {
                            type == 'wc_order' ?
                                `Order #${order.id}` : (
                                    type == 'ebay_order' ?
                                        `Details for order ${order.order_id}` : ''
                                )
                        }
                    </div>
                    {
                        type == 'ebay_order' ?
                            <div className='order_meta'>
                                <table width="100%" border="0">
                                    <tbody>
                                        <tr>
                                            <td width="20%">
                                                <b>Date:</b>
                                            </td>
                                            <td>{order.date_created}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>eBay Buyer:</b>
                                            </td>
                                            <td>
                                                {order.buyer_userid}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b>Buyer Email:</b>
                                            </td>
                                            <td>
                                                {order.buyer_email}
                                            </td>
                                        </tr>
                                    </tbody></table>
                            </div> : null
                    }
                    <button className="close-button" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className='modal_body'>
                    {
                        type == 'ebay_order' ?
                            <>
                                <div className='ebay_order_shipping'>
                                    <h2>Shipping and Payment</h2>
                                    <table width="100%" border="0">
                                        <tbody>
                                            <tr>
                                                <td width="50%" valign="top">
                                                    <strong>Shipping address:</strong><br />
                                                    <span>
                                                        {/* {order.buyer_name} */}
                                                    </span>
                                                    <br /><br />
                                                    <strong>
                                                        Shipping service:
                                                    </strong><br />
                                                    <span>
                                                        {/* {order.ShippingService} */}
                                                    </span><br />
                                                    {/* <span>Shipping cost: </span> */}
                                                </td>
                                                <td width="50%" valign="top">
                                                    <strong>Payment address:</strong><br /><br />
                                                    <strong>Payment method:</strong><br /><br />
                                                    <strong>Order Total:</strong><br />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='ebay_order_shipping'>
                                    <h2>Purchased Items</h2>
                                    <div className='ebay_order_items'>

                                    </div>
                                </div>
                                <div className='ebay_order_shipping'>
                                    <h2>History</h2>
                                    <div className='ebay_order_items'>

                                    </div>
                                </div>
                                <div className='ebay_order_shipping'>
                                    <h2>Order Notes</h2>
                                    <div className='ebay_order_items'>

                                    </div>
                                </div>
                            </> : null
                    }
                    <div className='d-flex'>
                        {
                            type == 'wc_order' && order.billing.address_1.length ?
                                <div className='col'>
                                    <h5 class="mb-2">Billing Details</h5>
                                    <div className='view_order_modal_address'>
                                        {order.billing.first_name} {order.billing.last_name}<br />
                                        {order.billing.address_1} {order.billing.address_2}<br />
                                        {order.billing.city}, {order.billing.state} {order.billing.postcode} {order.billing.country}
                                    </div>
                                    <div className='view_order_modal_info'>
                                        <p>
                                            <strong>
                                                Email
                                            </strong>
                                        </p>
                                        <span>
                                            {order.billing.email}
                                        </span>
                                    </div>
                                    <div className='view_order_modal_info'>
                                        <p>
                                            <strong>
                                                Payment via
                                            </strong>
                                        </p>
                                        <span>
                                            {
                                                'paypal' == order.payment_method_title ?
                                                    <div>
                                                        {order.payment_method_title} (<a target="_blank" href={`https://www.paypal.com/cgi-bin/webscr?cmd=_view-a-trans&id=${order.transaction_id}`}>{order.transaction_id}</a>)
                                                    </div> : null
                                            }
                                        </span>
                                    </div>
                                </div> : null
                        }
                        {
                            type == 'wc_order' && order.shipping.address_1.length ?
                                <div className='col'>
                                    <h5 class="mb-2">Shipping Details</h5>
                                    <div className='view_order_modal_address'>
                                        {order.shipping.first_name} {order.shipping.last_name}<br />
                                        {order.shipping.address_1} {order.shipping.address_2}<br />
                                        {order.shipping.city}, {order.shipping.state} {order.shipping.postcode} {order.shipping.country}
                                    </div>
                                    <div className='view_order_modal_info'>
                                        <p>
                                            <strong>
                                                Shipping method
                                            </strong>
                                        </p>
                                        <span>
                                            {
                                                order.shipping_lines.map(shipping_line => {
                                                    return <div>
                                                        {shipping_line.method_title}
                                                    </div>
                                                })
                                            }
                                        </span>
                                    </div>
                                </div> : null
                        }
                    </div>
                    {
                        type == 'wc_order' && order.shipping_lines.length ?
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Tax</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        order.line_items.map(line_item => {
                                            return <tr>
                                                <td>
                                                    {line_item.name}
                                                </td>
                                                <td>
                                                    {line_item.quantity}
                                                </td>
                                                <td>
                                                    ${convertToCommaSeparate(line_item.total_tax)}
                                                </td>
                                                <td>
                                                    ${convertToCommaSeparate(line_item.total)}
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table> : null
                    }
                </div>
                <div className='modal_footer'>
                    <span class={`badge px-2 ${order.status == 'processing' ? 'bg-label-success' : ''} ${order.status == 'on-hold' ? 'bg-label-warning' : ''} ${order.status == 'completed' ? 'bg-label-primary' : ''} ${order.status == 'pending' ? 'bg-label-info' : ''}`} text-capitalized="">
                        {order.status}
                    </span>
                    {
                        type == 'wc_order' ?
                            <Link to={`/admin/orders/order-details/${order.id}`} className="btn btn-primary waves-effect waves-light">
                                Edit
                            </Link> : null
                    }
                </div>
            </div>
        </div>
    );
};

export default ViewOrderModal;
